import styled from 'styled-components';

interface ContainerProps {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  alignItems?: string;
  bgColor?: string;
  border?: string;
  borderRadius?: string;
  marginLeft?: string;
  position?: string;
  top?: string;
  marginTop?: string;
  left?: string;
  opacity?: string;
  overflow?: string;
  overflowY?: string;
  boxShadow?: string;
  display?: string;
  zIndex?: string;
  justifyContent?: string;
  transform?: string;
  cursor?: string;
}

const Container = styled.div.attrs<ContainerProps>((props) => ({
  onClick: props.onClick,
}))<ContainerProps>`
  width: ${(props) => props.width ?? ""};
  height: ${(props) => props.height ?? ""};
  margin: ${(props) => props.margin ?? ""};
  padding: ${(props) => props.padding ?? ""};
  align-items: ${(props) => props.alignItems ?? ""};
  background-color: ${(props) => props.bgColor ?? ""};
  border-radius: ${(props) => props.borderRadius ?? ""};
  margin-left: ${(props) => props.marginLeft ?? ""};
  position: ${(props) => props.position ?? ""};
  border: ${(props) => props.border ?? ""};
  top: ${(props) => props.top ?? ""};
  margin-top: ${(props) => props.marginTop ?? ""};
  left: ${(props) => props.left ?? ""};
  opacity: ${(props) => props.opacity ?? ""};
  overflow: ${(props) => props.overflow ?? ""};
  overflowy: ${(props) => props.overflowY ?? ""};
  boxshadow: ${(props) => props.boxShadow ?? ""};
  display: ${(props) => props.display ?? ""};
  z-index: ${(props) => props.zIndex ?? ""};
  justify-content: ${(props) => props.justifyContent ?? ""};
  transform: ${(props) => props.transform ?? ""};
  cursor: ${(props) => props.cursor ?? ""};

  @media (max-width: 912px) {
    // width: 100%;
    // overflow-x: auto;
    // padding: 0px;
  }
  //   @media (max-width: 768px) {
  //     width: 100%;
  //     // padding: 0px;
  //   }
`;



export default Container;
