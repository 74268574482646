// import React from "react";
// import "../../assets/css/switch.css"; // Import CSS file for styling

// interface SwitchProps {
//   checked: boolean;
//   onChange: (checked: boolean) => void;
// }

// const Switch: React.FC<SwitchProps> = ({ checked, onChange }) => {
//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     onChange(event.target.checked);
//   };

//   return (
//     <label className="switch">
//       <input type="checkbox" checked={checked} onChange={handleChange} />
//       <span className="slider"></span>
//     </label>
//   );
// };

// export default Switch;

import React, { useState } from "react";
import "../../assets/css/switch.css"; // Import CSS file for styling

interface Props {
  isOn: boolean;
  onToggle: () => void;
}

const Switch: React.FC<Props> = ({ isOn, onToggle }) => {
  const toggleSwitch = () => {
    onToggle();
  };
  return (
    <div className={`switch ${isOn ? "on" : "off"}`} onClick={toggleSwitch}>
      <div className="switch-toggle"></div>
    </div>
  );
};

export default Switch;
