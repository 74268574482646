import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import deleteIcon from "../../assets/deleteIcon.svg";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import NestedMenu from "../ParticlesDropdown/NestedMenu";
import "../../stylesheets/theme.css";
import LessonsDropdown from "../LessonDropdwon/lessonsDropdwon";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../Common/Toast";

import RightSideArrow from "../../assets/RightSideArrow.svg";
import RadioButton from "../Common/RadioButton";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
import '../../stylesheets/common.css'

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  order: number;
}

interface Theme {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  themes: Theme[];
}

interface Level {
  level?: string;
  lessons: Lesson[];
}

const DragDropColors = () => {
  const { id } = useParams();
  const [lessonsData, setLessonsData] = useState<Level[]>([]);
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [colorsId, setColorsId] = useState("");
  const [points, setPoints] = useState("");
  const [level, setLevel]= useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    lesson: "",
  });
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);

  const [validationError, setValidationError] = useState<{
    pairs: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
  }>({
    pairs: {},
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchColorsData();
  }, []);

  const fetchColorsData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (id) {
        response = await getRequest(
          `${baseURL}/drag-and-drop-colors-challenge/get-drag-and-drop-colors-challenge-by-id/${id}`
        );
      } else {
        setIsLoading(false);
        return;
      }

      if (response?.data.success) {
        const updatedColorsData = response.data.data.colorsChallenge;
        const particles = response.data.data.particles;

        const themeName = await fetchThemeById(updatedColorsData.themeId);
        setLessonsData([
          {
            lessons: [
              {
                id: updatedColorsData.themeId,
                name: themeName.name,
                themes: [],
              },
            ],
          },
        ]);
        setLevel(themeName.lessonsLevel);
        setPoints(`${updatedColorsData.points} pts`);

        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelectItem = (item: Lesson) => {
    setLessonsData([{lessons: [item]}]);
    setErrors({
      ...errors,
      lesson: item ? "" : "Please select a lesson.",
    });
  };

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      { dropdownValue: null, inputValue: "", order: newOrder },
    ]);
  };

  const handleDropdownChange = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].dropdownValue = "";
    } else {
      updatedErrors.pairs[index] = { dropdownValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const removePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "dragAndDropColorsChallengeParticles",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);

      const updatedErrors = { ...validationError };
      if (updatedErrors.pairs) {
        delete updatedErrors.pairs[index];
      }
      setValidationError(updatedErrors);
    }
  };

  const handleDeletePair = (index: number, id?: number) => {
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this items?");
      setOnConfirmDelete(() => () => removePair(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair(index);
    }
  };

  const handleSave = async () => {
    let hasError = false;

    const validationErrors = {
      lesson: !lessonsData.length ? "Please select a lesson." : "",
    };

    setErrors(validationErrors);

    if (pairs.length === 0) {
      Toast("Please add at least one pair.", "error");
      return;
    }

    const newErrorsColumn1: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrorsColumn1[index] = {};
      if (!item.dropdownValue) {
        newErrorsColumn1[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue) {
        newErrorsColumn1[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: newErrorsColumn1,
    }));

    if (hasError) {
      return;
    }
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }
    //  if (Object.values(validationError).some((error) => error)) {
    //    return;
    //  }

    const particles = pairs.map((pair) => ({
      id: pair.id,
      value: pair.inputValue,
      type: pair.dropdownValue?.id || "",
      order: pair.order,
    }));

    const firstLesson = lessonsData[0];

    const themeId = firstLesson.lessons[0].id;

    const requestData = {
      themeId: themeId,
      points: 1,
      particles: particles,
    };
    try {
      setIsLoading(true);
      let response;
      const updateId = colorsId || id;
      if (updateId) {
        response = await postRequest(
          `${baseURL}/drag-and-drop-colors-challenge/update-drag-and-drop-colors-challenge/${updateId}`,
          requestData
        );
      } else {
        response = await postRequest(
          `${baseURL}/drag-and-drop-colors-challenge/create-drag-and-drop-colors-challenge`,
          requestData
        );
      }

      if (response?.data.success) {
        const updatedColorsData = response.data.data.challenge;
        const particles = response.data.data.particle;
        setColorsId(updatedColorsData.id);
        setPoints(`${updatedColorsData.points} pts`);
        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
      }
      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error handling question:", error);
    }
  };

  const fetchThemeById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/notification-challenge/get-themename-by-id/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setPairToDelete(null);
  };

  const handleBackClick = async () => {
    navigate(`/drag-drop-colors`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });

  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <VerticalSpace height={10} />
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a challenge
            </h4>
            <VerticalSpace height={30} />
            <div
              className="container"
              style={{
                borderRadius: "30px",
                maxWidth: "1117px",
                backgroundColor: "#d9d9d980",
                padding: "0px",
              }}
            >
              <div
                className="col-12 p-4"
                style={{
                  borderRadius: "30px 30px 0px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              >
                {level ? (
                  <RowContainer
                    alignItem="center"
                    justifyContent="end"
                    margin="0px 0px 20px 0px"
                  >
                    <RadioButton checked={true} />
                    <Label fontSize="14px" fontWeight={400}>
                      {level}
                    </Label>
                  </RowContainer>
                ) : null}
                <RowContainer
                  alignItem="center"
                  className="textFields-container"
                >
                  <div className="col-12" style={{ flex: "unset" }}>
                    <LessonsDropdown
                      data={lessonsData as []}
                      onSelectItem={(item) => handleSelectItem(item as Lesson)}
                      borderColor={errors.lesson ? "#dc3545" : "#D9D9D9"}
                    />
                  </div>
                  <RowContainer alignItem="center" width="60px">
                    <Label fontSize="18px" fontWeight={400}>
                      1 pts
                    </Label>
                    <img src={RightSideArrow} alt="Arrow" />
                  </RowContainer>
                </RowContainer>
              </div>
              <div className="col-12  p-4">
                <div className="col d-flex drag-drop-content">
                  <div className="col-6 container-title">
                    <Label
                      fontSize="20px"
                      padding="0px 0px 0px 8px"
                      fontWeight={700}
                    >
                      Exercise
                    </Label>
                  </div>
                  <Label fontSize="20px" fontWeight={700}>
                    Particles drag & drop
                  </Label>
                </div>

                <VerticalSpace height={10} />

                <div
                  className="col d-flex flex-column align-itmes-center sentence-example"
                  style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                >
                  {pairs.map((pair, index) => (
                    <div
                      key={index}
                      className="row sentence-row"
                      style={{ flexWrap: "unset", alignItems: "center" }}
                    >
                      <div className="col sentence-dropdown">
                        <input
                          type="text"
                          value={pair.inputValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          className={`form-control ${
                            validationError.pairs[index]?.inputValue
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder=""
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        <div
                          className="p-0 delete-icon"
                          style={{
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                          onClick={() => handleDeletePair(index, pair.id)}
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="col-mb-6 sentence-input"
                        style={{ flex: "1" }}
                      >
                        <NestedMenu
                          data={pair.dropdownValue ? [pair.dropdownValue!] : []}
                          onSelectItem={(value) =>
                            handleDropdownChange(index, value as DropdownValue)
                          }
                          borderColor={
                            validationError.pairs[index]?.dropdownValue
                              ? "#dc3545"
                              : "#D9D9D9"
                          }
                        />
                      </div>
                      <div
                        className="p-0 delete-button"
                        style={{
                          cursor: "pointer",
                          width: "fit-content",
                        }}
                        onClick={() => handleDeletePair(index, pair.id)}
                      >
                        <img
                          src={deleteIcon}
                          className="user-scale"
                          style={{
                            WebkitUserSelect: "none",
                            userSelect: "none",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <VerticalSpace height={16} />
                <button
                  type="button"
                  className="user"
                  style={{
                    color: "#100F15",
                    background: "none",
                    border: "1px solid #100F15",
                    borderRadius: "10px",
                    padding: "14px 20px",
                    fontSize: "14px",
                    width: "auto",
                  }}
                  onClick={addNewPair}
                >
                  Add new pair
                </button>
              </div>
            </div>
          </div>
          <VerticalSpace height={220} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #ffffff 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Publish
              </button>
            </div>
          </div>
        </>
      )}

      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default DragDropColors;
