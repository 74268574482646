import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
// import '../../assets/css/editMember.css'
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipActivity from "../../Components/MembersActivity";
import VerticalSpace from "../../Components/VerticalSpace";
import MembershipLessons from "../../Components/LessonsRedeemed";
import HorizontalSpace from "../../Components/HorizontalSpace";
import Switch from "../../Components/Common/Switch";
import { useNavigate, useParams } from "react-router-dom";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import styled from "styled-components";
import "../../stylesheets/theme.css";
import CustomDropdown from "../Common/CustomDropdown";

interface Activity {
  id: number;
  name: string;
  progress: string;
  duration: string;
  startdate: string;
  type: boolean;
  level: string;
  points: string;
}

interface lessons {
  id: number;
  paquete: string;
  level: string;
  date: string;
  operation: boolean;
}

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 720px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const EditMemberShipUser = () => {
  const [showGeneralForm, setShowGeneralForm] = useState(true);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedButton, setSelectedButton] = useState("General");
  const [activity, setActivity] = useState<Activity[]>([]);
  const [lessons, setLessons] = useState<lessons[]>([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const navigate = useNavigate();

  const handleDeleteConfirm = () => {
    // Perform delete action here
    // Once delete action is performed, close the modal
    setShowDeletePopup(false);
  };
   
  const dropdownItems: string[] = [
    "3 months free",
    "6 months free",
    "1 Year free",
  ];

   const handleSelect = (item: string) => {
     setSelectedItem(item);
   };

  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleDeleteCancel = () => {
    // Cancel the delete action and close the modal
    setShowDeletePopup(false);
  };

  // Mock data
  useEffect(() => {
    // Fetch or set your user data here
    const mockData: Activity[] = [
      {
        id: 1,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 2,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 3,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 4,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 5,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 6,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 7,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
    ];
    setActivity(mockData);
  }, []);

  useEffect(() => {
    const lessonsData: lessons[] = [
      {
        id: 1,
        paquete: "100 verbos",
        level: "A1",
        date: "13.03.2024",
        operation: true,
      },
      {
        id: 2,
        paquete: "100 verbos",
        level: "A1",
        date: "13.03.2024",
        operation: false,
      },
    ];
    setLessons(lessonsData);
  }, []);

  const handleDeleteButtonClick = () => {
    // Show the delete confirmation modal
    setShowDeletePopup(true);
  };

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "General") {
      setShowGeneralForm(true);
      setShowPaymentForm(false);
    } else {
      setShowGeneralForm(false);
      setShowPaymentForm(true);
    }
    setSelectedButton(buttonName);
  };

  const handleBackClick = async () => {
    navigate(`/members`);
    //  try {
    //    const response = await getRequest(
    //      `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
    //    );
    //    const flashCardId = response?.data.data[0].flashCardId;
    //    navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    //  } catch (error) {
    //    console.error("Error fetching themeId:", error);
    //  }
  };

  return (
    <div
      className="container-fluid"
      // style={{
      //   backgroundColor: "#F8F8F8",
      // }}
    >
      <div className="p-3 main-page">
        <div
          className="d-flex  align-items-center"
          onClick={handleBackClick}
          style={{ width: "fit-content" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="me-2"
            style={{
              backgroundColor: "#D9D9D9",
              padding: "7px",
              borderRadius: "8px",
              cursor: "pointer",
              color: "#000",
            }}
          />
          <label
            style={{
              color: "#FF9E00",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Back
          </label>
        </div>
        <h4
          style={{
            textAlign: "center",
            margin: "auto",
            color: "#7542E4",
            fontWeight: "bold",
          }}
        >
          Memberships premium
        </h4>
        <VerticalSpace height={20} />
        <div className="row p-3 profile-main">
          <RowContainer>
            <RowContainer></RowContainer>
            <RowContainer></RowContainer>
          </RowContainer>
          <div className="col user-profile">
            <div className="row align-items-center mb-3">
              <div className="col-auto">
                <h3 style={{ fontWeight: "bold" }}>Profile</h3>
              </div>

              <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
            </div>
            <div
              className="container "
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "20px",
              }}
            >
              <div className="row p-2">
                <button
                  type="button"
                  className={`btn ${
                    selectedButton === "General"
                      ? "btn-success"
                      : "btn-outline-dark"
                  } me-2 text-center`}
                  style={{
                    color: selectedButton === "General" ? "#FFFFFF" : "#100F15",
                    backgroundColor:
                      selectedButton === "General" ? "#000000" : "transparent",
                    border:
                      selectedButton === "General"
                        ? "none"
                        : "1px solid #100F15",
                    borderRadius: "46px",
                    padding: "8px 20px",
                    fontSize: "10px",
                    width: "auto",
                  }}
                  onClick={() => handleButtonClick("General")}
                >
                  General
                </button>
                <button
                  type="button"
                  className={`btn ${
                    selectedButton === "Payment"
                      ? "btn-success"
                      : "btn-outline-dark"
                  } me-2 text-center`}
                  style={{
                    color: selectedButton === "Payment" ? "#FFFFFF" : "#100F15",
                    backgroundColor:
                      selectedButton === "Payment" ? "#000000" : "transparent",
                    border:
                      selectedButton === "Payment"
                        ? "none"
                        : "1px solid #100F15",
                    borderRadius: "46px",
                    fontSize: "10px",
                    width: "auto",
                    padding: "8px 20px",
                  }}
                  onClick={() => handleButtonClick("Payment")}
                >
                  Payment
                </button>
              </div>
              <VerticalSpace height={14} />
              {showGeneralForm && (
                <div className="col">
                  <form className="row g-3">
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        ID
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Member since
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Name
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder="Name"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Last Name
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputLastName"
                        placeholder="Last name"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Email
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="antonia@lexik.app"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Total Points
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputpoints"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Points redeemed
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputredeemPoints"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Level
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="inputlevel"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2 text-center"
                        style={{
                          color: "#100F15",
                          background: "none",
                          border: "1px solid #100F15",

                          borderRadius: "10px",
                          padding: "8px 20px",
                          fontSize: "14px",
                          width: "auto",
                        }}
                        onClick={handleDeleteButtonClick}
                      >
                        Delete user
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {showPaymentForm && (
                <div className="col">
                  <form className="row g-3">
                    <div>
                      <div className="col-md-4">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Membership
                        </Label>
                        <VerticalSpace height={4} />
                        <CustomDropdown
                          items={dropdownItems}
                          onSelect={handleSelect}
                          height="40px"
                          border="none"
                        />
                        {/* <select
                          id="inputpack"
                          className="form-select"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        >
                          <option selected>Yearly</option>
                          <option>Monthly</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        From
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="date"
                        className="form-control"
                        id="inputDate"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Until
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="date"
                        className="form-control"
                        id="inputDate"
                        style={{
                          color: "#28A138",
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    {/* <br /> */}
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Billing Contact
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="antonia@lexik.app"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>

                    {/* <section style={{backgroundColor: "#eee"}}> */}
                    <div className="col-12">
                      <div className="row">
                        <div className="col">
                          <Label
                            fontSize="14px"
                            padding="0px 0px 0px 8px"
                            fontWeight={400}
                          >
                            Paymenet method
                          </Label>
                        </div>
                        <div className="col-md-auto ">
                          <Label
                            fontSize="14px"
                            padding="0px 8px 0px 0px"
                            fontWeight={400}
                            color="#7542e4"
                          >
                            Update your plan payments details
                          </Label>
                        </div>
                        <VerticalSpace height={4} />
                      </div>
                      <div className="container">
                        <div
                          className="row d-flex justify-content-center"
                          style={{
                            padding: "40px 19px",
                            backgroundColor: "#FFF",
                            borderRadius: "10px",
                          }}
                        >
                          {/* <div className="card rounded-3"> */}
                          <div className="card-body d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="row">
                                <Label
                                  fontSize="14px"
                                  // padding="0px 0px 0px 8px"
                                  fontWeight={400}
                                  color="#00A3FF"
                                >
                                  Visa
                                </Label>
                                <Label
                                  fontSize="14px"
                                  padding="0px 0px 0px 18px"
                                  fontWeight={400}
                                >
                                  **** **** **** 1234
                                </Label>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pb-1">
                              <button
                                type="button"
                                data-mdb-button-init
                                data-mdb-ripple-init
                                className="btn btn-primary btn-lg"
                                style={{
                                  backgroundColor: "#7542E4",
                                  fontSize: "14px",
                                  borderRadius: "10px",
                                  // borderRadius: "46px",
                                  // fontSize: "10px",
                                  width: "auto",
                                  padding: "6px 30px",
                                }}
                              >
                                Change
                              </button>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Payment successfully completed
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="date"
                        className="form-control"
                        id="inputDate"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Payment amount
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        id="inputpayment"
                        style={{
                          color: "#7542E4",
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          {/* <HorizontalSpace width={3} /> */}
          <div className="col user-activity">
            <h3 style={{ fontWeight: "bold" }}>Activity</h3>
            <VerticalSpace height={2} />
            <ScrollableRowContainer>
              <InnerRowContainer>
                <Container width="100%">
                  <RowContainer
                    alignItem="center"
                    padding="8px 12px 8px 12px"
                    gap="8px"
                  >
                    <RowContainer flex="6.4">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Name
                      </Label>
                    </RowContainer>
                    <RowContainer flex="4">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Progress
                      </Label>
                    </RowContainer>
                    <RowContainer flex="4">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Duration
                      </Label>
                    </RowContainer>
                    <RowContainer flex="6">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Start day
                      </Label>
                    </RowContainer>
                    <RowContainer flex="4">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Type
                      </Label>
                    </RowContainer>
                    <RowContainer flex="2">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Level
                      </Label>
                    </RowContainer>
                    <RowContainer flex="2.5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Points
                      </Label>
                    </RowContainer>
                  </RowContainer>
                  <ColumnContainer>
                    {activity.map((activity) => (
                      <MembershipActivity
                        key={activity.id}
                        id={activity.id}
                        name={activity.name}
                        progress={activity.progress}
                        duration={activity.duration}
                        startdate={activity.startdate}
                        type={activity.type}
                        level={activity.level}
                        points={activity.points}
                      />
                    ))}
                  </ColumnContainer>
                </Container>
              </InnerRowContainer>
            </ScrollableRowContainer>
            <VerticalSpace height={34} />
            <h3 style={{ fontWeight: "bold" }}>Lessons redeemed</h3>
            <VerticalSpace height={2} />
            <ScrollableRowContainer>
              <InnerRowContainer>
                <Container width="100%">
                  <RowContainer
                    alignItem="center"
                    padding="8px 12px 8px 12px"
                    gap="8px"
                  >
                    <RowContainer flex="8">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Paquete
                      </Label>
                    </RowContainer>
                    <RowContainer flex="7">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Lavel
                      </Label>
                    </RowContainer>
                    <RowContainer flex="8">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Date
                      </Label>
                    </RowContainer>
                    <RowContainer flex="5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Operation
                      </Label>
                    </RowContainer>
                  </RowContainer>
                  <ColumnContainer>
                    {lessons.map((lessons) => (
                      <MembershipLessons
                        key={lessons.id}
                        id={lessons.id}
                        paquete={lessons.paquete}
                        level={lessons.level}
                        date={lessons.date}
                        operation={lessons.operation}
                      />
                    ))}
                  </ColumnContainer>
                </Container>
              </InnerRowContainer>
            </ScrollableRowContainer>
          </div>
        </div>
        <VerticalSpace height={100} />
      </div>
      {showDeletePopup && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeletePopup}
          message="Are you sure want to delete this user?" // Example dynamic value
          deleteButtonLabel="Delete"
        />
      )}
    </div>
  );
};

export default EditMemberShipUser;
