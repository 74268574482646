// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* sidebar.css */
.sidebar {
    background-color: black;
    border-radius: 0px 20px 20px 0px;
    z-index: 9999;
    /* transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); */
}
/* 
.sidebar.visible {
    transform: translateX(0);
    
} */

@media (max-width: 1100px) {
    .sidebar {
        display: none;
    }

    .sidebar.visible .sidebar{
        flex-direction: column !important;
        display: block !important;
        align-items: flex-start !important;
        border-radius: 0px 20px 20px 0px !important;
        transform: translateX(0);
    }
    .sidebar-content{
       
    flex-direction: column !important;
    /* display: block !important; */
    }
    .sidebar-menu{
        flex-direction: column !important;
        align-items: center !important;
    }
    .sidebar-bottem{
      position: absolute;
      bottom: 20px;
      align-items: center !important;
    }
}

.nav-pills .nav-item:hover svg {
    fill: #ffffff;
}

.dropdown:hover svg {
    fill: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/sidebar/style.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,uBAAuB;IACvB,gCAAgC;IAChC,aAAa;IACb;mCAC+B;AACnC;AACA;;;;GAIG;;AAEH;IACI;QACI,aAAa;IACjB;;IAEA;QACI,iCAAiC;QACjC,yBAAyB;QACzB,kCAAkC;QAClC,2CAA2C;QAC3C,wBAAwB;IAC5B;IACA;;IAEA,iCAAiC;IACjC,+BAA+B;IAC/B;IACA;QACI,iCAAiC;QACjC,8BAA8B;IAClC;IACA;MACE,kBAAkB;MAClB,YAAY;MACZ,8BAA8B;IAChC;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* sidebar.css */\n.sidebar {\n    background-color: black;\n    border-radius: 0px 20px 20px 0px;\n    z-index: 9999;\n    /* transition: transform 0.3s ease-in-out;\n    transform: translateX(-100%); */\n}\n/* \n.sidebar.visible {\n    transform: translateX(0);\n    \n} */\n\n@media (max-width: 1100px) {\n    .sidebar {\n        display: none;\n    }\n\n    .sidebar.visible .sidebar{\n        flex-direction: column !important;\n        display: block !important;\n        align-items: flex-start !important;\n        border-radius: 0px 20px 20px 0px !important;\n        transform: translateX(0);\n    }\n    .sidebar-content{\n       \n    flex-direction: column !important;\n    /* display: block !important; */\n    }\n    .sidebar-menu{\n        flex-direction: column !important;\n        align-items: center !important;\n    }\n    .sidebar-bottem{\n      position: absolute;\n      bottom: 20px;\n      align-items: center !important;\n    }\n}\n\n.nav-pills .nav-item:hover svg {\n    fill: #ffffff;\n}\n\n.dropdown:hover svg {\n    fill: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
