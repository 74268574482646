import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HorizontalSpace from "../HorizontalSpace";
import "../../assets/css/createlessons.css";
import VerticalSpace from "../VerticalSpace";
import RadioButton from "../Common/RadioButton";
import deleteIcon from "../../assets/deleteIcon.svg";
import editIcon from "../../assets/editIcon.svg";
import plusIcon from "../../assets/plus_Icon.png";
import { Link, useParams } from "react-router-dom";
import Label from "../Common/Label";
import { useNavigate } from "react-router-dom";
import {
  baseURL,
  deleteRequest,
  getRequest,
  multiPostRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "./../../api/apiClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../Common/Toast";
import RowContainer from "../Common/RowContainer";
import ColumnContainer from "../Common/ColumnContainer";
import NestedMenu from "../ParticlesDropdown/NestedMenu";
import TextEditor from "../Common/TextEditor";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import Loader from "../../utils/Loading";

interface Theme {
  id: number;
  name: string;
  cards: number;
}

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  order?: number;
}

const CreateLessons = () => {
  const { id } = useParams();
  const [addThemes, setNewThemes] = useState(false);
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [showCoverForm, setShowCoverForm] = useState(true);
  const [showIntroForm, setShowIntroForm] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Cover");
  const [themeName, setThemeName] = useState("");
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [introTitle, setIntroTitle] = useState("");
  const [titleColor, setTitleColor] = useState("#000000");
  const [subTitleColor, setSubTitleColor] = useState("#000000");
  const [subTitleBgColor, setSubTitleBgColor] = useState("#FFFFFF");
  const [themes, setThemes] = useState<Theme[]>([]);
  const [editThemes, setEditThemes] = useState<Theme | null>(null);
  const [level, setLevel] = useState("A1");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const startingLessonInputRef = useRef<HTMLInputElement>(null);
  const myLessonsInputRef = useRef<HTMLInputElement>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [startingLessonImage, setStartingLessonImage] = useState<File | null>(
    null
  );
  const [themeError, setThemeError] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [themeToDelete, setThemeToDelete] = useState<number | null>(null);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    title: "",
    // intro: "",
    coverImage: "",
    startingLessonImage: "",
    myLessonsImage: "",
  });
const [validationError, setValidationError] = useState<{
  pairs: {
    [key: number]: {
      dropdownValue?: string;
      inputValue?: string;
    };
  };
}>({
  pairs: {},
});
  const [myLessonsImage, setMyLessonsImage] = useState<File | null>(null);
  const [lessonId, setLessonId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLessonData = async () => {
      setLoading(true);
      if (!id) return;
      try {
        setIsLoading(true);
        const response = await getRequest(
          `${baseURL}/lesson/get-lesson-theme/${id}`
        );

        const themeData = response?.data.data.themeData;
        const lessonData = response?.data.data.lesson;
        const particles = response?.data.data.particles;

        // Fetching cards count for each theme
        const cardsCountPromises = themeData.map(async (theme: any) => {
          const cardCountResponse = await postRequest(
            `${baseURL}/lesson/counts`,
            {
              modelName: "subthemes",
              field: "themeId",
              id: theme.id.toString(),
            }
          );
          if (cardCountResponse) {
            return { ...theme, cards: cardCountResponse.data.data.count };
          } else {
            return { ...theme, cards: 0 };
          }
        });

        const resolvedCardsCount = await Promise.all(cardsCountPromises);
        setLevel(lessonData.level);
        setTitle(lessonData.title);
        setTitleColor(lessonData.titleColor);
        setSubTitleColor(lessonData.subTitleColor);
        setSubTitleBgColor(lessonData.subTitleBgColor);
        setCoverImage(lessonData.coverImage);
        setStartingLessonImage(lessonData.startingLessonImage);
        setMyLessonsImage(lessonData.mylessonsImage);
        setThemes(resolvedCardsCount);
        setLessonId(lessonData.id);
        setIntro(lessonData.intro);
        setIntroTitle(lessonData.introTitle);

        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching themes:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchLessonData();
  }, [id]);

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "Cover") {
      setShowCoverForm(true);
      setShowIntroForm(false);
    } else {
      setShowCoverForm(false);
      setShowIntroForm(true);
    }
    setSelectedButton(buttonName);
  };

  const handleIntro = (value: string) => {
    setIntro(value);
  };

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      {
        dropdownValue: null,
        inputValue: "",
        order: newOrder,
      },
    ]);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleDropdownChange = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].dropdownValue = "";
    } else {
      updatedErrors.pairs[index] = { dropdownValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleLevelChange = (level: string) => {
    setLevel(level);
  };

  const handleNewThemes = () => {
    const activeLessonId = lessonId || id;

    if (!activeLessonId) {
      Toast("Please create a lesson before adding themes.", "error");
      return;
    }
    setThemeName("");
    setThemeError("");
    setNewThemes(true);
    setEditThemes(null);
  };

  const handleCoverImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setCoverImage(event.target.files[0]);
    }
  };

  const handleStartingLessonImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setStartingLessonImage(event.target.files[0]);
    }
  };

  const handleMyLessonsImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setMyLessonsImage(event.target.files[0]);
    }
  };

  const handleChooseFileClick = (inputType: string) => {
    const inputRef =
      inputType === "cover"
        ? fileInputRef
        : inputType === "startingLesson"
        ? startingLessonInputRef
        : myLessonsInputRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = (imageType: string) => {
    switch (imageType) {
      case "cover":
        setCoverImage(null);
        break;
      case "startingLesson":
        setStartingLessonImage(null);
        break;
      case "myLessons":
        setMyLessonsImage(null);
        break;
      default:
        break;
    }
  };

  //handle public click
  const handlePublishClick = async () => {
    const validationErrors = {
      title: !title ? "Title is required" : "",
      coverImage: !coverImage ? "Cover image is required" : "",
      startingLessonImage: !startingLessonImage
        ? "Starting lesson image is required"
        : "",
      myLessonsImage: !myLessonsImage ? "My lessons image is required" : "",
    };

    setErrors(validationErrors);

    // If any validation error exists, stop the form submission
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    let hasError = false;

    if (pairs.length === 0) {
      Toast("Please add at least one intro partículas.", "error");
      return;
    }

    const newErrorsColumn1: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrorsColumn1[index] = {};
      if (!item.dropdownValue) {
        newErrorsColumn1[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue) {
        newErrorsColumn1[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: newErrorsColumn1,
    }));

    if (hasError) {
      return;
    }

    if (lessonId) {
      // If lessonId exists, update the lesson
      await handleUpdateLesson();
    } else {
      // Otherwise, create a new lesson
      await handleCreateLesson();
    }
  };

  const handleCreateLesson = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("level", level);

    formData.append("titleColor", titleColor);
    formData.append("subTitleColor", subTitleColor);
    formData.append("subTitleBgColor", subTitleBgColor);
    if (coverImage) formData.append("coverImage", coverImage);
    if (startingLessonImage)
      formData.append("startingLessonImage", startingLessonImage);
    if (myLessonsImage) formData.append("myLessonsImage", myLessonsImage);
    themes.forEach((theme, index) => {
      formData.append(`themes[${index}][id]`, theme.id.toString());
      formData.append(`themes[${index}][name]`, theme.name);
      formData.append(`themes[${index}][cards]`, theme.cards.toString());
    });

    // Append intro
    formData.append("introTitle", introTitle);
    formData.append("intro", intro);

    // Append pairs data
    const pairsData = pairs.map((pair) => ({
      id: pair.id,
      type: pair.dropdownValue?.id || null,
      value: pair.inputValue || "",
      order: pair.order || 0,
    }));
    formData.append("particles", JSON.stringify(pairsData));

    try {
      setIsLoading(true);

      const response = await postRequest(
        `${baseURL}/lesson/create-lesson`,
        formData
      );

      if (response?.data.success) {
        const createdLessonData = response.data.data.lesson;
        const createdParticlesData = response.data.data.sortedParticles;
        setLessonId(createdLessonData.id);
        setTitle(createdLessonData.title);
        setTitleColor(createdLessonData.titleColor);
        setSubTitleColor(createdLessonData.subTitleColor);
        setSubTitleBgColor(createdLessonData.subTitleBgColor);
        setCoverImage(createdLessonData.coverImage);
        setStartingLessonImage(createdLessonData.startingLessonImage);
        setMyLessonsImage(createdLessonData.mylessonsImage);
        setIntro(createdLessonData.intro);
        setIntroTitle(createdLessonData.introTitle);
        const fetchedPairs = await Promise.all(
          createdParticlesData.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error creating lesson.");
    }
  };

  const handleUpdateLesson = async () => {
    const formData = new FormData();
    if (lessonId !== null) {
      // Check if lessonId is not null
      formData.append("id", lessonId.toString());
    } else {
      return; // Exit the function early if lessonId is null
    }
    formData.append("title", title);
    formData.append("level", level);
    formData.append("titleColor", titleColor);
    formData.append("subTitleColor", subTitleColor);
    formData.append("subTitleBgColor", subTitleBgColor);
    if (coverImage) formData.append("coverImage", coverImage);
    if (startingLessonImage)
      formData.append("startingLessonImage", startingLessonImage);
    if (myLessonsImage) formData.append("myLessonsImage", myLessonsImage);
    themes.forEach((theme, index) => {
      if (theme.id !== undefined && theme.cards !== undefined) {
        formData.append(`themes[${index}][id]`, theme.id.toString());
        formData.append(`themes[${index}][name]`, theme.name);
        formData.append(`themes[${index}][cards]`, theme.cards.toString());
      } else {
        console.error(
          `Theme at index ${index} is missing id or cards property`
        );
      }
    });

    formData.append("introTitle", introTitle);
    formData.append("intro", intro);

    const pairsData = pairs.map((pair) => ({
      id: pair.id,
      type: pair.dropdownValue?.id || null,
      value: pair.inputValue || "",
      order: pair.order || 0,
    }));
    formData.append("particles", JSON.stringify(pairsData));

    try {
      setIsLoading(true);
      const response = await multiPostRequest(
        `${baseURL}/lesson/update-lessons`,
        formData
      );

      if (response?.data.success) {
        const updatedLessonData = response.data.data.lesson;
        const updatedParticlesData = response.data.data.sortedParticles;

        setTitle(updatedLessonData.title);
        setTitleColor(updatedLessonData.titleColor);
        setSubTitleColor(updatedLessonData.subTitleColor);
        setSubTitleBgColor(updatedLessonData.subTitleBgColor);
        setCoverImage(updatedLessonData.coverImage);
        setStartingLessonImage(updatedLessonData.startingLessonImage);
        setMyLessonsImage(updatedLessonData.mylessonsImage);
        setIntro(updatedLessonData.intro);
        setIntroTitle(updatedLessonData.introTitle);

        const fetchedPairs = await Promise.all(
          updatedParticlesData.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);

        toast.success(response.data.statusMessage);
      } else {
        toast.error("Failed to update lesson.");
      }

      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error updating lesson.");
    }
  };

  const handleSaveTheme = async () => {
    if (!themeName) {
      setThemeError("Please enter theme name");
      return;
    }

    const activeLessonId = lessonId || Number(id);

    if (!activeLessonId) {
      setThemeError("Lesson ID is missing.");
      toast.error("Lesson ID is missing.");
      return;
    }
    if (editThemes) {
      // If editThemes exists, update the theme
      await handleUpdateTheme(editThemes.id, themeName);
    } else {
      // Otherwise, create a new theme
      await handleCreateTheme(activeLessonId, themeName);
    }
  };

  const handleCreateTheme = async (lessonId: number, themeName: string) => {
    try {
      const reqData = {
        lessonId,
        name: themeName,
      };

      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/create-theme`,
        reqData
      );
      setIsLoading(false);
      const newTheme: Theme = {
        id: response?.data.data.id,
        name: response?.data.data.name,
        cards: 0,
      };

      setThemes([...themes, newTheme]);
      setThemeName("");
      setNewThemes(false);
    } catch (error) {
      setThemeError("Error creating Theme");
      toast.error("Error creating Theme.");
    }
  };

  const handleUpdateTheme = async (themeId: number, themeName: string) => {
    try {
      const reqData = {
        name: themeName,
      };

      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-themes/${themeId}`,
        reqData
      );
      setIsLoading(false);

      const updatedThemes = themes.map((theme) =>
        theme.id === themeId ? { ...theme, name: themeName } : theme
      );

      setThemes(updatedThemes);
      setThemeName("");
      setNewThemes(false);
      setEditThemes(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      setThemeError("Error updating Theme");
      toast.error("Error updating Theme.");
    }
  };

  const handleDeleteTheme = (id: number, name: string) => {
    setThemeToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}" ?`);
    setOnConfirmDelete(() => () => handleConfirmDeleteTheme(id));
    setShowDeleteDialog(true);
  };

  const handleConfirmDeleteTheme = async (id: number) => {
    try {
      const response = await deleteRequest(
        `${baseURL}/lesson/delete-themes/${id}`
      );
      const updatedThemes = themes.filter((theme) => theme.id !== id);
      setThemes(updatedThemes);
      setShowDeleteDialog(false);
      setThemeToDelete(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error deleting flashcard:", error);
      setThemeError("Error deleting flashcard");
      setShowDeleteDialog(false);
    }
  };

  const removePair = (index: number) => {
    const updatedPairs = [...pairs];
    updatedPairs.splice(index, 1);
    setPairs(updatedPairs);
  };

  const handleDeletePair = (index: number, id?: number) => {
    console.log("index: ", index, "id: ", id);
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this pair?");
      setOnConfirmDelete(() => () => handleConfirmDeletePair(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair(index);
    }
  };

  const handleConfirmDeletePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "lessonIntros",
          id: id,
        };

        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setThemeToDelete(null);
    setPairToDelete(null);
  };

  const handleEditTheme = (theme: Theme) => {
    setThemeName(theme.name);
    setEditThemes(theme);
    setNewThemes(true);
  };

  const handleAddSubTheme = (id: number | null, name: string) => {
    // Assuming your sub-themes page route is '/sub-themes'
    navigate(`/lessons/theme/${id}`, {
      state: { themeId: id, themeName: name },
    });
  };

  const handleBackClick = () => {
    navigate(`/lessons`);
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <Label
              fontSize="22px"
              alignItems="center"
              display="flex"
              justifyContent="center"
              color="#7542E4"
              fontWeight={700}
            >
              Add a lesson
            </Label>
            <VerticalSpace height={20} />
            <div className="row p-3 d-flex main-container justify-content-between">
              <div className="col lesson-container">
                <div
                  className="container p-4"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 66%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="d-flex justify-content-between lessons-top align-items-center">
                    <RowContainer gap="0px">
                      <button
                        type="button"
                        className={`btn ${
                          selectedButton === "Cover"
                            ? "btn-success"
                            : "btn-outline-dark"
                        } me-2 text-center`}
                        style={{
                          color:
                            selectedButton === "Cover" ? "#FFFFFF" : "#100F15",
                          backgroundColor:
                            selectedButton === "Cover"
                              ? "#000000"
                              : "transparent",
                          border:
                            selectedButton === "Cover"
                              ? "none"
                              : "1px solid #100F15",
                          borderRadius: "46px",
                          padding: "8px 30px",
                          fontSize: "12px",
                          width: "auto",
                        }}
                        onClick={() => handleButtonClick("Cover")}
                      >
                        Cover
                      </button>
                      <button
                        type="button"
                        className={`btn ${
                          selectedButton === "Intro"
                            ? "btn-success"
                            : "btn-outline-dark"
                        } me-2 text-center`}
                        style={{
                          color:
                            selectedButton === "Intro" ? "#FFFFFF" : "#100F15",
                          backgroundColor:
                            selectedButton === "Intro"
                              ? "#000000"
                              : "transparent",
                          border:
                            selectedButton === "Intro"
                              ? "none"
                              : "1px solid #100F15",
                          borderRadius: "46px",
                          fontSize: "12px",
                          width: "auto",
                          padding: "8px 30px",
                        }}
                        onClick={() => handleButtonClick("Intro")}
                      >
                        Intro
                      </button>
                    </RowContainer>
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <RadioButton
                        checked={level === "A1"}
                        onChange={() => handleLevelChange("A1")}
                      />
                      <Label fontSize="14px" fontWeight={400}>
                        A1
                      </Label>
                      <RadioButton
                        checked={level === "A2"}
                        onChange={() => handleLevelChange("A2")}
                      />
                      <Label fontSize="14px" fontWeight={400}>
                        A2
                      </Label>

                      <RadioButton
                        checked={level === "B1"}
                        onChange={() => handleLevelChange("B1")}
                      />
                      <Label fontSize="14px" fontWeight={400}>
                        B1
                      </Label>
                    </div>
                  </div>
                  <VerticalSpace height={10} />
                  {showCoverForm && (
                    <div className="col">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <Label fontSize="20px" fontWeight={700}>
                            Title
                          </Label>
                        </div>
                        <VerticalSpace height={10} />
                        <input
                          type="text"
                          placeholder="Tiempos Verbales"
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          id="lessonstitle"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errors.title && (
                          <div className="invalid-feedback">{errors.title}</div>
                        )}
                      </div>
                      <VerticalSpace height={20} />
                      <RowContainer
                        className="color-set"
                        alignItem="center"
                        justifyContent="space-between"
                      >
                        <ColumnContainer
                          alignItem="center"
                          className="item-color"
                        >
                          <Label
                            fontSize="14px"
                            width="max-content"
                            fontWeight={400}
                          >
                            Theme Color
                          </Label>
                          <input
                            type="color"
                            value={titleColor}
                            className="form-control form-control-color"
                            id="myColor"
                            onChange={(e) => setTitleColor(e.target.value)}
                          />
                        </ColumnContainer>
                        <ColumnContainer
                          alignItem="center"
                          className="item-color"
                        >
                          <Label
                            fontSize="14px"
                            width="max-content"
                            fontWeight={400}
                          >
                            SubTheme Color
                          </Label>
                          <input
                            type="color"
                            value={subTitleColor}
                            className="form-control form-control-color"
                            id="myColor"
                            onChange={(e) => setSubTitleColor(e.target.value)}
                          />
                        </ColumnContainer>
                        <ColumnContainer
                          alignItem="center"
                          className="item-color"
                        >
                          <Label
                            fontSize="14px"
                            width="max-content"
                            fontWeight={400}
                          >
                            SubTheme Bg Color
                          </Label>
                          <input
                            type="color"
                            value={subTitleBgColor}
                            className="form-control form-control-color"
                            id="myColor"
                            onChange={(e) => setSubTitleBgColor(e.target.value)}
                          />
                        </ColumnContainer>
                      </RowContainer>
                      <VerticalSpace height={20} />
                      {!coverImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            Cover Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div className="drop_box">
                            <header>
                              <h4 className="image-text">
                                Upload a&nbsp;
                                <span style={{ color: "#7542E4" }}>cover</span>
                                &nbsp;image for the lesson section
                              </h4>
                              <h4>jpg, png or svg</h4>
                            </header>

                            <input
                              type="file"
                              className={`form-control ${
                                errors.coverImage ? "is-invalid" : ""
                              }`}
                              ref={fileInputRef}
                              hidden
                              accept="image/*"
                              onChange={handleCoverImageChange}
                            />

                            <VerticalSpace height={10} />
                            <button
                              type="button"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",
                                borderRadius: "10px",
                                padding: "14px 30px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              onClick={() => handleChooseFileClick("cover")}
                            >
                              Choose file
                            </button>
                            {errors.coverImage && (
                              <div className="invalid-feedback d-flex justify-content-center">
                                {errors.coverImage}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {coverImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            Cover Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div
                            className="drop_box"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {typeof coverImage === "string" ? (
                                <>
                                  <img
                                    src={coverImage}
                                    alt="Cover"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleChooseFileClick("cover")
                                    }
                                  />
                                  <input
                                    type="file"
                                    ref={fileInputRef} // Attach ref to file input
                                    hidden
                                    accept="image/*"
                                    onChange={handleCoverImageChange}
                                  />
                                </>
                              ) : (
                                coverImage && (
                                  <img
                                    src={URL.createObjectURL(coverImage)}
                                    alt="Cover"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                    }}
                                  />
                                )
                              )}
                              <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {coverImage.name}
                              </p>{" "}
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteImage("cover");
                              }}
                            >
                              <img
                                src={deleteIcon}
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <VerticalSpace height={10} />
                      {!startingLessonImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            Starting Lesson Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div className="drop_box">
                            <header>
                              <h4 className="image-text">
                                Upload a&nbsp;
                                <span style={{ color: "#7542E4" }}>
                                  starting lesson
                                </span>
                                &nbsp;image for the lesson section
                              </h4>
                              <h4>jpg, png or svg</h4>
                            </header>

                            <input
                              type="file"
                              hidden
                              ref={startingLessonInputRef}
                              className={`form-control ${
                                errors.startingLessonImage ? "is-invalid" : ""
                              }`}
                              accept="image/*"
                              onChange={handleStartingLessonImageChange}
                            />
                            <VerticalSpace height={10} />
                            <button
                              type="button"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",
                                borderRadius: "10px",
                                padding: "14px 30px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              onClick={() =>
                                handleChooseFileClick("startingLesson")
                              }
                            >
                              Choose file
                            </button>
                            {errors.startingLessonImage && (
                              <div className="invalid-feedback  d-flex justify-content-center">
                                {errors.startingLessonImage}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {startingLessonImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            Starting Lesson Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div
                            className="drop_box"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {typeof startingLessonImage === "string" ? (
                                <>
                                  <img
                                    src={startingLessonImage}
                                    alt="starting-lessons-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleChooseFileClick("startingLesson")
                                    }
                                  />
                                  <input
                                    type="file"
                                    hidden
                                    ref={startingLessonInputRef}
                                    accept="image/*"
                                    onChange={handleStartingLessonImageChange}
                                  />
                                </>
                              ) : (
                                <img
                                  src={URL.createObjectURL(startingLessonImage)}
                                  alt="starting-lessons-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                              )}
                              <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {startingLessonImage.name}
                              </p>{" "}
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteImage("startingLesson");
                              }}
                            >
                              <img
                                src={deleteIcon}
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <VerticalSpace height={10} />
                      {!myLessonsImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            My Lessons Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div className="drop_box">
                            <header>
                              <h4 className="image-text">
                                Upload a&nbsp;
                                <span style={{ color: "#7542E4" }}>
                                  my lessons
                                </span>
                                &nbsp;image for the lesson section
                              </h4>
                              <h4>jpg, png or svg</h4>
                            </header>

                            <input
                              type="file"
                              hidden
                              ref={myLessonsInputRef}
                              className={`form-control ${
                                errors.myLessonsImage ? "is-invalid" : ""
                              }`}
                              accept="image/*"
                              onChange={handleMyLessonsImageChange}
                            />
                            <VerticalSpace height={10} />
                            <button
                              type="button"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",
                                borderRadius: "10px",
                                padding: "14px 30px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              onClick={() => handleChooseFileClick("myLessons")}
                            >
                              Choose file
                            </button>
                            {errors.myLessonsImage && (
                              <div className="invalid-feedback  d-flex justify-content-center">
                                {errors.myLessonsImage}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {myLessonsImage && (
                        <div className="col-12">
                          <Label fontSize="20px" fontWeight={700}>
                            My Lessons Image
                          </Label>
                          <VerticalSpace height={4} />
                          <div
                            className="drop_box"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {typeof myLessonsImage === "string" ? (
                                <>
                                  <img
                                    src={myLessonsImage}
                                    alt="my-lessoms-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleChooseFileClick("myLessons")
                                    }
                                  />
                                  <input
                                    type="file"
                                    hidden
                                    ref={myLessonsInputRef}
                                    accept="image/*"
                                    onChange={handleMyLessonsImageChange}
                                  />
                                </>
                              ) : (
                                <img
                                  src={URL.createObjectURL(myLessonsImage)}
                                  alt="my-lessoms-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                              )}
                              <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {myLessonsImage.name}
                              </p>{" "}
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteImage("myLessons");
                              }}
                            >
                              <img
                                src={deleteIcon}
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {showIntroForm && (
                    <div className="col">
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          Intro Title
                        </Label>
                        <VerticalSpace height={10} />
                        <input
                          type="text"
                          placeholder="Intro title"
                          className="form-control"
                          id="introtitle"
                          value={introTitle}
                          onChange={(e) => setIntroTitle(e.target.value)}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          Intro
                        </Label>{" "}
                        <VerticalSpace height={10} />
                        <TextEditor value={intro} onChange={handleIntro} />
                      </div>
                      <VerticalSpace height={20} />
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          Partículas
                        </Label>
                        <VerticalSpace height={10} />
                        <div
                          className="col d-flex flex-column align-itmes-center particle-example"
                          style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                        >
                          {pairs.map((pair, index) => (
                            <div
                              key={index}
                              className="row particle-row"
                              style={{
                                flexWrap: "unset",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="col particle-dropdown"
                                style={{ flex: "1", minWidth: "0" }}
                              >
                                <NestedMenu
                                  data={
                                    pair.dropdownValue
                                      ? [pair.dropdownValue!]
                                      : []
                                  }
                                  onSelectItem={(value) =>
                                    handleDropdownChange(
                                      index,
                                      value as DropdownValue
                                    )
                                  }
                                  borderColor={
                                    validationError.pairs[index]?.dropdownValue
                                      ? "#dc3545"
                                      : "#D9D9D9"
                                  }
                                />
                                <div
                                  className="p-0 delete-icon"
                                  style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  onClick={() =>
                                    handleDeletePair(index, pair.id)
                                  }
                                >
                                  <img
                                    src={deleteIcon}
                                    style={{
                                      WebkitUserSelect: "none",
                                      userSelect: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col particle-input">
                                <input
                                  type="text"
                                  value={pair.inputValue || ""}
                                  onChange={(e) =>
                                    handleInputChange(index, e.target.value)
                                  }
                                  className={`form-control ${
                                    validationError.pairs[index]?.inputValue
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder=""
                                  style={{
                                    borderRadius: "10px",
                                    height: "40px",
                                    fontSize: "14px",
                                  }}
                                />
                              </div>
                              <div
                                className="p-0 delete-button"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() => handleDeletePair(index, pair.id)}
                              >
                                <img src={deleteIcon} />
                              </div>
                            </div>
                          ))}
                        </div>
                        <VerticalSpace height={16} />

                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2 text-center"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 40px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={addNewPair}
                        >
                          Add new
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <HorizontalSpace width={5} />
              <div className="col theme-container">
                <div
                  className="container p-4"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 66%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="col-12 theme-content">
                    <div className="d-flex justify-content-between">
                      <Label fontSize="20px" fontWeight={700}>
                        Themes
                      </Label>
                    </div>

                    <div>
                      {themes.length === 0 ? (
                        <div className="p-5 d-flex justify-content-center align-items-center ">
                          <label
                            htmlFor="theme"
                            className="form-label"
                            style={{ fontWeight: "bold", color: "#7542E4" }}
                          >
                            There are no themes yet in this lesson.
                          </label>
                        </div>
                      ) : (
                        themes.map((theme) => (
                          <div
                            className="row d-flex justify-content-center align-items-center "
                            style={{ gap: "10px" }}
                            key={theme.id}
                          >
                            <div
                              className="drop_box theme-box col-9"
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Label
                                  fontSize="14px"
                                  color="#000"
                                  fontWeight={400}
                                >
                                  {theme.name}
                                </Label>
                              </div>

                              <div style={{ cursor: "pointer" }}>
                                <Label
                                  fontSize="16px"
                                  color="#000"
                                  fontWeight={400}
                                  cursor="pointer"
                                  padding="8px 13px 8px 13px"
                                  bgColor="#DAD7FE"
                                  justifyContent="center"
                                  display="flex"
                                  borderRadius="40px"
                                >
                                  {theme.cards} cards
                                </Label>
                              </div>
                            </div>
                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() => handleEditTheme(theme)}
                            >
                              <img
                                src={editIcon}
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                                backgroundColor: "rgb(203 203 203 / 99%)",
                                borderRadius: "10px",
                              }}
                              onClick={() =>
                                handleAddSubTheme(theme.id, theme.name)
                              }
                            >
                              <img
                                src={plusIcon}
                                width="31px"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>

                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() =>
                                handleDeleteTheme(theme.id, theme.name)
                              }
                            >
                              <img
                                src={deleteIcon}
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>

                    <button
                      type="button"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        borderRadius: "10px",
                        padding: "14px 40px",
                        fontSize: "14px",
                        width: "auto",
                      }}
                      onClick={handleNewThemes}
                    >
                      Add new
                    </button>
                  </div>
                </div>
              </div>
              <VerticalSpace height={200} />
              <div
                className="container row d-flex justify-content-center publish-button"
                style={{
                  position: "fixed",
                  height: "12%",
                  bottom: "0px",
                  zIndex: 1,
                  background:
                    "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
                }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "fixed",
                    zIndex: 1,
                    bottom: "34px",

                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {" "}
                  <button
                    type="button"
                    className="btn btn-success d-flex justify-content-center"
                    style={{
                      backgroundColor: "#FF9E00",
                      color: "#100F15",
                      borderRadius: "10px",
                      height: "fit-content",
                      padding: "10px 20%",
                      width: "400px",
                      fontSize: "14px",
                    }}
                    onClick={handlePublishClick}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>

          {addThemes && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 3,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body">
                    <h5 style={{ fontWeight: "bold" }}>Add a theme</h5>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputthemes"
                      placeholder="New theme name"
                      value={themeName}
                      onChange={(e) => setThemeName(e.target.value)}
                    />
                    {themeError && (
                      <div className="error-message">{themeError}</div>
                    )}
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setNewThemes(false)}
                      style={{
                        // height: "50px",
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        borderRadius: "10px",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                        borderRadius: "10px",
                      }}
                      onClick={handleSaveTheme}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default CreateLessons;
