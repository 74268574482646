// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .main-responsive{
        gap: 10px;
        padding: 0;
    }

.responsive{
    gap: 10px;
    justify-content: space-between !important;
    flex-direction: row;
    padding: 0;
}

.tickets-filter{
     width: 100% !important;
     justify-content: center !important;
     align-items: center !important;
}

.tickets-count{
    width: 100% !important;
    justify-content: center !important;
    display: grid !important;
    grid-template-columns: repeat(2,auto) !important;
}
.users-count{
    width: 100% !important;
    justify-content: center !important;
    display: grid !important;
    grid-template-columns: repeat(2,auto) !important;
}
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/indexpage.css"],"names":[],"mappings":"AAAA;IACI;QACI,SAAS;QACT,UAAU;IACd;;AAEJ;IACI,SAAS;IACT,yCAAyC;IACzC,mBAAmB;IACnB,UAAU;AACd;;AAEA;KACK,sBAAsB;KACtB,kCAAkC;KAClC,8BAA8B;AACnC;;AAEA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,wBAAwB;IACxB,gDAAgD;AACpD;AACA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,wBAAwB;IACxB,gDAAgD;AACpD;AACA","sourcesContent":["@media (max-width: 768px) {\n    .main-responsive{\n        gap: 10px;\n        padding: 0;\n    }\n\n.responsive{\n    gap: 10px;\n    justify-content: space-between !important;\n    flex-direction: row;\n    padding: 0;\n}\n\n.tickets-filter{\n     width: 100% !important;\n     justify-content: center !important;\n     align-items: center !important;\n}\n\n.tickets-count{\n    width: 100% !important;\n    justify-content: center !important;\n    display: grid !important;\n    grid-template-columns: repeat(2,auto) !important;\n}\n.users-count{\n    width: 100% !important;\n    justify-content: center !important;\n    display: grid !important;\n    grid-template-columns: repeat(2,auto) !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
