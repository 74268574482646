import React, { useState } from "react";
import Label from "../Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "../Common/RowContainer";
import Container from "../Common/Container";
import HorizontalSpace from "../HorizontalSpace";
import editIcon from "../../assets/editIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import styled from "styled-components";
import Switch from "../Common/Switch";
import CustomCheckbox from "../Common/CustomCheckbox";
import CustomDropdown from "../Common/CustomDropdown";
import formatDate from "../DateFormat";
import ViewIcon from "../../assets/Images/viewIcon.png";

interface TicketProps {
  ticket: Ticket;
  onDelete: (id: number) => void;
  onStatusChange: (id: number, status: string) => void;
  onEdit: (id: number) => void;
}

interface Ticket {
  id: number;
  status: string;
  ticketId: string;
  problem: string;
  description: string;
  email: string;
  userId: string;
  updatedAt: string;
}

// const Label = styled`
//   font-size: ${(props) => props.fontSize || "16px"};
//   font-weight: ${(props) => props.fontWeight || 400};
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// `;

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DropdownContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
   display:none
  }
`;

const TicketsList: React.FC<TicketProps> = ({
  ticket,
  onDelete,
  onStatusChange,
  onEdit,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  //   const [ticketStatus, setTicketStatus] = useState(status);
  const dropdownItems: string[] = [
    "Open",
    "Urgent",
    "Cancel",
    "Medium",
    "Solved",
  ];

  const handleStatusChange = (status: string) => {
    onStatusChange(ticket.id, status);
  };

  const getPriorityLabel = (priority: any) => {
    switch (priority) {
      case "Open":
        return (
          <Label
            fontSize="10px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#7542E4"
            justifyContent="center"
            display="flex"
            width="82px"
            borderRadius="40px"
            padding="8px 13px"
          >
            Open
          </Label>
        );
      case "Urgent":
        return (
          <Label
            fontSize="10px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#EB002C"
            justifyContent="center"
            display="flex"
            width="82px"
            borderRadius="40px"
            padding="8px 13px"
          >
            Urgent
          </Label>
        );
      case "Cancel":
        return (
          <Label
            fontSize="10px"
            color="#100F15"
            fontWeight={400}
            cursor="pointer"
            bgColor="#D9D9D9"
            justifyContent="center"
            display="flex"
            width="82px"
            borderRadius="40px"
            padding="8px 13px"
          >
            Cancelled
          </Label>
        );
      case "Solved":
        return (
          <Label
            fontSize="10px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#28A138"
            justifyContent="center"
            display="flex"
            width="82px"
            borderRadius="40px"
            padding="8px 13px"
          >
            Solved
          </Label>
        );
      case "Medium":
      default:
        return (
          <Label
            fontSize="10px"
            color="#100F15"
            fontWeight={400}
            cursor="pointer"
            bgColor="#FF9E00"
            justifyContent="center"
            display="flex"
            width="82px"
            borderRadius="40px"
            padding="8px 13px"
          >
            Medium
          </Label>
        );
    }
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      // width="1320px"
      width="100%"
      className="user"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        // gap="3.6px"
        margin="10px"
      >
        <RowContainer flex="5" gap="0px">
          {getPriorityLabel(ticket.status)}
        </RowContainer>
        <NewRowContainer flex="4" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {ticket.ticketId}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="5" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {ticket.problem}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="9" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {ticket.description}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="9" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {ticket.email}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="5" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400} marginLeft="15px">
            {ticket.userId}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="6">
          <NewLabel fontSize="16px" fontWeight={400}>
            {formatDate(ticket.updatedAt)}
          </NewLabel>
        </NewRowContainer>
        <DropdownContainer flex="6">
          <CustomDropdown
            items={dropdownItems}
            onSelect={handleStatusChange}
            selectedItem={ticket.status}
            width="130px"
            padding="8px 10px"
            height="40px"
            border="1px solid #D9D9D9"
            zIndex="-1"
          />
        </DropdownContainer>
        <div
          className="user-scale"
          style={{
            cursor: "pointer",
            backgroundColor: "#e6e6e6",
            // opacity: "10%",
            alignItems: "center",
            display: "flex",
            borderRadius: "10px",
            padding: "4px",
          }}
        >
          <img
            src={ViewIcon}
            width="24px"
            height="24px"
            title="view message"
            onClick={() => onEdit(ticket.id)}
            style={{
              WebkitUserSelect: "none",
              userSelect: "none",
            }}
          />
        </div>
      </RowContainer>
    </Container>
  );
};
export default TicketsList;
