import React from "react";
import "../../stylesheets/privacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h2 className="centered-heading">Política de Privacidad de Lexik</h2>

      <h3 className="heading-three">Última actualización: [Fecha]</h3>

      <p className="p-color">
        En Lexik, valoramos tu privacidad y nos comprometemos a proteger tus
        datos personales. Esta Política de Privacidad describe cómo recopilamos,
        usamos, almacenamos y protegemos tu información cuando utilizas nuestra
        aplicación de aprendizaje de alemán centrada en la gramática.
      </p>
      <ol>
        <li>
          <b>Información que Recopilamos</b>
          <p className="p-color">
            Recopilamos diferentes tipos de información para mejorar tu
            experiencia en Lexik:
          </p>
          <ol>
            <li>
              Información Personal:
              <p className="p-color">
                Al registrarte, podemos solicitar información personal, como tu
                nombre, dirección de correo electrónico y datos de inicio de
                sesión.
              </p>
            </li>
            <li>
              Información de Uso:
              <p className="p-color">
                Recopilamos información sobre cómo utilizas la aplicación,
                incluidos los temas gramaticales que seleccionas, los ejercicios
                que completas, los desafíos en los que participas y las fichas
                que utilizas.
              </p>
            </li>
            <li>
              Notificaciones:
              <p className="p-color">
                Para enviarte recordatorios y notificaciones sobre los temas
                seleccionados y los desafíos, recopilamos información sobre tus
                preferencias de notificación.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Uso de la Información</b>
          <p className="p-color">
            Usamos la información recopilada para los siguientes propósitos:
          </p>
          <ol>
            <li>
              Mejora del Servicio:
              <p className="p-color">
                Utilizamos tus datos para mejorar y personalizar tu experiencia
                de aprendizaje, incluyendo la adaptación de los temas
                gramaticales y ejercicios a tus necesidades.
              </p>
            </li>
            <li>
              Notificaciones:
              <p className="p-color">
                Enviamos recordatorios y notificaciones para ayudarte a seguir
                tu plan de estudio y completar los desafíos dentro del tiempo
                establecido.
              </p>
            </li>
            <li>
              Análisis y Estadísticas:
              <p className="p-color">
                Analizamos los datos de uso para comprender mejor cómo los
                usuarios interactúan con Lexik y mejorar nuestros servicios.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Compartición de Información</b>
          <p className="p-color">
            No compartimos tu información personal con terceros, excepto en las
            siguientes circunstancias:
          </p>
          <ol>
            <li>
              Con tu Consentimiento:
              <p className="p-color">
                Podremos compartir información con terceros si contamos con tu
                consentimiento explícito.
              </p>
            </li>
            <li>
              Cumplimiento Legal:
              <p className="p-color">
                Podemos divulgar tu información si es requerido por la ley o en
                respuesta a una solicitud legal válida.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Seguridad de la Información</b>
          <p className="p-color">
            Implementamos medidas de seguridad razonables para proteger tu
            información personal contra accesos no autorizados, pérdida o
            alteración. Sin embargo, ningún método de transmisión por Internet o
            almacenamiento electrónico es 100% seguro.
          </p>
        </li>
        <li>
          <b>Retención de Datos</b>
          <p className="p-color">
            Retenemos tu información personal solo durante el tiempo necesario
            para cumplir con los propósitos descritos en esta política, a menos
            que la ley exija o permita un período de retención más largo.
          </p>
        </li>
        <li>
          <b>Tus Derechos</b>
          <p className="p-color">
            Tienes derecho a acceder, corregir, actualizar o eliminar tu
            información personal. Puedes ejercer estos derechos contactándonos a
            través de la información de contacto proporcionada a continuación.
          </p>
        </li>
        <li>
          <b>Cambios en la Política de Privacidad</b>
          <p className="p-color">
            Podemos actualizar esta Política de Privacidad de vez en cuando. Te
            notificaremos cualquier cambio publicando la nueva política en esta
            página. Te recomendamos revisar esta política periódicamente para
            estar al tanto de cualquier cambio.
          </p>
        </li>
        <li>
          <b>Contacto</b>
          <p className="p-color">
            Si tienes alguna pregunta o inquietud sobre esta Política de
            Privacidad o nuestras prácticas de privacidad, puedes contactarnos
            en:
          </p>
        </li>
      </ol>
      <div className="contact-info">
        <strong>Lexik</strong>
        <br />
        <p className="contact-detail">[Dirección de la empresa]</p>
        <p className="contact-detail">
          <a href="mailto:info@lexik.app">info@lexik.app</a>
        </p>
        <p className="contact-detail">[Teléfono de contacto]</p>
      </div>
      <br />
      <b className="bold">
        Gracias por confiar en Lexik para tu aprendizaje del alemán. Estamos
        comprometidos a proteger tu privacidad y mejorar continuamente tu
        experiencia de aprendizaje.
      </b>
    </div>
  );
};

export default PrivacyPolicy;
