// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* radiobutton.css */
.custom-radio .form-check-input {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    /* Make it circular for radio button */
    margin-top: 0px;
    margin-left: 5px;
    
}

.custom-radio .form-check-input:checked {
    background-color: #7542e4;
    border: none;
}

.custom-radio .form-check-input:not(:checked) {
    background-color: #262323;
    opacity: 30%;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/radiobutton.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;IACtC,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB","sourcesContent":["/* radiobutton.css */\n.custom-radio .form-check-input {\n    width: 23px;\n    height: 23px;\n    border-radius: 50%;\n    /* Make it circular for radio button */\n    margin-top: 0px;\n    margin-left: 5px;\n    \n}\n\n.custom-radio .form-check-input:checked {\n    background-color: #7542e4;\n    border: none;\n}\n\n.custom-radio .form-check-input:not(:checked) {\n    background-color: #262323;\n    opacity: 30%;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
