import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipUser from "../../Components/membershipUser";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import "../../assets/fonts/font.css";
import "../../stylesheets/indexpage.css";
import VerticalSpace from "../../Components/VerticalSpace";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px; /* Ensures minimum width to trigger horizontal scrolling */
`;

interface Member {
  id: number;
  name: string;
  lastname: string;
  email: string;
  membership: string;
  since: string;
  until: string;
  active: boolean;
}

const MemberShipUsers = () => {
  const [users, setUsers] = useState<Member[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(5);
  const [count, setCount] = useState(5);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
   const [filters, setFilters] = useState<{
     level?: string;
     updatedAt?: number;
   }>({});
  const navigate = useNavigate();

  const dropdownItems: string[] = ["ID", "Membership", "Since", "Until"];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  // Mock data
  useEffect(() => {
    // Fetch or set your user data here
    const mockData: Member[] = [
      {
        id: 1,
        name: "John",
        lastname: "Doe",
        email: "john@example.com",
        membership: "Yearly",
        since: "09.06.2023",
        until: "01.06.2025",
        active: true,
      },
      {
        id: 2,
        name: "Jane",
        lastname: "Doe",
        email: "jane@example.com",
        membership: "Yearly",
        since: "09.06.2023",
        until: "01.06.2025",
        active: true,
      },
      {
        id: 3,
        name: "Jane",
        lastname: "Doe",
        email: "jane@example.com",
        membership: "Monthly",
        since: "09.06.2023",
        until: "01.06.2025",
        active: true,
      },
      {
        id: 4,
        name: "Jane",
        lastname: "Doe",
        email: "jane@example.com",
        membership: "Yearly",
        since: "09.06.2023",
        until: "01.06.2025",
        active: true,
      },
      {
        id: 5,
        name: "Jane",
        lastname: "Doe",
        email: "jane@example.com",
        membership: "Yearly",
        since: "09.06.2023",
        until: "01.06.2025",
        active: true,
      },
    ];
    setUsers(mockData);
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Level":
        setFilters({ level: "A1,A2,B1" });
        break;
      case "Latest update":
        setFilters({ updatedAt: -1 });
        break;
      default:
        setFilters({});
        break;
    }
  };

  const handleEditMember = (id: number) => {
    navigate(`/members/edit-member/${id}`);
  };

  const handleStatusChange = async (id: number, active: boolean) => {
    const reqData = {
      modelName: "members",
      id: id,
      isActive: !active,
    };
    // await postRequest(`${baseURL}/change-status`, reqData);
    // await fetchTextFields(page + 1, limit, filters);
  };

  const onCheckboxChange = (id: number) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(
        selectedMembers.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedMembers([...selectedMembers, id]);
    }
  };

  const deleteSelectedMembers = () => {
    if (selectedMembers.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one member to delete.", "error");
    }
  };

  const deleteMembers = async (memberIds: number | number[]) => {
    try {
      const payload = {
        id: Array.isArray(memberIds) ? memberIds : memberIds,
      };
      const response = await postRequest(
        `${baseURL}/members-challenge/delete-members-challenge-by-id`,
        payload
      );

      if (response?.status === 200) {
        Toast("memberss deleted successfully", "success");
        setUsers((prevData) =>
          prevData.filter((members) =>
            Array.isArray(memberIds)
              ? !memberIds.includes(members.id)
              : members.id !== memberIds
          )
        );
      } else {
        Toast("Failed to delete notifications", "error");
      }
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedMemberId !== null) {
      await deleteMembers(selectedMemberId);
    } else {
      await deleteMembers(selectedMembers);
    }
    // await fetchTextFields(page + 1, limit, filters);
    setSelectedMembers([]);
    setSelectedMemberId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <div className="container-fluid">
      <div className="p-3">
        {/* Heading */}
        <div className="row mb-3 ">
          <div className="col">
            <h2 style={{ fontWeight: "bold" }}>Members Premium</h2>
          </div>
        </div>

        {/* Filter Label */}
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="filter" className="form-label">
              Filter
            </label>
          </div>
        </div>

        {/* Buttons Row */}
        <div className="row mb-3 main-responsive">
          <div className="col d-flex responsive">
            <CustomDropdown
              items={dropdownItems}
              onSelect={handleSelect}
              height="50px"
            />
            <button
              type="button"
              className="btn btn-primary ms-3"
              style={{
                backgroundColor: "#100F15",
                borderRadius: "10px",

                color: "#FFFFFF",
                fontSize: "14px",
                width: "auto",
                padding: "14px 50px",
              }}
            >
              Apply
            </button>
          </div>
          <div className="col d-flex justify-content-end responsive">
            {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",

                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedMembers}
            >
              Delete all
            </button> */}
            {/* <button
              type="button"
              className="btn btn-success"
              style={{
                backgroundColor: "#FF9E00",
                borderRadius: "10px",
                color: "#100F15",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
            >
              Add now
            </button> */}
          </div>
        </div>

        <ScrollableRowContainer>
          <InnerRowContainer>
            <Container width="100%">
              <RowContainer
                alignItem="center"
                padding="8px 12px 8px 12px"
                gap="8px"
              >
                <RowContainer flex="0.7">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  ></Label>
                </RowContainer>
                <RowContainer flex="3">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    ID
                  </Label>
                </RowContainer>
                <RowContainer flex="3">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Name
                  </Label>
                </RowContainer>
                <RowContainer flex="3">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Lastname
                  </Label>
                </RowContainer>
                <RowContainer flex="7">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    email
                  </Label>
                </RowContainer>
                <RowContainer flex="5">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Membership
                  </Label>
                </RowContainer>
                <RowContainer flex="4">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Since
                  </Label>
                </RowContainer>
                <RowContainer flex="4">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Until
                  </Label>
                </RowContainer>
                <RowContainer flex="3">
                  <Label
                    fontWeight={400}
                    fontSize="14px"
                    color="rgba(164, 163, 167, 1)"
                  >
                    Action
                  </Label>
                </RowContainer>
              </RowContainer>
              <ColumnContainer>
                {users.map((member) => (
                  <MembershipUser
                    key={member.id}
                    id={member.id}
                    name={member.name}
                    lastname={member.lastname}
                    email={member.email}
                    membership={member.membership}
                    since={member.since}
                    until={member.until}
                    onEdit={handleEditMember}
                    onDelete={() => {
                      setSelectedMemberId(member.id);
                      toggleDeleteDialog();
                    }}
                    isChecked={selectedMembers.includes(member.id)}
                    onCheckboxChange={onCheckboxChange}
                    status={member.active}
                    onStatusChange={() =>
                      handleStatusChange(member.id, member.active)
                    }
                  />
                ))}
              </ColumnContainer>
            </Container>
          </InnerRowContainer>
        </ScrollableRowContainer>
        <VerticalSpace height={10} />
        <RowContainer justifyContent="flex-end">
          <PaginationComponent
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={count}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
          />
        </RowContainer>
      </div>
      <VerticalSpace height={110} />
    </div>
  );
};

export default MemberShipUsers;
