import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../stylesheets/notFound.css";
import logobg from "../assets/Images/logobg.png";

const NotFoundPage = () => {

  const navigate = useNavigate();

  const handleReturn = () => {
    navigate("/");
  };
  return (
    <div
      className="not-found-container"
      style={{
        backgroundImage: `url(${logobg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="animated-404">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </div>
      <p>Oops! The page you are looking for does not exist.</p>
      <div
        onClick={handleReturn}
        style={{
          cursor: "pointer",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
        className="home-link"
      >
        Return to homepage
      </div>
    </div>
  );
};

export default NotFoundPage;
