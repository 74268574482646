import React, { useEffect, useState } from "react";
import RowContainer from "../../Components/Common/RowContainer";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import "../../stylesheets/indexpage.css";
import { baseURL, getRequest } from "../../api/apiClient";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loading";


interface Count {
  totalUserCount: number;
  totalLessonsCount: number;
}

const Dashboard = () => {
  const [totalCount, setTotalCount] = useState<Count | null>(null);
  const [isLoading, setIsLoading]= useState(true);
   const [editorValue, setEditorValue] = useState<string>("");
  
  const navigate = useNavigate();

  useEffect(()=>{
    fetchAllCount();
  },[])

  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };

  const fetchAllCount = async () => {
    setIsLoading(true);
    const response = await getRequest(`${baseURL}/users/get-users-count`);
      if(response?.data){
         setTotalCount(response?.data.data);
      }
    setIsLoading(false);
  };

  const handelUsers = ()=>{
    navigate('/users');
  };

  const handelLessons = () =>{
    navigate('/lessons');
  };

  return (
    <>
    {isLoading ? (
        <Loader/>
      ) : (
        <>
    <div className="container-fluid">
          <div className="p-3">
            <div className="row mb-3">
              <div className="col">
                <h2 style={{ fontWeight: "bold" }}>Dashboard</h2>
              </div>
            </div>

            <div className="row mb-3 main-responsive">
              <RowContainer
                justifyContent="flex-start"
                width="fit-content"
                className="users-count"
              >
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  cursor="pointer"
                  onClick={handelUsers}
                >
                  <ColumnContainer alignItem="center" gap="0px">
                    <Label fontWeight={500} fontSize="22px" color="#100F15">
                      Users
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#00A3FF">
                      {totalCount ? totalCount?.totalUserCount : 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  cursor="pointer"
                  onClick={handelLessons}
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Lessons
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#7542E4">
                      {totalCount?.totalLessonsCount || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                {/* <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                >
                  <ColumnContainer alignItem="center" gap="0px">
                    <Label fontWeight={500} fontSize="22px" color="#100F15">
                      Challenges
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#EB002C">
                      15
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      In progress
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#FF9E00">
                      15
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Cancelled
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#D9D9D9">
                      7
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Solved
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#28A138">
                      123
                    </Label>
                  </ColumnContainer>
                </Container> */}

                {/* <Loader /> */}
              </RowContainer>
            </div>
          </div>
    </div>
    </> 
  )}
  </>
  );
};

export default Dashboard;
