import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import Question from "../../Components/Questions";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import { toast, ToastContainer } from "react-toastify";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import "../../stylesheets/indexpage.css";
import Toast from "../../Components/Common/Toast";
import Loader from "../../utils/Loading";

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px; /* Ensures minimum width to trigger horizontal scrolling */
`;


interface Quesiton {
  id: number;
  question: string;
  questionLevel: string;
  updatedAt: string;
  active: boolean;
}

const Quesiton = () => {
  const [quetion, setquetion] = useState<Quesiton[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedQuetions, setSelectedQuestions] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  );

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
  }>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const navigate = useNavigate();

  const dropdownItems: string[] = [
    "All", 
    "Level",
    "Latest update",
  ];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const fetchquetions = async (
    page: number,
    perPage: number,
    filters: { level?: string; updatedAt?: number }
  ) => {
    setLoading(true);
    try {
      let url = `${baseURL}/question/get-questions?page=${page}&limit=${perPage}`;

      if (filters.level) {
        url += `&level=${filters.level}`;
      }

      if (filters.updatedAt) {
        url += `&updatedAt=${filters.updatedAt}`;
      }

      const response = await getRequest(url);

      
      if (response?.data) {
        setquetion(response.data.data.questions);
        setCount(response.data.data.totalQuestionsCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching lessons:", error);
    }
  };

  useEffect(() => {
    fetchquetions(page + 1, limit, filters);
  }, [page, limit, filters]);

  const handleApplyFilters = () =>{
    switch (selectedItem){
      case "Level":
        setFilters({level:"A1,A2,B1"});
        break;
      case "Latest update":
        setFilters({ updatedAt: -1 });
        break;
      default:
        setFilters({});
        break;
    }
  }

  const handleEditLessons = (id: number) => {
    navigate(`/questions/edit/${id}`);
  };

  const handleCreateLessons = () => {
    navigate("/questions/create");
  };



  const handleStatusChange =async (id: number) => {
    try {
       await getRequest(`${baseURL}/question/status/changed-status/${id}`);
    } catch (error) {
       console.error("Error status change:", error);
      // toast.error("An error occurred while  the Questions");
    }
  };

  const onCheckboxChange = (id: number) => {
    if (selectedQuetions.includes(id)) {
      setSelectedQuestions(
        selectedQuetions.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedQuestions([...selectedQuetions, id]);
    }
  };

  const deleteSelectedQuestions = () => {
    if (selectedQuetions.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one question to delete.","error");
    }
  };

  const deleteQuetions = async (questionIds: number | number[]) => {
    try {
      const payload = {
        questionId: Array.isArray(questionIds) ? questionIds : questionIds,
      };
      console.log("payload: ", payload);
      const response = await postRequest(
        `${baseURL}/question/deleteById`,
        payload
      );

      if (response?.status === 200) {
        setquetion((prevData) =>
          prevData.filter((question) =>
            Array.isArray(questionIds)
              ? !questionIds.includes(question.id)
              : question.id !== questionIds
          )
        );
      } else {
        toast.error("Failed to delete question");
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("An error occurred while deleting the Questions");
    }
  };

  const handleDelete =async () => {
    if(selectedQuestionId !== null){
      await deleteQuetions(selectedQuestionId);
    }else{
     await deleteQuetions(selectedQuetions);
    }
    await fetchquetions(page + 1, limit,filters);
    setSelectedQuestions([]);
    setSelectedQuestionId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Questionnaire Level</h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3 ">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      padding: "14px 50px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                padding: "14px 40px",
                fontSize: "14px",
                width: "auto",
                borderRadius: "10px",
              }}
              onClick={deleteSelectedQuestions}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      padding: "14px 40px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleCreateLessons}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="23">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>

                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Level
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6.7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {quetion && quetion.length > 0 ? (
                      <ColumnContainer>
                        {quetion.map((question) => (
                          <Question
                            key={question.id}
                            id={question.id}
                            question={question.question}
                            level={question.questionLevel}
                            latestdate={formatDate(question.updatedAt)}
                            onEdit={handleEditLessons}
                            onDelete={() => {
                              setSelectedQuestionId(question.id);
                              toggleDeleteDialog();
                            }}
                            isChecked={selectedQuetions.includes(question.id)}
                            onCheckboxChange={onCheckboxChange}
                            status={question.active}
                            onStatusChange={handleStatusChange}
                          />
                        ))}
                      </ColumnContainer>
                    ) : (
                      <>
                        <VerticalSpace height={30} />
                        <Label
                          alignItems="center"
                          display="flex"
                          color="rgba(164, 163, 167, 1)"
                          justifyContent="center"
                        >
                          No results found.
                        </Label>
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>

              <VerticalSpace height={100} />
            </div>

            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedQuetions.length === 0
                    ? "this question?"
                    : selectedQuetions.length === 1
                    ? "this question?"
                    : "these questions?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Quesiton;
