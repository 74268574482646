import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NewLessons from "../../Components/Lessons";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import { getRequest, postRequest, baseURL } from "../../api/apiClient";
import formatDate from "../../Components/DateFormat";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { toast } from "react-toastify";
import VerticalSpace from "../../Components/VerticalSpace";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import Toast from "../../Components/Common/Toast";
import { setDropDown } from "../../redux/lessonsSlice";
import { setParticles } from "../../redux/particlesReducerSlice";
import { useDispatch } from "react-redux";
import Loader from "../../utils/Loading";

const ReponsiveRow = styled(RowContainer)`
  @media (max-width: 1430px) {
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface lessons {
  id: number;
  title: string;
  section: string;
  subTheme: boolean;
  themeCount: number;
  level: string;
  createdAt: string;
  active: boolean;
}

const Lessons = () => {
  const [lessons, setlessons] = useState<lessons[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState<number[]>([]);
  const [selectedLessonId, setSelectedLessonId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
  }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dropdownItems: string[] = [
    "All",
    "Level",
    "Latest update",
  ];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  // Fetch lessons data from API
  const fetchLessons = async (
    page: number,
    perPage: number,
    filters: { level?: string; updatedAt?: number }
  ) => {
    setLoading(true);
    try {
       let url = `${baseURL}/lesson/get-lessons?page=${page}&limit=${perPage}`;
       if (filters.level) {
         url += `&level=${filters.level}`;
       }
       if (filters.updatedAt) {
         url += `&updatedAt=${filters.updatedAt}`;
       }
       const response = await getRequest(url);
      setLoading(false);
      if (response?.data) {
        setlessons(response.data.data.lessons);
        setCount(response.data.data.totalLessonCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }

      const fetchLessons = await getRequest(
        `${baseURL}/notification-challenge/get-lesson-dropdown`
      );

      const fetchParticles = await getRequest(
        `${baseURL}/particle/get-dropdown`
      );
      dispatch(setDropDown(fetchLessons?.data?.data || []));
      dispatch(setParticles(fetchParticles?.data?.data || []));
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };

  useEffect(() => {
    fetchLessons(page + 1, limit, filters);
  }, [page, limit, filters]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
   switch (selectedItem) {
     case "Level":
       setFilters({ level: "A1,A2,B1" });
       break;
     case "Latest update":
       setFilters({ updatedAt: -1 });
       break;
     default:
       setFilters({});
       break;
   }
  };

  const handleEditLessons = (id: number) => {
    navigate(`/lessons/edit-lessons/${id}`);
  };

  const handleCreateLessons = () => {
    navigate("/lessons/create-lessons");
  };

  const handleStatusChange = async (id: number) => {
    try {
        await getRequest(`${baseURL}/lesson/update-lesson-status/${id}`);
    } catch (error) {
    }
  };

  const onCheckboxChange = (id: number) => {
    if (selectedLessons.includes(id)) {
      setSelectedLessons(
        selectedLessons.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedLessons([...selectedLessons, id]);
    }
  };

  const deleteSelectedLessons = () => {
    if (selectedLessons.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one lessons to delete.", "error");
    }
  };

  const deleteLessons = async (lessonIds: number | number[]) => {
    try {
      const payload = {
        lessonId: Array.isArray(lessonIds) ? lessonIds : lessonIds,
      };
      const response = await postRequest(
        `${baseURL}/lesson/delete-lessons`,
        payload
      );

      if (response?.status === 200) {
        Toast("Lessons deleted successfully","success");
        setlessons((prevData) =>
          prevData.filter((lesson) =>
            Array.isArray(lessonIds)
              ? !lessonIds.includes(lesson.id)
              : lesson.id !== lessonIds
          )
        );
      } else {
        toast.error("Failed to delete lesson(s)");
      }
    } catch (error) {
      console.error("Error deleting lesson:", error);
      toast.error("An error occurred while deleting the lesson(s)");
    }
  };

  const handleDelete =async () => {
    if (selectedLessonId !== null) {
     await deleteLessons(selectedLessonId);
    } else {
     await deleteLessons(selectedLessons);
    }
    await fetchLessons(page + 1, limit, filters);
    setSelectedLessons([]);
    setSelectedLessonId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Lessons</h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ''}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedLessons}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleCreateLessons}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="8.8">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Title
                        </Label>
                      </RowContainer>
                      <RowContainer flex="7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          {/* Section */}
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Sub-theme
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Level
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3.3">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {lessons && lessons.length > 0 ? (
                      <ColumnContainer>
                        {lessons.map((lessons) => (
                          <NewLessons
                            key={lessons.id}
                            id={lessons.id}
                            title={lessons.title}
                            section={lessons.section || "N/A"}
                            subTheme={lessons.subTheme}
                            level={lessons.level}
                            latestdate={formatDate(lessons.createdAt)}
                            onEdit={handleEditLessons}
                            onDelete={() => {
                              setSelectedLessonId(lessons.id);
                              toggleDeleteDialog();
                            }}
                            isChecked={selectedLessons.includes(lessons.id)}
                            onCheckboxChange={onCheckboxChange}
                            status={lessons.active}
                            themeCount={lessons.themeCount}
                            onStatusChange={handleStatusChange}
                          />
                        ))}
                      </ColumnContainer>
                    ) : (
                      <>
                        <VerticalSpace height={30} />
                        <Label
                          alignItems="center"
                          display="flex"
                          color="rgba(164, 163, 167, 1)"
                          justifyContent="center"
                        >
                          No results found.
                        </Label>
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedLessons.length === 0
                    ? "this lessons?"
                    : selectedLessons.length === 1
                    ? "this lessons?"
                    : "these lessons?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};

export default Lessons;
