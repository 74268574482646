// Loader.tsx
import React from "react";
import "./Loading.css"; // Import loader styles
import lexikLoader from "../assets/Images/Lexik-loader1.gif";

interface ImageLoader{
  width?: string;
  height?: string;
}

const Loader: React.FC<ImageLoader> = ({width,height}) => {
  return (
    // <div className="shimmer-loader-wrapper">
    //   <div className="shimmer-loader">
    //     <div className="shimmer"></div>
    //     <div className="spinner"></div>
    //   </div>
    // </div>
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "80%" }}
    >
      <img src={lexikLoader} className="Loader-Icon" alt="Loading" height={ height || '50px'}/>
    </div>
    // <div
    //   className="d-flex align-items-center justify-content-center"
    //   style={{ height: "80%" }}
    // >
    //   <div className="spinner-border" role="status">
    //     <span className="sr-only">Loading...</span>
    //   </div>
    // </div>
  );
};

export default Loader;
