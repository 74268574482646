// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* header.css */
.navbar {
    width: 100%;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* z-index: 1000; */
    padding: 20px;
}

.slide-button {
    display: none;
}

@media screen and (max-width: 1100px) {
    .slide-button {
        display: block;
        border: none;
        
    }
}

@media (max-width: 488px) {
    .navbar {
            top: 10px;
            padding: 0px !important;
        }
        .div-control{
            width: 160px !important;
        }
}

@media (max-width: 458px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 140px !important;
    }
}

@media (max-width: 398px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 120px !important;
    }
}

@media (max-width: 380px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 90px !important;
    }
}

@media (max-width: 350px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 75px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW;IACX,8CAA8C;IAC9C,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,YAAY;;IAEhB;AACJ;;AAEA;IACI;YACQ,SAAS;YACT,uBAAuB;QAC3B;QACA;YACI,uBAAuB;QAC3B;AACR;;AAEA;IACI;QACI,SAAS;QACT,uBAAuB;IAC3B;;IAEA;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,SAAS;QACT,uBAAuB;IAC3B;;IAEA;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,SAAS;QACT,uBAAuB;IAC3B;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,SAAS;QACT,uBAAuB;IAC3B;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["/* header.css */\n.navbar {\n    width: 100%;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n    /* z-index: 1000; */\n    padding: 20px;\n}\n\n.slide-button {\n    display: none;\n}\n\n@media screen and (max-width: 1100px) {\n    .slide-button {\n        display: block;\n        border: none;\n        \n    }\n}\n\n@media (max-width: 488px) {\n    .navbar {\n            top: 10px;\n            padding: 0px !important;\n        }\n        .div-control{\n            width: 160px !important;\n        }\n}\n\n@media (max-width: 458px) {\n    .navbar {\n        top: 10px;\n        padding: 0px !important;\n    }\n\n    .div-control {\n        width: 140px !important;\n    }\n}\n\n@media (max-width: 398px) {\n    .navbar {\n        top: 10px;\n        padding: 0px !important;\n    }\n\n    .div-control {\n        width: 120px !important;\n    }\n}\n\n@media (max-width: 380px) {\n    .navbar {\n        top: 10px;\n        padding: 0px !important;\n    }\n\n    .div-control {\n        width: 90px !important;\n    }\n}\n\n@media (max-width: 350px) {\n    .navbar {\n        top: 10px;\n        padding: 0px !important;\n    }\n\n    .div-control {\n        width: 75px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
