import React from "react";
import "../../stylesheets/privacyPolicy.css";

const FaqsContent: React.FC = () => {
  return (
    <body>
      <div className="privacy-policy">
        <h2 className="centered-heading">Preguntas Frecuentes (FAQ)</h2>

        <h3 className="heading-three">General</h3>
        <ol>
          <li>
            ¿Qué es Lexik?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Lexik está diseñada para ayudar a los usuarios a aprender
                alemán, centrándose especialmente en la gramática mediante un
                innovador sistema de códigos de colores.
              </li>
            </ul>
          </li>
          <br />
          <li>
            ¿Cómo funciona Lexik?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Lexik está diseñado para ayudar a los usuarios a mejorar sus
                conocimientos de alemán proporcionándoles materiales de estudio,
                ejercicios, retos, notificaciones y fichas. Nuestro objetivo es
                crear una experiencia de aprendizaje atractiva y eficaz que
                permita a los usuarios mejorar su dominio de la gramática a su
                propio ritmo.
              </li>
            </ul>
          </li>
        </ol>
        <h3 className="heading-three">Características</h3>
        <ol>
          <li>
            ¿Qué es el sistema de códigos de colores?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Lexik emplea un sistema de colores llamativos, asignando un
                color único a cada partícula. Esto facilita su reconocimiento
                inmediato en oraciones y ejercicios. Así, reforzamos la memoria
                visual, permitiendo al usuario asociar colores con significados,
                lo que simplifica el aprendizaje de conceptos complejos. Gracias
                a los códigos de colores, la estructura de las oraciones se
                divide en partes fáciles de entender.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Qué tipos de temas gramaticales puedo estudiar?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes seleccionar entre una amplia variedad de temas
                gramaticales, como pronombres, verbos, declinación,
                preposiciones y más. Cada tema viene con ejercicios específicos
                para reforzar tu aprendizaje.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Qué son los retos y cómo funcionan?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Los retos son una forma divertida y competitiva de evaluar tu
                dominio de la gramática alemana. Puedes elegir la duración de tu
                reto (3, 5 o 7 días), dedicando solo 10 minutos al día. Estos
                desafíos se basan en los temas que has seleccionado para
                estudiar, ayudándote a identificar áreas que requieren más
                atención y consolidar tu aprendizaje de manera constante.
              </li>
            </ul>
          </li>
          <br />
          <li>
            ¿Cómo funcionan las notificaciones?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes configurar las notificaciones para recibir recordatorios
                durante 3, 5 o 7 días, con la cantidad de notificaciones que
                prefieras por día. Estas notificaciones te ayudarán a mantener
                un ritmo constante de estudio, recordándote tus temas
                seleccionados y asegurándote de que no pierdas el enfoque en tu
                aprendizaje de la gramática alemana.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Qué son las fichas y cómo puedo usarlas?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Las fichas son tarjetas de estudio que contienen información
                clave sobre los temas gramaticales. Puedes guardar y revisar
                estas fichas para ayudarte a memorizar y entender mejor la
                gramática alemana.
              </li>
            </ul>
          </li>
        </ol>

        <h3 className="heading-three">Gamificación</h3>
        <ol>
          <li>
            ¿Cómo se obtienen puntos?
            <p className="p-color">
              Lexik motiva a los usuarios mediante la gamificación,
              permitiéndoles acumular puntos a través de retos, los cuales
              pueden canjear por paquetes de lecciones especiales. Existen dos
              maneras de ganar puntos:
            </p>
          </li>

          <ol>
            <li>
              Retos de lecciones:
              <p className="p-color">
                El usuario puede poner en práctica sus conocimientos mediante
                retos temáticos. Solo necesita crear un reto sobre el tema que
                le interesa y practicar durante 10 minutos al día por 3, 5 o 7
                días, según su configuración. Cada día puede acumular hasta 10
                puntos si acierta en todos los ejercicios.
              </p>
            </li>
            <li>
              Desafíos de notificaciones:
              <p className="p-color">
                El usuario crea un reto sobre el tema de su interés y practica
                durante 3, 5 o 7 días, configurando la cantidad de
                notificaciones diarias que desea recibir (3, 5 o 7). Estas
                notificaciones contienen ejercicios que ayudan a dominar las
                reglas gramaticales y la estructura de la oración. Por cada
                ejercicio resuelto, el usuario recibe 2 puntos, pudiendo
                acumular hasta 14 puntos según su configuración.
              </p>
            </li>
          </ol>
          <br />
          <li>¿Cómo se puede subir de nivel?</li>
          <ol>
            <li>
              Existen 7 niveles que se pueden desbloquear a medida que el
              usuario completa una cantidad determinada de retos. Al subir de
              nivel, el usuario obtiene puntos extra y acceso a promociones,
              como descuentos o meses gratuitos en su membresía. A continuación
              se detallan los retos necesarios para alcanzar cada nivel:
              <ul style={{ listStyleType: "disc" }}>
                <li>Nivel 1: 5 retos</li>
                <li>Nivel 2: 20 retos</li>
                <li>Nivel 3: 35 retos</li>
                <li>Nivel 4: 50 retos</li>
                <li>Nivel 5: 60 retos</li>
                <li>Nivel 6: 70 retos</li>
                <li>Nivel 7: 87 retos</li>
              </ul>
            </li>
          </ol>
        </ol>

        <h3 className="heading-three">Cuenta y Suscripción</h3>
        <ol>
          <li>
            ¿Necesito una cuenta para usar Lexik?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Sí, necesitas crear una cuenta para guardar tu progreso y
                personalizar tu experiencia de aprendizaje.
              </li>
            </ul>
          </li>
          <br />
          <li>
            ¿Lexik es gratuita?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Ofrecemos una versión gratuita con acceso limitado a algunas
                funciones. Esta versión te permite estudiar una lección por
                semana y participar en un reto. Para acceder a todo el contenido
                y disfrutar de la app sin interrupciones publicitarias, puedes
                suscribirte a nuestro plan premium.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Cómo puedo suscribirme a la versión premium?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes suscribirte a la versión premium a través de la sección
                de ajustes de la app o desde nuestro sitio web, donde
                encontrarás varias opciones de pago.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Puedo cancelar mi suscripción en cualquier momento?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Sí, puedes cancelar tu suscripción en cualquier momento desde la
                configuración de tu cuenta. Ten en cuenta nuestra política de
                reembolso.
              </li>
            </ul>
          </li>
        </ol>

        <h3 className="heading-three">Uso y Personalización</h3>
        <ol>
          <li>
            ¿Cómo selecciono los temas gramaticales que quiero estudiar?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes seleccionar los temas gramaticales desde la sección
                lecciones en la app. Ahí encontrarás una lista de temas
                disponibles para elegir según tus necesidades.
              </li>
            </ul>
          </li>
          <br />
          <li>
            ¿Puedo personalizar las notificaciones?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Sí, puedes personalizar las notificaciones desde la
                configuración de la app, eligiendo la frecuencia y el tipo de
                recordatorios que deseas recibir.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Cómo accedo a los retos programados?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Los retos están disponibles en la sección de retos de la app o
                al final de cada lección. Puedes elegir el tema gramatical que
                deseas practicar y la duración del reto.
              </li>
              <li>
                En la versión premium, puedes iniciar tantos retos como desees,
                mientras que en la versión gratuita solo puedes participar en un
                reto por semana.
              </li>
            </ul>
          </li>
        </ol>

        <h3 className="heading-three">Problemas y Soporte</h3>
        <ol>
          <li>
            Olvidé mi contraseña. ¿Cómo puedo recuperarla?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes recuperar tu contraseña haciendo clic en "Olvidé mi
                contraseña" en la pantalla de inicio de sesión y siguiendo las
                instrucciones proporcionadas.
              </li>
            </ul>
          </li>
          <br />
          <li>
            La app no funciona correctamente. ¿Qué puedo hacer?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Primero, intenta reiniciar la app y asegúrate de tener la última
                versión instalada. Si el problema persiste, contacta a nuestro
                equipo de soporte con una descripción detallada del problema.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Cómo puedo reportar un error o dar feedback?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Puedes reportar errores o enviar tus comentarios a través de la
                sección de asistencia en la app o enviando un correo electrónico
                a nuestro equipo de soporte a{" "}
                <a href="mailto:support@lexik.com">support@lexik.com</a>
              </li>
            </ul>
          </li>
        </ol>

        <h3 className="heading-three">Privacidad y Seguridad</h3>
        <ol>
          <li>
            ¿Es segura mi información en la app?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Sí, tomamos la privacidad y seguridad de tus datos muy en serio.
                Utilizamos cifrado y otras medidas de seguridad para proteger tu
                información.
              </li>
            </ul>
          </li>
          <br />
          <li>
            ¿Qué información recopila la app?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Recopilamos información necesaria para proporcionar nuestros
                servicios, como datos de uso y la información personal que
                proporcionas al registrarte. Consulta nuestra política de
                privacidad para más detalles.
              </li>
            </ul>
          </li>

          <br />
          <li>
            ¿Cómo puedo eliminar mi cuenta?
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Si deseas eliminar tu cuenta, ve a la configuración de tu cuenta
                y sigue las instrucciones para la eliminación de la cuenta.
              </li>
            </ul>
          </li>
        </ol>

        <b className="bold">
          ¡Esperamos que disfrutes aprendiendo alemán con Lexik! Si tienes más
          preguntas, no dudes en contactar a nuestro equipo de soporte.
        </b>
      </div>
    </body>
  );
};

export default FaqsContent;
