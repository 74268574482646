import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipActivity from "../../Components/MembersActivity";
import VerticalSpace from "../../Components/VerticalSpace";
import MembershipLessons from "../../Components/LessonsRedeemed";
import HorizontalSpace from "../../Components/HorizontalSpace";
import Switch from "../../Components/Common/Switch";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import "../../stylesheets/theme.css";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../Common/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../utils/Loading";

interface User {
  id?: number;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  memberSince?: string;
  points?: number;
  level?: number;
  redeemPoints?: number;
  active?: boolean;
}

interface Activity {
  id: number;
  name: string;
  progress: string;
  duration: string;
  startdate: string;
  type: boolean;
  level: string;
  points: string;
}

interface lessons {
  id: number;
  paquete: string;
  level: string;
  date: string;
  operation: boolean;
}

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 720px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const StyledInput = styled.input`
  border-radius: 10px;
  border: none;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  width: 100%;
`;

interface ValidationErrors {
  fullName?: string;
  email?: string;
  level?: string;
  points?: string;
  redeemPoints?: string;
}

const CreateUser = () => {
  const { id } = useParams();
   const [userData, setUserData] = useState<User>({
     fullName: "",
     email: "",
     level: 0,
     memberSince: "",
     points: 0, 
     redeemPoints: 0, 
     active: true,
   });
  console.log("userData: ", userData);
  const [activity, setActivity] = useState<Activity[]>([]);
  const [lessons, setLessons] = useState<lessons[]>([]);
  const [memberSince, setMemberSince] = useState<Date | null>(new Date());
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchUserDetails();
    }
  }, [id]);

  // Fetch user details
  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await getRequest(`${baseURL}/users/${id}`);
        if (response) {
          const userDataFromApi = response.data.data;
          userDataFromApi.formattedCreatedAt = new Date(
            userDataFromApi.memberSince
          ).toLocaleDateString("en-GB");
          setUserData(userDataFromApi);
        } else {
          console.error("User data not found.");
        }
        setIsLoading(false);
      } else {
        console.error("ID is undefined.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user details:", error);
    }
  };

  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const splitFullName = (fullName: string) => {
    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names.slice(1).join(" ") : "";
    return { firstName, lastName };
  };

  // Mock data
  useEffect(() => {
    const mockData: Activity[] = [
      {
        id: 1,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 2,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 3,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 4,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 5,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: true,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 6,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
      {
        id: 7,
        name: "Tiempos verbales",
        progress: "30%",
        duration: "7 days",
        startdate: "13.03.2024",
        type: false,
        level: "A1",
        points: "2 pts",
      },
    ];
    setActivity(mockData);
  }, []);

  useEffect(() => {
    const lessonsData: lessons[] = [
      {
        id: 1,
        paquete: "100 verbos",
        level: "A1",
        date: "13.03.2024",
        operation: true,
      },
      {
        id: 2,
        paquete: "100 verbos",
        level: "A1",
        date: "13.03.2024",
        operation: false,
      },
    ];
    setLessons(lessonsData);
  }, []);

  const validateInputs = () => {
    const errors: ValidationErrors = {};

    if (!userData?.fullName) {
      errors.fullName = "Full name is required.";
    }
     if (!userData?.email) {
       errors.email = "Email is required.";
     } else {
       // Validate email format
       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       if (!emailRegex.test(userData.email)) {
         errors.email = "Invalid email address.";
       }
     }
    if (userData?.level === undefined || userData.level === null) {
      errors.level = "Level is required.";
    }
    if (userData?.points === undefined || userData.points === null) {
      errors.points = "Points are required.";
    }
    if (
      userData?.redeemPoints === undefined ||
      userData.redeemPoints === null
    ) {
      errors.redeemPoints = "Redeem Points are required.";
    }

    return errors;
  };


  // const CreateUser = async() =>{
  //   if (!userData) return;

  //   const userPayload = {
  //     fullName: `${userData.firstName} ${userData.lastName}`,
  //     email: userData.email,
  //     sincevalid: memberSince ? memberSince.toISOString() : "",
  //     totalPoints: userData.points,
  //     redeemedPoints: userData.redeemPoints,
  //     level: userData.level,
  //   };

  //   const response = await postRequest(`${baseURL}/user/create-user`,userPayload);

  //    if (response?.data.statuseMessage === 201) {
  //      navigate("/users"); // Redirect to the users list or another page
  //    }
  // }
  const handleSubmit = async () => {
    setValidationErrors({});
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const fullName = `${userData.firstName || ""} ${
      userData.lastName || ""
    }`.trim();


    const userPayload = {
      fullName,
      email: userData.email,
      level: userData.level,
      memberSince: userData.memberSince || new Date().toISOString(),
      points: userData.points,
      redeemPoints: userData.redeemPoints,
    };

    setIsLoading(true);
    const response = await postRequest(`${baseURL}/users/create-user`, userPayload);
     
    if (response) {
      const createdUserData = response.data.data;
      setUserData({
        ...userData,
        id: createdUserData.id, // Assign the new ID
        fullName: createdUserData.fullName,
        email: createdUserData.email,
        level: createdUserData.level,
        memberSince: createdUserData.sincevalid, // Assuming sincevalid is the correct field
        points: userData.points, // Retain original points
        redeemPoints: userData.redeemPoints, // Retain original redeemPoints
        active: createdUserData.active,
      });
       setIsLoading(false);
      Toast(response?.data?.statusMessage, "success");
      
    } else {
       Toast("Failed to create user", "error");
       setIsLoading(false);
    }
 
    setIsLoading(false);
  };

  const handleDeleteButtonClick = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    const response = await getRequest(`${baseURL}/users/change-status/${id}`);
    if (response?.status === 200) {
      Toast(response.data.statusMessage, "success");
      navigate("/users");
    }
    //  try {
    //    setIsLoading(true);
    //    if (id) {
    //     const resId = {
    //       userId: id,
    //     };

    //     const response = await postRequest(
    //      `${baseURL}/users/delete-users`,
    //      resId
    //    );

    //  if (response?.status === 200) {
    //    Toast(response.data.statusMessage, "success");
    //    navigate("/users");
    //   }
    //    } else {
    //      setIsLoading(true);
    //      console.error("ID is undefined.");
    //    }
    //  } catch (error) {
    //    console.error("Error deleting user:", error);
    //  } finally {
    //    setIsLoading(false);
    //    setShowDeletePopup(false);
    //  }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleBackClick = () => {
    navigate(`/users`);
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Memberships free
            </h4>
            <VerticalSpace height={20} />
            <div className="row p-3 profile-main">
              <div className="col-5 user-profile">
                <div className="row align-items-center mb-3">
                  <div className="col-auto">
                    <h3 style={{ fontWeight: "bold" }}>Profile</h3>
                  </div>
                  <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
                </div>
                <div
                  className="container"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 66%)",
                    borderRadius: "30px",
                    padding: "20px",
                  }}
                >
                  <div className="row px-2">
                    <button
                      type="button"
                      className=" btn btn-success"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#000000",
                        border: "none",
                        borderRadius: "46px",
                        padding: "8px 20px",
                        fontSize: "14px",
                        width: "auto",
                      }}
                    >
                      General
                    </button>
                  </div>
                  <VerticalSpace height={24} />
                  <div className="col">
                    <div className="row g-3">
                      {/* <form className="row g-3"> */}
                      {userData?.id ? (
                        <div className="col-md-6">
                          <Label
                            fontSize="14px"
                            padding="0px 0px 0px 8px"
                            fontWeight={400}
                          >
                            ID
                          </Label>
                          <VerticalSpace height={4} />
                          <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            value={userData?.id || "N/A"}
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            disabled
                          />
                        </div>
                      ) : null}
                      <div className="col-md-6">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Member since
                        </Label>
                        <VerticalSpace height={4} />
                        <DatePicker
                          selected={memberSince}
                          // onChange={(date: Date | null) => setMemberSince(date)}
                          onChange={(date: Date | null) => {
                            setMemberSince(date);
                            if (date) {
                              setUserData((prevData) => ({
                                ...prevData,
                                memberSince: date.toISOString(),
                              }));
                            }
                          }}
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          customInput={<StyledInput />}
                        />
                      </div>
                      <br />
                      <div className="col-12">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Name
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          // className="form-control"
                          className={`form-control ${
                            validationErrors.fullName ? "is-invalid" : ""
                          }`}
                          id="inputName"
                          placeholder="Name"
                          value={
                            splitFullName(userData?.fullName || "").firstName ||
                            ""
                          }
                          onChange={(e) => {
                            const newFirstName = e.target.value;
                            setUserData((prevData) => ({
                              ...prevData,
                              firstName: newFirstName,
                              fullName: `${newFirstName} ${
                                splitFullName(prevData.fullName || "")
                                  .lastName || ""
                              }`,
                            }));
                            if (validationErrors.fullName) {
                              setValidationErrors((prevErrors) => ({
                                ...prevErrors,
                                fullName: undefined,
                              }));
                            }
                          }}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        {validationErrors.fullName && (
                          <div
                            className="invalid-feedback"
                            style={{ marginLeft: "10px" }}
                          >
                            {validationErrors.fullName}
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="col-12">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Last Name
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="inputLastName"
                          placeholder="Last name"
                          value={
                            splitFullName(userData?.fullName || "").lastName ||
                            ""
                          }
                          onChange={(e) => {
                            const newLastName = e.target.value;
                            setUserData((prevData) => ({
                              ...prevData,
                              lastName: newLastName,
                              fullName: `${
                                splitFullName(prevData.fullName || "")
                                  .firstName || ""
                              } ${newLastName}`,
                            }));
                          }}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <br />
                      <div className="col-12">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Email
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="email"
                          className={`form-control ${
                            validationErrors.email ? "is-invalid" : ""
                          }`}
                          id="inputEmail"
                          placeholder="antonia@lexik.app"
                          value={userData?.email || ""}
                          onChange={(e) => {
                            const newEmail = e.target.value;
                            setUserData({ ...userData, email: newEmail });

                            // Clear error
                            if (validationErrors.email) {
                              setValidationErrors((prevErrors) => ({
                                ...prevErrors,
                                email: undefined,
                              }));
                            }
                          }}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        {validationErrors.email && (
                          <div
                            className="invalid-feedback"
                            style={{ marginLeft: "10px" }}
                          >
                            {validationErrors.email}
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="col-md-6">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Total Points
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="inputpoints"
                          value={userData?.points || "0"}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setUserData({
                              ...userData,
                              points: isNaN(value) ? 0 : value,
                            });
                          }}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        {validationErrors.points && (
                          <div
                            className="invalid-feedback"
                            style={{ marginLeft: "10px" }}
                          >
                            {validationErrors.points}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Points redeemed
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="inputredeemPoints"
                          value={userData?.redeemPoints || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setUserData({
                              ...userData,
                              redeemPoints: isNaN(value) ? 0 : value,
                            });
                          }}
                          style={{
                            color: "#7542E4",
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        {validationErrors.redeemPoints && (
                          <div
                            className="invalid-feedback"
                            style={{ marginLeft: "10px" }}
                          >
                            {validationErrors.redeemPoints}
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="d-flex justify-content-between align-items-end">
                        <div>
                          <Label
                            fontSize="14px"
                            padding="0px 0px 0px 8px"
                            fontWeight={400}
                          >
                            level
                          </Label>
                          <VerticalSpace height={4} />
                          <input
                            type="text"
                            className="form-control"
                            id="inputlevel"
                            value={userData?.level || 0}
                            onChange={(e) => {
                              const value = Number(e.target.value);
                              setUserData({
                                ...userData,
                                level: isNaN(value) ? 0 : value,
                              });
                            }}
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                          />
                          {validationErrors.level && (
                            <div
                              className="invalid-feedback"
                              style={{ marginLeft: "10px" }}
                            >
                              {validationErrors.level}
                            </div>
                          )}
                        </div>
                        <button
                          type="button"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "8px 20px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={handleDeleteButtonClick}
                        >
                          Delete user
                        </button>
                      </div>
                      {/* <form/> */}
                    </div>
                  </div>
                </div>
              </div>
              <HorizontalSpace width={3} />
              <div className="col-5 user-activity">
                <h3 style={{ fontWeight: "bold" }}>Activity</h3>
                <VerticalSpace height={2} />
                <ScrollableRowContainer>
                  <InnerRowContainer>
                    <Container width="100%">
                      <RowContainer
                        alignItem="center"
                        padding="8px 12px 8px 12px"
                        gap="8px"
                      >
                        <RowContainer flex="6.4">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Name
                          </Label>
                        </RowContainer>
                        <RowContainer flex="4">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Progress
                          </Label>
                        </RowContainer>
                        <RowContainer flex="4">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Duration
                          </Label>
                        </RowContainer>
                        <RowContainer flex="6">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Start day
                          </Label>
                        </RowContainer>
                        <RowContainer flex="4">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Type
                          </Label>
                        </RowContainer>
                        <RowContainer flex="2">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            level
                          </Label>
                        </RowContainer>
                        <RowContainer flex="2.5">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Points
                          </Label>
                        </RowContainer>
                      </RowContainer>
                      <ColumnContainer>
                        {activity.map((activity) => (
                          <MembershipActivity
                            key={activity.id}
                            id={activity.id}
                            name={activity.name}
                            progress={activity.progress}
                            duration={activity.duration}
                            startdate={activity.startdate}
                            type={activity.type}
                            level={activity.level}
                            points={activity.points}
                          />
                        ))}
                      </ColumnContainer>
                    </Container>
                  </InnerRowContainer>
                </ScrollableRowContainer>
                <VerticalSpace height={34} />
                <h3 style={{ fontWeight: "bold" }}>Lessons redeemed</h3>
                <VerticalSpace height={2} />
                <ScrollableRowContainer>
                  <InnerRowContainer>
                    <Container width="100%">
                      <RowContainer
                        alignItem="center"
                        padding="8px 12px 8px 12px"
                        gap="8px"
                      >
                        <RowContainer flex="8">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Paquete
                          </Label>
                        </RowContainer>
                        <RowContainer flex="7">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Level
                          </Label>
                        </RowContainer>
                        <RowContainer flex="8">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Date
                          </Label>
                        </RowContainer>
                        <RowContainer flex="5">
                          <Label
                            fontWeight={400}
                            fontSize="14px"
                            color="rgba(164, 163, 167, 1)"
                          >
                            Operation
                          </Label>
                        </RowContainer>
                      </RowContainer>
                      <ColumnContainer>
                        {lessons.map((lessons) => (
                          <MembershipLessons
                            key={lessons.id}
                            id={lessons.id}
                            paquete={lessons.paquete}
                            level={lessons.level}
                            date={lessons.date}
                            operation={lessons.operation}
                          />
                        ))}
                      </ColumnContainer>
                    </Container>
                  </InnerRowContainer>
                </ScrollableRowContainer>
              </div>
            </div>
            <VerticalSpace height={100} />
          </div>
          <VerticalSpace height={220} />
          {!userData?.id ? (
            <div
              className="container row d-flex justify-content-center publish-button"
              style={{
                position: "fixed",
                zIndex: 1,
                height: "12%",
                maxWidth: "100%",
                bottom: "0px",
                background:
                  "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  position: "fixed",
                  zIndex: 1,
                  bottom: "34px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-success d-flex justify-content-center"
                  style={{
                    backgroundColor: "#FF9E00",
                    color: "#100F15",
                    height: "fit-content",
                    borderRadius: "10px",
                    padding: "10px 20%",
                    width: "400px",
                    fontSize: "14px",
                  }}
                  onClick={handleSubmit}
                >
                  Publish
                </button>
              </div>
            </div>
          ) : null}
        </>
      )}
      {showDeletePopup && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeletePopup}
          message="Are you sure want to delete this user?"
          deleteButtonLabel="Delete"
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </div>
  );
};

export default CreateUser;
