import React, { useState, useRef, useEffect } from "react";
import VerticalSpace from "../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../assets/deleteIcon.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  getRequest,
  multiPostRequest,
  postRequest,
} from "../../api/apiClient";
import Toast from "../Common/Toast";
import "../../stylesheets/theme.css";
import Loader from "../../utils/Loading";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import ColumnContainer from "../Common/ColumnContainer";
import { formatKeyName } from "../../utils/configFormatKeyName";
import AddImage from "../../assets/Images/AddImageIcon.svg";
import CloseImage from "../../assets/Images/CloseIcon.svg";
import styled from "styled-components";

interface CreateConfigProps {
  homeScreenVideo: File | null;
  homeScreenChallengeImage: File | null;
  homeScreenNotificationImage: File | null;
  homeScreenLessonImage: File | null;
  homeScreenMoreLessonImage: File | null;
  homeScreenOneNotificationImage: File | null;
  homeScreenOneChallengeImage: File | null;
  homeScreenMoreNotificationImage: File | null;
  homeScreenMoreChallengeImage: File | null;
  favouriteFlashcard: File[] | null;
}

const NewLabel = styled(Label)`
  @media (max-width: 769px) {
    font-size: 19px;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

const CreateConfig = () => {
  const { id } = useParams();
  const [config, setConfig] = useState<CreateConfigProps>({
    homeScreenVideo: null,
    homeScreenChallengeImage: null,
    homeScreenNotificationImage: null,
    homeScreenLessonImage: null,
    homeScreenMoreLessonImage: null,
    homeScreenOneNotificationImage: null,
    homeScreenOneChallengeImage: null,
    homeScreenMoreNotificationImage: null,
    homeScreenMoreChallengeImage: null,
    favouriteFlashcard: [],
  });
  const fileInputRefs = useRef<
    Record<keyof CreateConfigProps, HTMLInputElement | null>
  >({
    homeScreenVideo: null,
    homeScreenChallengeImage: null,
    homeScreenNotificationImage: null,
    homeScreenLessonImage: null,
    homeScreenMoreLessonImage: null,
    homeScreenOneNotificationImage: null,
    homeScreenOneChallengeImage: null,
    homeScreenMoreNotificationImage: null,
    homeScreenMoreChallengeImage: null,
    favouriteFlashcard: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    setIsLoading(true);
    const response = await getRequest(`${baseURL}/config/get-all-config/${id}`);
    if (response?.data) {
      setConfig(response?.data.data.data);
    }
    setIsLoading(false);
  };

  const handleChooseFileClick = (key: keyof CreateConfigProps) => {
    if (fileInputRefs.current[key]) {
      fileInputRefs.current[key]!.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof CreateConfigProps
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setConfig((prevConfig) => {
        if (key === "favouriteFlashcard") {
          const updatedFiles = [
            ...(prevConfig.favouriteFlashcard || []),
            ...files,
          ];
          return { ...prevConfig, favouriteFlashcard: updatedFiles };
        }
        return { ...prevConfig, [key]: files[0] };
      });
    }
  };

  const handleDeleteImage = (key: keyof CreateConfigProps, index?: number) => {
    setConfig((prevConfig) => {
      if (key === "favouriteFlashcard" && index !== undefined) {
        const updateFlashcard = [...prevConfig.favouriteFlashcard!];
        updateFlashcard.splice(index, 1);
        return {
          ...prevConfig,
          favouriteFlashcard:
            updateFlashcard.length > 0 ? updateFlashcard : null,
        };
      } else {
        return {
          ...prevConfig,
          [key]: null,
        };
      }
    });
  };

  const handlePublishClick = async () => {
    const formData = new FormData();
    let hasMissingFiles = false;

    // Object.entries(config).forEach(([key, file]) => {
    //   const formattedKey = formatKeyName(key);

    //   if (key === "favouriteFlashcard" && Array.isArray(file)) {
    //     if (file.length === 0) {
    //       Toast(`Field ${formattedKey} is required`, "error");
    //       hasMissingFiles = true;
    //     } else {
    //       file.forEach((file, index) => {
    //         formData.append(`favouriteFlashcard`, file as Blob);
    //       });
    //     }
    //   } else if (!file) {
    //     Toast(`Field ${formattedKey} is required`, "error");
    //     hasMissingFiles = true;
    //   } else {
    //     formData.append(key, file as Blob);
    //   }
    // });

    // Object.entries(config).forEach(([key, file]) => {
    //   const formattedKey = formatKeyName(key);
    //   if (!file) {
    //     Toast(`Field ${formattedKey} is required`, "error");
    //     hasMissingFiles = true;
    //   } else {
    //     formData.append(key, file as Blob);
    //   }
    // });
    
    Object.entries(config).forEach(([key, value]) => {
      const formattedKey = formatKeyName(key);

      if (key === "favouriteFlashcard" && Array.isArray(value)) {
        // Handle the favouriteFlashcard array
        value.forEach((item, index) => {
          if (item instanceof File) {
            // If the item is a File object (newly added image), append it as Blob
            formData.append(`favouriteFlashcard`, item as Blob);
          } else if (typeof item === "string") {
            // If the item is a URL, append it as a string
            formData.append(`favouriteFlashcard`, item);
          }
        });
      } else if (!value) {
        Toast(`Field ${formattedKey} is required`, "error");
        hasMissingFiles = true;
      } else {
        formData.append(key, value as Blob);
      }
    });

    if (hasMissingFiles) {
      return;
    }

    setIsLoading(true);
    const response = await multiPostRequest(
      `${baseURL}/config/create-config`,
      formData
    );

    if (response?.data) {
      setConfig(response?.data.data.config.data);
    }

    setIsLoading(false);
  };

  const handleBackClick = () => {
    navigate("/configs");
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <VerticalSpace height={10} />
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Configs
          </h4>
          <VerticalSpace height={30} />
          <div className="container" style={{ maxWidth: "1117px" }}>
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-6">
                  {/* Home Screen Lesson Image*/}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen Lesson Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenLessonImage = el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenLessonImage")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick("homeScreenLessonImage")
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenLessonImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenLessonImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenLessonImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenLessonImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenLessonImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(e, "homeScreenLessonImage")
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenLessonImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage("homeScreenLessonImage");
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
                <div className="custom-config-div col-6">
                  {/* Home Screen More Lesson Image*/}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen More Lesson Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenMoreLessonImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenMoreLessonImage")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick("homeScreenMoreLessonImage")
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenMoreLessonImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenMoreLessonImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreLessonImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreLessonImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreLessonImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreLessonImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenMoreLessonImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage("homeScreenMoreLessonImage");
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer>
              <VerticalSpace height={20} />

              <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-6">
                  {/* Home Screen Challenge Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen Challenge Image
                    </NewLabel>

                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenChallengeImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenChallengeImage")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick("homeScreenChallengeImage")
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenChallengeImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenChallengeImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage("homeScreenChallengeImage");
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
                <div className="custom-config-div col-6">
                  {/* Home Screen One Challenge Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen One Challenge Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenOneChallengeImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenOneChallengeImage")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick(
                                "homeScreenOneChallengeImage"
                              )
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenOneChallengeImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenOneChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenOneChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenOneChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenOneChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenOneChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenOneChallengeImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage(
                                  "homeScreenOneChallengeImage"
                                );
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer>
              <VerticalSpace height={20} />

              <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-6">
                  {/* Home Screen More Challenge Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen More Challenge Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenMoreChallengeImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                "homeScreenMoreChallengeImage"
                              )
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick(
                                "homeScreenMoreChallengeImage"
                              )
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenMoreChallengeImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenMoreChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenMoreChallengeImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage(
                                  "homeScreenMoreChallengeImage"
                                );
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
                <div className="custom-config-div col-6">
                  {/* Home Screen Notification Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen Notification Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenNotificationImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenNotificationImage")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick(
                                "homeScreenNotificationImage"
                              )
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenNotificationImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenNotificationImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage(
                                  "homeScreenNotificationImage"
                                );
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer>
              <VerticalSpace height={20} />

              <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-6">
                  {/* Home Screen One Notification Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen One Notification Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenOneNotificationImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                "homeScreenOneNotificationImage"
                              )
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick(
                                "homeScreenOneNotificationImage"
                              )
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenOneNotificationImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenOneNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenOneNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenOneNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenOneNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenOneNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenOneNotificationImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage(
                                  "homeScreenOneNotificationImage"
                                );
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
                <div className="custom-config-div col-6">
                  {/* Home Screen More Notification Image */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen More Notification Image
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenMoreNotificationImage =
                                el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                "homeScreenMoreNotificationImage"
                              )
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick(
                                "homeScreenMoreNotificationImage"
                              )
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenMoreNotificationImage && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenMoreNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <img
                                src={URL.createObjectURL(
                                  config?.homeScreenMoreNotificationImage
                                )}
                                alt="flash-image"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage(
                                  "homeScreenMoreNotificationImage"
                                );
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer>
              <VerticalSpace height={20} />

              <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-6">
                  {/* Home Screen Video */}
                  <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Home Screen Video
                    </NewLabel>
                    <RowContainer>
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            ref={(el) =>
                              (fileInputRefs.current.homeScreenVideo = el)
                            }
                            hidden
                            accept="video/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "homeScreenVideo")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick("homeScreenVideo")
                            }
                          />
                        </div>
                      </div>
                      {config?.homeScreenVideo && (
                        <div>
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              position: "relative",
                              width: "120px",
                              height: "120px",
                            }}
                          >
                            {typeof config?.homeScreenVideo === "string" ? (
                              <>
                                <video
                                  controls
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick("homeScreenVideo")
                                  }
                                >
                                  <source
                                    src={config?.homeScreenVideo}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenVideo = el)
                                  }
                                  hidden
                                  accept="video/*"
                                  onChange={(e) =>
                                    handleFileChange(e, "homeScreenVideo")
                                  }
                                />
                              </>
                            ) : (
                              <video
                                controls
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  cursor: "pointer",
                                }}
                              >
                                <source
                                  src={URL.createObjectURL(
                                    config?.homeScreenVideo
                                  )}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteImage("homeScreenVideo");
                              }}
                            >
                              <img
                                src={CloseImage}
                                width="24px"
                                alt="Delete"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer>
              <VerticalSpace height={20} />

              {/* <NewRowContainer justifyContent="space-between">
                <div className="custom-config-div col-12"> */}
                  {/* Favourite Flashcard Images */}
                  {/* <ColumnContainer>
                    <NewLabel fontWeight={500} fontSize="22px" color="#100F15">
                      Favourite Flashcard Images
                    </NewLabel>
                    <RowContainer flexWrap="wrap">
                      <div>
                        <div
                          className="drop_box"
                          style={{
                            margin: "0px",
                            width: "120px",
                            height: "120px",
                          }}
                        >
                          <input
                            type="file"
                            multiple
                            ref={(el) =>
                              (fileInputRefs.current.favouriteFlashcard = el)
                            }
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "favouriteFlashcard")
                            }
                          />
                          <img
                            src={AddImage}
                            width="40px"
                            height="40px"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleChooseFileClick("favouriteFlashcard")
                            }
                          />
                        </div>
                      </div>
                      {config?.favouriteFlashcard &&
                        config?.favouriteFlashcard.length > 0 &&
                        config?.favouriteFlashcard.map((image, index) => (
                          <>
                            <div key={index}>
                              <div
                                className="drop_box"
                                style={{
                                  margin: "0px",
                                  position: "relative",
                                  width: "120px",
                                  height: "120px",
                                }}
                              >
                                {typeof image === "string" ? (
                                  <>
                                    <img
                                      src={image}
                                      alt="flash-image"
                                      style={{
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleChooseFileClick(
                                          "favouriteFlashcard"
                                        )
                                      }
                                    />
                                    <input
                                      type="file"
                                      multiple
                                      ref={(el) =>
                                        (fileInputRefs.current.favouriteFlashcard =
                                          el)
                                      }
                                      hidden
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleFileChange(
                                          e,
                                          "favouriteFlashcard"
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt="flash-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleDeleteImage(
                                      "favouriteFlashcard",
                                      index
                                    );
                                  }}
                                >
                                  <img
                                    src={CloseImage}
                                    width="24px"
                                    style={{
                                      WebkitUserSelect: "none",
                                      userSelect: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </RowContainer>
                  </ColumnContainer>
                </div>
              </NewRowContainer> */}
              <VerticalSpace height={20} />
            </div>
            <VerticalSpace height={120} />
          </div>
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 2,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handlePublishClick}
              >
                Publish
              </button>
            </div>
          </div>
          b
        </div>
      )}
    </div>
  );
};
export default CreateConfig;
