import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../assets/deleteIcon.svg";
import VerticalSpace from "../../VerticalSpace";
import Label from "../../Common/Label";
import editIcon from "../../../assets/editIcon.svg";
import plusIcon from "../../../assets/plus_Icon.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../stylesheets/theme.css";
import {
  baseURL,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../api/apiClient";
import Toast from "../../Common/Toast";
import DeleteConfirmation from "../../Common/DeleteConfirmation";
import Loader from "../../../utils/Loading";
import gsap from 'gsap';
import '../../../stylesheets/common.css'
import DeleteLoader from "../../../utils/deleteLoader";

interface SubTheme {
  id: number;
  name: string;
  cards: number;
}

const SubThemes = () => {
  const { id } = useParams();
  const [addSubThemes, setNewThemes] = useState(false);
  const [themeName, setThemeName] = useState("");
  const [subthemes, setSubThemes] = useState<SubTheme[]>([]);
  const [editSubThemes, setEditSubThemes] = useState<SubTheme | null>(null);
  const [subThemeError, setSubThemeError] = useState("");
  const [parentThemeName, setParentThemeName] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [subThemeToDelete, setSubThemeToDelete] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { themeId: themeId } = location.state || {};

  useEffect(() => {
    const fetchSubThemes = async () => {
      try {
        setIsLoading(true);
        const response = await getRequest(
          `${baseURL}/lesson/get-subthemes/${id}`
        );
        
        const SubThemeData = response?.data.data.subTheme;
        const handlecardsCount = SubThemeData.map(async (subTheme: any) => {
          const reqData = {
            modelName: "flashcards",
            field: "subthemeId",
            id: subTheme.id.toString(),
          };
          const cardCountResponse = await postRequest(
            `${baseURL}/lesson/counts`,
            reqData
          );
          if (cardCountResponse) {
            return { ...subTheme, cards: cardCountResponse.data.data.count };
          } else {
            return { ...subTheme, cards: 0 };
          }
        });
        const resolvedCardsCount = await Promise.all(handlecardsCount);
        setSubThemes(resolvedCardsCount);
        setParentThemeName(response?.data.data.themeName);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      }
    };

    fetchSubThemes();
  }, [id]);

  const handleNewSubThemes = () => {
    setThemeName("");
    setSubThemeError("");
    setNewThemes(true);
    setEditSubThemes(null);
  };

  const handleSaveSubTheme = async () => {
    if (!themeName) {
      setSubThemeError("Please enter sub-theme name");
    }
    const activeThemeId = themeId || id;

    if (editSubThemes) {
      await handleUpdateSubTheme(editSubThemes.id, themeName);
    } else {
      await handleCreateSubTheme(activeThemeId, themeName);
    }
  };

  const handleCreateSubTheme = async (
    themeId: number,
    subThemeName: string
  ) => {
    try {
      const reqData = {
        themeId,
        name: subThemeName,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/create-subtheme`,
        reqData
      );
     
      const newTheme: SubTheme = {
        id: response?.data.data.id, // Generate a unique ID (you might want to use a more robust approach)
        name: response?.data.data.name,
        cards: 0, // Initialize cards count
      };
      setSubThemes([...subthemes, newTheme]);
      setThemeName("");
      setNewThemes(false);
      setIsLoading(false);
    } catch (error) {
      setSubThemeError("Error creating Sub-Theme");
    }
  };

  const handleUpdateSubTheme = async (
    subthemeId: number,
    subThemeName: string
  ) => {
    try {
      const reqData = {
        name: subThemeName,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-subthemes/${subthemeId}`,
        reqData
      );
      setIsLoading(false);

      const updatedSubThemes = subthemes.map((subTheme) =>
        subTheme.id === subthemeId
          ? { ...subTheme, name: subThemeName }
          : subTheme
      );
      setSubThemes(updatedSubThemes);
      setThemeName("");
      setNewThemes(false);
      setEditSubThemes(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      setSubThemeError("Error updateing Sub-Theme");
    }
  };

  const handleEditSubTheme = (subTheme: SubTheme) => {
    setThemeName(subTheme.name);
    setEditSubThemes(subTheme);
    setNewThemes(true);
  };

  const handleAddFlashcard = (id: number, name: string) => {
    navigate(`/lessons/theme/subthemes/${id}`, {
      state: { subThemeId: id, subtheme: name },
    });
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-themes/${id}`
      );

      const lessonId = response?.data.data[0].lessonId;
      navigate(`/lessons/edit-lessons/${lessonId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  

  // const handleDeleteSubTheme = async (id: number) => {
  //   try {
  //     const response = await deleteRequest(
  //       `${baseURL}/lesson/delete-subthemes/${id}`
  //     );
  //     const updatedSubThemes = subthemes.filter((theme) => theme.id !== id);
  //     setSubThemes(updatedSubThemes);
  //     Toast(response?.data.statusMessage, "success");
  //   } catch (error) {
  //     console.error("Error deleting flashcard:", error);
  //     setSubThemeError("Error deleting flashcard");
  //   }
  // };

  const handleConfirmDeleteSubTheme = async (id: number) => {
      try {
        setLoading(true);
        const response = await deleteRequest(
          `${baseURL}/lesson/delete-subthemes/${id}`
        );
        setLoading(false);
        const updatedSubThemes = subthemes.filter((theme) => theme.id !== id);
        setSubThemes(updatedSubThemes);
        setShowDeleteDialog(false);
        setSubThemeToDelete(null);
        Toast(response?.data.statusMessage, "success");
      } catch (error) {
        console.error("Error deleting flashcard:", error);
        setSubThemeError("Error deleting flashcard");
        setLoading(false);
        setShowDeleteDialog(false);
      }
  };

  const handleDeleteSubTheme = (id: number, name: string) => {
    setSubThemeToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
    setOnConfirmDelete(() => ()=> handleConfirmDeleteSubTheme(id));
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setSubThemeToDelete(null);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });

  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a sub-theme
            </h4>
            <VerticalSpace height={30} />
            <div
              className="container d-flex justify-content-center theme-container"
              style={{ maxWidth: "666px" }}
            >
              <div
                className="container p-4"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                }}
              >
                <div className="col-12 theme-content">
                  <div className="d-flex justify-content-between">
                    <Label fontSize="20px" fontWeight={700}>
                      {parentThemeName}
                    </Label>
                  </div>

                  <div>
                    {subthemes.length === 0 ? (
                      <div className="p-5 d-flex justify-content-center align-items-center ">
                        <label
                          htmlFor="theme"
                          className="form-label"
                          style={{ fontWeight: "bold", color: "#7542E4" }}
                        >
                          There are no sub-themes yet in this theme.
                        </label>
                      </div>
                    ) : (
                      subthemes.map((subtheme) => (
                        // <div key={theme.id}>
                        <div
                          className="row d-flex justify-content-center align-items-center "
                          style={{ gap: "10px" }}
                          key={subtheme.id}
                        >
                          <div
                            className="drop_box theme-box col-9"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Label
                                fontSize="14px"
                                color="#000"
                                fontWeight={400}
                              >
                                {subtheme.name}
                              </Label>
                            </div>

                            <div style={{ cursor: "pointer" }}>
                              <Label
                                fontSize="16px"
                                color="#000"
                                fontWeight={400}
                                cursor="pointer"
                                padding="8px 13px 8px 13px"
                                bgColor="#DAD7FE"
                                justifyContent="center"
                                display="flex"
                                borderRadius="40px"
                              >
                                {subtheme.cards} cards
                              </Label>
                            </div>
                          </div>
                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleEditSubTheme(subtheme)}
                          >
                            <img
                              src={editIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                          <div
                            className="p-0 user-scale"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              backgroundColor: "rgb(203 203 203 / 99%)",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              handleAddFlashcard(subtheme.id, subtheme.name)
                            }
                          >
                            <img
                              src={plusIcon}
                              // className="user-scale"
                              width="31px"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>

                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() =>
                              handleDeleteSubTheme(subtheme.id, subtheme.name)
                            }
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  <VerticalSpace height={12} />
                  <button
                    type="button"
                    className="user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      alignItems: "center",
                      borderRadius: "10px",
                      padding: "14px 40px",
                      width: "auto",
                    }}
                    onClick={handleNewSubThemes}
                  >
                    Add sub-theme
                  </button>
                </div>
              </div>
            </div>
          </div>

          {addSubThemes && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>Add a sub-theme</h5>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputthemes"
                      placeholder="New sub-theme name"
                      value={themeName}
                      onChange={(e) => setThemeName(e.target.value)}
                    />
                    {subThemeError && (
                      <div className="error-message">{subThemeError}</div>
                    )}
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setNewThemes(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        borderRadius: "10px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        borderRadius: "10px",
                        color: "#100F15",
                      }}
                      onClick={handleSaveSubTheme}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}

      {loading && (
        <DeleteLoader />
      )}
      <VerticalSpace height={120} />
    </div>
  );
};

export default SubThemes;
