import styled from 'styled-components';


interface RowContainerProps {
  height?: string;
  width?: string;
  maxWidth?:string;
  gap?: string;
  margin?: string;
  flexDirection?: string;
  alignItem?: string;
  justifyContent?: string;
  flex?: string;
  padding?: string;
  position?: string;
  bgColor?: string;
  flexWrap?: string;
  left?: string;
  margintop?: string;
  marginleft?: string;
  top?: string;
  borderBottom?: string;
  borderRadius?: string;
  textalign?: string;
  cursor?: string;
  borderTop?: string;
  border?: string;
  display?: string;
  isActive?: boolean;
  background?: string;
  bottom?: string;
}

const RowContainer = styled.div<RowContainerProps>`
  height: ${(props) => props.height ?? ""};
  width: ${(props) => props.width ?? "100%"};
  top: ${(props) => props.top ?? ""};
  max-width: ${(props) => props.maxWidth ?? ""};
  left: ${(props) => props.left ?? " "};
  flex-wrap: ${(props) => props.flexWrap ?? ""};
  display: ${(props) => props.display ?? "flex"};
  flex: ${(props) => props.flex ?? ""};
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  align-items: ${(props) =>
    props.alignItem ?? "start"}; // Change 'start' to 'center'
  justify-content: ${(props) =>
    props.justifyContent ?? "flex-start"}; // Change 'flex-center' to 'center'
  gap: ${(props) => props.gap ?? "10px"};
  margin: ${(props) => props.margin || ""};
  margin-left: ${(props) => props.marginleft || ""}; // Change 'margin
  margin-top: ${(props) => props.margintop || ""};
  padding: ${(props) => props.padding ?? ""};
  position: ${(props) => props.position ?? ""};
  background-color: ${(props) => props.bgColor || ""};
  background: ${(props)=>props.background || ""};
  border-bottom: ${(props) => props.borderBottom || ""};
  border-top: ${(props) => props.borderTop || ""};
  border-radius: ${(props) => props.borderRadius || ""};
  border: ${(props) => props.border || ""};
  bottom: ${(props) => props.bottom || ""};
  text-align: ${(props) => props.textalign ?? ""};
  cursor: ${(props) => props.cursor ?? ""};
`;

export default RowContainer;
