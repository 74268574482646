import styled from "styled-components";

interface LabelProps {
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  styleName?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textAlign?: string;
  padding?: string;
  marginLeft?: string;
  margin?: string;
  marginTop?: string;
  width?: string;
  height?: string;
  display?: string;
  alignItems?: string;
  opacity?: string;
  cursor?: string;
  borderRadius?: string;
  bgColor?: string;
  fontStyle?: string;
  justifyContent?: string;
}

const Label = styled.span<LabelProps>`
  font-size: ${(props) => props.fontSize ?? ""};
  // font-weight: ${(props) => props.fontWeight ?? "normal"};
  color: ${(props) => props.color ?? "#000"};
  // stylename: ${(props) => props.styleName ?? "Large/Semibold"};
  // font-family: ${(props) => props.fontFamily ?? " SF Pro Display"};
  font-size: ${(props) => props.fontSize ?? "18px"};
  font-weight: ${(props) => props.fontWeight ?? "600"};
  line-height: ${(props) => props.lineHeight ?? ""};
  font-style: ${(props) => props.fontStyle ?? ""};
  letter-spacing: ${(props) => props.letterSpacing ?? ""};
  text-align: ${(props) => props.textAlign ?? ""};
  justify-content: ${(props) => props.justifyContent ?? ""};
  padding: ${(props) => props.padding ?? ""};
  margin-left: ${(props) => props.marginLeft ?? ""};
  margin: ${(props) => props.margin ?? ""};
  margin-Top: ${(props)=>props.marginTop ?? ""};
  height: ${(props) => props.height ?? ""};
  width: ${(props) => props.width ?? ""};
  display: ${(props) => props.display ?? ""};
  align-items: ${(props) => props.alignItems ?? ""};
  opacity: ${(props) => props.opacity ?? ""};
  cursor: ${(props) => props.cursor ?? ""};
  border-radius: ${(props) => props.borderRadius ?? ""};
  background-color: ${(props) => props.bgColor ?? ""};
`;

export default Label;
