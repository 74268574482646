import React from "react";
import { Form } from "react-bootstrap";
import '../../assets/css/checkbox.css';

interface Props {
  checked: boolean;
  onChange: () => void;
}

const OptionCheckbox: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <Form.Check
      type="checkbox"
      checked={checked}
      onChange={onChange}
      style={{
        width: "30px",
        height: "30px",
      }}
      className="checkboxss"
    />
  );
};

export default OptionCheckbox;