// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Shimmer loader wrapper */
.shimmer-loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

/* Shimmer loader */
.shimmer-loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;

}


.shimmer {
    position: absolute;
    top: 0;
    left: -50%;
    width: 150%;
    height: 100%;
    background: linear-gradient(to right, rgba(240, 240, 240, 0.6) 0%, rgba(240, 240, 240, 0.8) 50%, rgba(240, 240, 240, 0.6) 100%);
    animation: shimmer-animation 2s infinite linear;
}

.spinner {
    position: absolute;
    /* top: 50%;
    left: 50%; */
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #000000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Shimmer animation keyframes */
@keyframes shimmer-animation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

/* Spinner animation keyframes */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/utils/Loading.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA,mBAAmB;AACnB;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;;AAEjB;;;AAGA;IACI,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,WAAW;IACX,YAAY;IACZ,+HAA+H;IAC/H,+CAA+C;AACnD;;AAEA;IACI,kBAAkB;IAClB;gBACY;IACZ,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA,gCAAgC;AAChC;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,2BAA2B;IAC/B;AACJ;;AAEA,gCAAgC;AAChC;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Shimmer loader wrapper */\n.shimmer-loader-wrapper {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    overflow: hidden;\n}\n\n/* Shimmer loader */\n.shimmer-loader {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 200px;\n    height: 200px;\n\n}\n\n\n.shimmer {\n    position: absolute;\n    top: 0;\n    left: -50%;\n    width: 150%;\n    height: 100%;\n    background: linear-gradient(to right, rgba(240, 240, 240, 0.6) 0%, rgba(240, 240, 240, 0.8) 50%, rgba(240, 240, 240, 0.6) 100%);\n    animation: shimmer-animation 2s infinite linear;\n}\n\n.spinner {\n    position: absolute;\n    /* top: 50%;\n    left: 50%; */\n    transform: translate(-50%, -50%);\n    width: 60px;\n    height: 60px;\n    border: 5px solid #f3f3f3;\n    border-top: 5px solid #000000;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n}\n\n/* Shimmer animation keyframes */\n@keyframes shimmer-animation {\n    0% {\n        transform: translateX(-100%);\n    }\n\n    100% {\n        transform: translateX(100%);\n    }\n}\n\n/* Spinner animation keyframes */\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
