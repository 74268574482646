import styled from 'styled-components';


interface ColumnContainerProps {
  gap?: string;
  margin?: string;
  flexDirection?: string;
  alignItem?: string;
  justifyContent?: string;
  flex?: string;
  backgroundColor?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  padding?: string;
  color?: string;
  marginTop?:string;
  border?: string;
  position?: string;
  bottom?: string;
  left?: string;
  transform?: string;
  borderRight?: string;
  overflow?: string;
}

const ColumnContainer = styled.div<ColumnContainerProps>`
  display: flex;
  flex-direction:  ${(props) => props.flexDirection ?? 'column'};
  flex: ${(props) => props.flex ?? '0'};
  align-items: ${(props) => props.alignItem ?? 'start'};
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  gap: ${(props) => props.gap || '10px'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0px'};
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : ''};
  border-radius: ${(props) => props.borderRadius ? props.borderRadius : ''};
  width: ${(props) => props.width ? props.width : ''};
  height: ${(props) => props.height ? props.height : ''};
  color: ${(props) => props.color ? props.color : ''};
  margin-top: ${(props)=>props.marginTop ? props.marginTop : ''};
  border: ${(props) => props.border ? props.border : ''};
  position: ${(props) => props.position ? props.position : ''};
  bottom: ${(props) => props.bottom ? props.bottom : ''};
  left: ${(props) => props.left ? props.left : ''};
  transform: ${(props) => props.transform ? props.transform : ''};
  border-right: ${(props) => props.borderRight ? props.borderRight : ''};
  overflow: ${(props) => props.overflow ? props.overflow : ''}; 
    `;

export default ColumnContainer;
