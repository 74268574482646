import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import deleteIcon from "../../../assets/deleteIcon.svg";
import VerticalSpace from "../../VerticalSpace";
import Label from "../../Common/Label";
import editIcon from "../../../assets/editIcon.svg";
import plusIcon from "../../../assets/plus_Icon.png";
import "../../../stylesheets/theme.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { baseURL, deleteRequest, getRequest, postRequest } from "../../../api/apiClient";
import Toast from "../../Common/Toast";
import DeleteConfirmation from "../../Common/DeleteConfirmation";
import Loader from "../../../utils/Loading";

interface FlashCard {
  id: number;
  name: string;
}

const FlashCard = () => {
  const { id } = useParams();
  const [addFlashCard, setNewFlashCard] = useState(false);
  const [themeName, setThemeName] = useState("");
  const [flashcard, setFlashCard] = useState<FlashCard[]>([]);
  const [editFlashCard, setEditFlashCard] = useState<FlashCard | null>(null);
  const [flashCardError, setFlashCardError] = useState("");
  const [parentSubThemeName, setParentSubThemeName] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [flashCardToDelete, setFlashCardToDelete] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { subThemeId: subThemeId, subtheme: parentThemeName } =
    location.state || {};

    const fetchFlashcard = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const response = await getRequest(
          `${baseURL}/lesson/get-flashcard/${id}`
        );
        setFlashCard(response?.data.data.flashcard);
        setParentSubThemeName(response?.data.data.subthemeName);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      }
    };

  useEffect(() => {
    
    fetchFlashcard();
  }, []);

  const handleNewFlaseCard = () => {
    setThemeName("");
    setFlashCardError("");
    setNewFlashCard(true);
    setEditFlashCard(null);
  };

  const handleSaveFlashCard = async () => {
    if (!themeName) {
      setFlashCardError("Please enter flashcard name");
      return;
    }

    const activeSubThemeId = subThemeId || id;

    if (editFlashCard) {
      await handleUpdateFlashcard(editFlashCard.id, themeName);
    } else {
      await handleCreateFlashcard(activeSubThemeId, themeName);
    }
  };

  const handleCreateFlashcard = async (
    subthemeId: number,
    flashcardName: string
  ) => {
    try {
      const reqData = {
        subthemeId,
        name: flashcardName,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/create-flashcard`,
        reqData
      );
      setIsLoading(false);

      const newTheme: FlashCard = {
        id: response?.data.data.id, // Generate a unique ID (you might want to use a more robust approach)
        name: response?.data.data.name,
      };
      setFlashCard([...flashcard, newTheme]);
      setThemeName("");
      setNewFlashCard(false);
    } catch (error) {
      setFlashCardError("Error creating Flahcard");
    }
  };

  const handleUpdateFlashcard = async (
    flashcardId: number,
    flashcardName: string
  ) => {
    try {
      const reqData = {
        name: flashcardName,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-flashcard/${flashcardId}`,
        reqData
      );

      setIsLoading(false);

      const updatedFlashCard = flashcard.map((flashCard) =>
        flashCard.id === flashcardId
          ? { ...flashCard, name: flashcardName }
          : flashCard
      );
      setFlashCard(updatedFlashCard);
      setThemeName("");
      setNewFlashCard(false);
      setEditFlashCard(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      setFlashCardError("Error updateing Flahcard");
    }
  };

  const handleDeleteFlaseCard = async (id: number) => {
    try {
      const response = await deleteRequest(
        `${baseURL}/lesson/delete-flashcard/${id}`
      );
      // Filter out the theme with the given ID
      const updatedflashcard = flashcard.filter((theme) => theme.id !== id);
      // Update the themes state
      setFlashCard(updatedflashcard);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error deleting flashcard:", error);
      setFlashCardError("Error deleting flashcard");
    }
  };

  const handleEditFlaseCard = (flashcard: FlashCard) => {
    setThemeName(flashcard.name);
    setEditFlashCard(flashcard);
    setNewFlashCard(true);
  };

  const handleConfirmDeleteFlashCard = async (id: number) => {
    try {
      const response = await deleteRequest(
        `${baseURL}/lesson/delete-flashcard/${id}`
      );
      const updatedflashcard = flashcard.filter((theme) => theme.id !== id);
      setFlashCard(updatedflashcard);
      setShowDeleteDialog(false);
      setFlashCardToDelete(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error deleting flashcard:", error);
      setFlashCardError("Error deleting flashcard");
      setShowDeleteDialog(false);
    }
  };

  const handleDeleteFlashCard = (id: number, name: string) => {
    setFlashCardToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
    setOnConfirmDelete(() => () => handleConfirmDeleteFlashCard(id));
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setFlashCardToDelete(null);
  };

  const handleAddFlashcardContent = (id: number) => {
    // Assuming your sub-themes page route is '/sub-themes'
    navigate(`/lessons/theme/subthemes/flashcard/${id}`, {
      state: { flashCardId: id },
    });
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-subthemes/${id}`
      );
      const themeId = response?.data.data[0].themeId;
      navigate(`/lessons/theme/${themeId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
    // navigate(`/lessons/theme/${subThemeId}`);
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            {/* </a> */}
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a flashcard
            </h4>
            <VerticalSpace height={30} />
            <div
              className="container d-flex theme-container justify-content-center"
              style={{ maxWidth: "666px" }}
            >
              <div
                className="container p-4"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                }}
              >
                <div className="col-12 theme-content">
                  <div className="d-flex justify-content-between">
                    <Label fontSize="20px" fontWeight={700}>
                      {parentSubThemeName}
                    </Label>
                  </div>

                  <div>
                    {flashcard.length === 0 ? (
                      <div className="p-5 d-flex justify-content-center align-items-center ">
                        <label
                          htmlFor="theme"
                          className="form-label"
                          style={{ fontWeight: "bold", color: "#7542E4" }}
                        >
                          There are no flashcard yet in this sub-theme.
                        </label>
                      </div>
                    ) : (
                      flashcard.map((flashcard) => (
                        <div
                          className="row d-flex justify-content-center align-items-center "
                          style={{ gap: "10px" }}
                          key={flashcard.id}
                        >
                          <div
                            className="drop_box theme-box col-9"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "7px 1px",
                              }}
                            >
                              <Label
                                fontSize="14px"
                                color="#000"
                                fontWeight={400}
                              >
                                {flashcard.name}
                              </Label>
                            </div>
                          </div>
                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleEditFlaseCard(flashcard)}
                          >
                            <img
                              src={editIcon}
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                          <div
                            className="p-0"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              backgroundColor: "rgb(203 203 203 / 99%)",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              handleAddFlashcardContent(flashcard.id)
                            }
                          >
                            <img
                              src={plusIcon}
                              width="31px"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>

                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() =>
                              handleDeleteFlashCard(
                                flashcard.id,
                                flashcard.name
                              )
                            }
                          >
                            <img
                              src={deleteIcon}
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>

                  <button
                    type="button"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      alignItems: "center",
                      borderRadius: "10px",
                      padding: "14px 40px",
                      width: "auto",
                    }}
                    onClick={handleNewFlaseCard}
                  >
                    Add flashcard
                  </button>
                </div>
              </div>
            </div>
          </div>

          {addFlashCard && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>Add a FlashCard</h5>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputthemes"
                      placeholder="New flashcard name"
                      value={themeName}
                      onChange={(e) => setThemeName(e.target.value)}
                    />
                    {flashCardError && (
                      <div className="error-message">{flashCardError}</div>
                    )}
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setNewFlashCard(false)}
                      style={{
                        // height: "50px",
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                      }}
                      onClick={handleSaveFlashCard}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <VerticalSpace height={120} />
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default FlashCard;
