import React from "react";
import "../../stylesheets/privacyPolicy.css";

const UserDataContent: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h2 className="centered-heading">
        Reglamento General de Protección de Datos (RGPD) y Otras Normativas de
        Protección de Datos para Lexik
      </h2>

      <p className="p-color">
        En Lexik, estamos comprometidos con la protección de tu privacidad y
        garantizamos que tus datos personales se manejen de manera segura y
        transparente. Cumplimos con el Reglamento General de Protección de Datos
        (RGPD) y otras leyes de protección de datos relevantes para proteger tu
        información.
      </p>

      <h3 className="heading-three">Tus Derechos Según el RGPD</h3>

      <h4 className="heading-four">
        Como usuario de Lexik, tienes los siguientes derechos en relación con
        tus datos personales:
      </h4>
      <p className="p-color">
        <b className="bold">Derecho de Acceso : </b>Tienes derecho a solicitar y
        obtener una copia de los datos personales que tenemos sobre ti. Esto
        incluye información sobre tu progreso de aprendizaje, interacción con la
        aplicación y cualquier otro dato asociado a tu cuenta.
      </p>

      <p className="p-color">
        <b className="bold">Derecho a la Portabilidad de los Datos : </b>Puedes
        solicitar que te proporcionemos tus datos en un formato estructurado, de
        uso común y de lectura mecánica. También puedes pedir que transfiramos
        tus datos directamente a otro servicio o plataforma.
      </p>

      <p className="p-color">
        <b className="bold">Derecho de Rectificación : </b>Si alguno de los
        datos personales que tenemos sobre ti es inexacto o está incompleto,
        tienes derecho a solicitar su corrección.
      </p>

      <p className="p-color">
        <b className="bold">Derecho de Supresión : </b>Puedes solicitar la
        eliminación de tus datos personales en determinadas circunstancias, como
        cuando los datos ya no son necesarios para los fines para los que fueron
        recogidos, o si retiras tu consentimiento.
      </p>

      <h3 className="heading-three">Cómo Gestionamos Tus Datos</h3>

      <p className="p-color">
        <b className="bold">Transparencia : </b>Somos transparentes sobre los
        datos que recogemos, cómo los utilizamos y con quién los compartimos.
        Puedes consultar nuestra Política de Privacidad en cualquier momento
        para entender nuestras prácticas.
      </p>

      <p className="p-color">
        <b className="bold">Seguridad de los Datos : </b>Utilizamos medidas de
        seguridad avanzadas para proteger tus datos contra el acceso no
        autorizado, la alteración o la pérdida.
      </p>

      <p className="p-color">
        <b className="bold">Cumplimiento Normativo : </b>Nos aseguramos de que
        nuestras prácticas de manejo de datos cumplan con el RGPD y otras leyes
        aplicables, como la Ley de Privacidad del Consumidor de California
        (CCPA) y la Ley de Protección de Datos del Reino Unido de 2018.
      </p>

      <p className="p-color">
        <b className="bold">Descarga de Tus Datos : </b>Para ejercer tu derecho
        de acceso y portabilidad de los datos, Lexik proporciona una opción
        sencilla en la configuración de tu perfil para descargar tus datos
        personales. Esta función te permite mantener un registro personal,
        compartir tu progreso con educadores o transferir tus datos a otro
        servicio.
      </p>

      <p className="p-color">
        Si tienes alguna pregunta sobre tus derechos de datos o necesitas ayuda
        para descargar tus datos, por favor contacta a nuestro equipo de soporte
        en <a href="mailto:support@lexik.com">support@lexik.com</a>.
      </p>
       <br/>
      <b className="bold" >
        Esta declaración en español explica claramente cómo Lexik maneja los
        datos de los usuarios, cumple con el RGPD y otras normativas, y
        garantiza que los usuarios estén al tanto de sus derechos.
      </b>
    </div>
  );
};

export default UserDataContent;
