// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
    width: 44px;
    height: 24px;
    background-color: red;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

.switch-toggle {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 8%;
    top: 13%;
    transform: translateY(-50%);
    transition: transform 0.3s, background-color 0.3s;
}

.switch.on {
    background-color: green;
}

.switch.on .switch-toggle {
    transform: translateX(20px);
    left: 6%;
}

.switch.off .switch-toggle {
    transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/switch.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,2BAA2B;IAC3B,iDAAiD;AACrD;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;IAC3B,QAAQ;AACZ;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".switch {\n    width: 44px;\n    height: 24px;\n    background-color: red;\n    border-radius: 15px;\n    position: relative;\n    cursor: pointer;\n}\n\n.switch-toggle {\n    width: 18px;\n    height: 18px;\n    background-color: white;\n    border-radius: 50%;\n    position: absolute;\n    left: 8%;\n    top: 13%;\n    transform: translateY(-50%);\n    transition: transform 0.3s, background-color 0.3s;\n}\n\n.switch.on {\n    background-color: green;\n}\n\n.switch.on .switch-toggle {\n    transform: translateX(20px);\n    left: 6%;\n}\n\n.switch.off .switch-toggle {\n    transform: translateX(0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
