import React from "react";
import TablePagination from "@mui/material/TablePagination";

interface PaginationComponentProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  hasNextPage,
  hasPreviousPage,
}) => {
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      rowsPerPageOptions={[5, 10, 25]}
      sx={{
        "& .MuiTablePagination-actions": {
          padding: '0px',
        },
        "& .MuiTablePagination-toolbar": {
          WebkitAlignItems: 'center',
          minHeight: 'fit-content',
          padding: "0px",
        },
        "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
          {
            margin: "0px",
            fontSize: "0.875rem", // Customize the font size of the text
          },
        "& .MuiTablePagination-select": {
          minWidth: "50px", // Customize the min width of the select
        },
      }}
      nextIconButtonProps={{ disabled: !hasNextPage }}
      backIconButtonProps={{ disabled: !hasPreviousPage }}
    />
  );
};

export default PaginationComponent;
