// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message{
    font-size: 12px;
    color: red;
    margin-left: 10px;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/inputBox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".error-message{\n    font-size: 12px;\n    color: red;\n    margin-left: 10px;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
