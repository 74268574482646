import React from "react";
import styled from "styled-components";

interface ParticleProps {
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: number;
  fontStyle?: string;
  styleName?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textAlign?: string;
  margin?: string;
  padding?: string;
  alignItems?: string;
  bgColor?: string;
  border?: string;
  color?: string;
  borderRadius?: string;
  marginLeft?: string;
  position?: string;
  gap?: string;
  top?: string;
  marginTop?: string;
  left?: string;
  opacity?: string;
  boxShadow?: string;
  display?: string;
  zIndex?: string;
  justifyContent?: string;
  transform?: string;
  innerColors?: string[]; // Array of inner colors
}

const ParticlesType = styled.div<ParticleProps>`
  width: ${(props) => props.width ?? ""};
  height: ${(props) => props.height ?? ""};
  font-size: ${(props) => props.fontSize ?? ""};
  font-size: ${(props) => props.fontSize ?? ""};
  font-weight: ${(props) => props.fontWeight ?? ""};
  line-height: ${(props) => props.lineHeight ?? ""};
  font-style: ${(props) => props.fontStyle ?? ""};
  letter-spacing: ${(props) => props.letterSpacing ?? ""};
  text-align: ${(props) => props.textAlign ?? ""};
  margin: ${(props) => props.margin ?? ""};
  padding: ${(props) => props.padding ?? ""};
  align-items: ${(props) => props.alignItems ?? ""};
  background-color: ${(props) => props.bgColor ?? ""};
  color: ${(props) => props.color ?? ""};
  border-radius: ${(props) => props.borderRadius ?? ""};
  margin-left: ${(props) => props.marginLeft ?? ""};
  position: ${(props) => props.position ?? ""};
  border: ${(props) => props.border ?? ""};
  gap: ${(props) => props.gap ?? "10px"};
  top: ${(props) => props.top ?? ""};
  margin-top: ${(props) => props.marginTop ?? ""};
  left: ${(props) => props.left ?? ""};
  opacity: ${(props) => props.opacity ?? ""};
  box-shadow: ${(props) => props.boxShadow ?? ""};
  display: ${(props) => props.display ?? "flex"};
  z-index: ${(props) => props.zIndex ?? ""};
  justify-content: ${(props) => props.justifyContent ?? ""};
  transform: ${(props) => props.transform ?? ""};
`;

const CenteredText = styled.div<{
  color?: string;
  width?: string;
  height?: string;
}>`
  background-color: ${(props) => props.color ?? "#FFFFFF"};
  width: ${(props) => props.width ?? "6px"};
  height: ${(props) => props.height ?? "4px"};
  text-align: center;
  border-radius: 1px;
`;

const CustomParticlesType = ({
  innerColors,
  
  ...props
}: ParticleProps & { children?: React.ReactNode }) => {
  return (
    <ParticlesType {...props}>
      {innerColors &&
        innerColors.map((color, index) => (
          <CenteredText key={index} color={color}>
            {props.children}
          </CenteredText>
        ))}
    </ParticlesType>
  );
};

export default CustomParticlesType;

// import React from "react";
// import styled from "styled-components";
// import ColumnContainer from "./ColumnContainer";
// import RowContainer from "./RowContainer";

// interface ParticleProps {
//   width?: string;
//   height?: string;
//   fontSize?: string;
//   fontWeight?: number;
//   fontStyle?: string;
//   styleName?: string;
//   fontFamily?: string;
//   lineHeight?: string;
//   letterSpacing?: string;
//   textAlign?: string;
//   margin?: string;
//   padding?: string;
//   alignItems?: string;
//   bgColor?: string;
//   border?: string;
//   color?: string;
//   borderRadius?: string;
//   marginLeft?: string;
//   position?: string;
//   gap?: string;
//   top?: string;
//   marginTop?: string;
//   left?: string;
//   opacity?: string;
//   boxShadow?: string;
//   display?: string;
//   zIndex?: string;
//   justifyContent?: string;
//   transform?: string;
//   innerColors?: string[]; // Array of inner colors
// }

// const ParticlesType = styled.div<ParticleProps>`
//   position: relative;
//   width: ${(props) => props.width ?? ""};
//   height: ${(props) => props.height ?? ""};
//   font-size: ${(props) => props.fontSize ?? ""};
//   font-weight: ${(props) => props.fontWeight ?? ""};
//   font-style: ${(props) => props.fontStyle ?? ""};
//   letter-spacing: ${(props) => props.letterSpacing ?? ""};
//   text-align: ${(props) => props.textAlign ?? ""};
//   margin: ${(props) => props.margin ?? ""};
//   padding: ${(props) => props.padding ?? ""};
//   align-items: ${(props) => props.alignItems ?? ""};
//   background-color: ${(props) => props.bgColor ?? ""};
//   color: ${(props) => props.color ?? ""};
//   border-radius: ${(props) => props.borderRadius ?? ""};
//   margin-left: ${(props) => props.marginLeft ?? ""};
//   position: ${(props) => props.position ?? ""};
//   border: ${(props) => props.border ?? ""};
//   gap: ${(props) => props.gap ?? "10px"};
//   top: ${(props) => props.top ?? ""};
//   margin-top: ${(props) => props.marginTop ?? ""};
//   left: ${(props) => props.left ?? ""};
//   opacity: ${(props) => props.opacity ?? ""};
//   box-shadow: ${(props) => props.boxShadow ?? ""};
//   display: ${(props) => props.display ?? ""};
//   z-index: ${(props) => props.zIndex ?? ""};
//   justify-content: ${(props) => props.justifyContent ?? ""};
//   transform: ${(props) => props.transform ?? ""};
// `;

// const CenteredText = styled.div<{ color?: string }>`
//   color: ${(props) => props.color ?? "#FFFFFF"};
// `;

// const CustomParticlesType = ({
//   innerColors,
//   children,
//   ...props
// }: ParticleProps & { children?: React.ReactNode }) => {
//   return (
//     <ParticlesType {...props}>
//       <ColumnContainer gap='0px'>
//         <RowContainer flex="none" padding="3.5px 10px 1px 10px">
//           <CenteredText>{children}</CenteredText>
//         </RowContainer>
//         <RowContainer
//           alignItem="center"
//           justifyContent="center"
//           padding="1px 10px 3.5px 10px"
//           gap='0px'
//         >
//           {innerColors &&
//             innerColors.map((color, index) => (
//               <div
//                 key={index}
//                 style={{ backgroundColor: color, width: "6px", height: "4px" ,borderRadius:'1px' }}
//               />
//             ))}
//         </RowContainer>
//       </ColumnContainer>
//     </ParticlesType>
//   );
// };

// export default CustomParticlesType;
