// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
  margin: 30px 200px;
  font-family: "Inter", sans-serif;
}

.centered-heading {
  justify-content: center;
  display: flex;
  font-size: 42px;
  line-height: 57px;
  font-weight: 800;
  color: #1D3557;
}

.heading-three {
  font-size: 25px;
  margin: 20px 0px;
   font-weight: 700;
  color: #1D3557;
}

.heading-four {
  color: #1D3557;
}

.bold {
  color: #1D3557;
}

ol {
  color: #1D3557;
}

.p-color {
  color: #1D3557;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0px;
}

.contact-info {
  color: #1D3557;
}

.contact-detail {
  font-size: 14px;
  margin: 1px 0px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .centered-heading {
    font-size: 32px;
    line-height: 45px;
  }

  .heading-three {
    font-size: 23px;
  }

  .p-color {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .privacy-policy {
    margin: 10px 20px;
  }

  .heading-three {
    font-size: 22px;
  }

  .centered-heading {
    font-size: 30px;
    line-height: 40px;
  }

  .p-color {
    font-size: 14px;
    line-height: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/privacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;GACf,gBAAgB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".privacy-policy {\n  margin: 30px 200px;\n  font-family: \"Inter\", sans-serif;\n}\n\n.centered-heading {\n  justify-content: center;\n  display: flex;\n  font-size: 42px;\n  line-height: 57px;\n  font-weight: 800;\n  color: #1D3557;\n}\n\n.heading-three {\n  font-size: 25px;\n  margin: 20px 0px;\n   font-weight: 700;\n  color: #1D3557;\n}\n\n.heading-four {\n  color: #1D3557;\n}\n\n.bold {\n  color: #1D3557;\n}\n\nol {\n  color: #1D3557;\n}\n\n.p-color {\n  color: #1D3557;\n  font-size: 16px;\n  line-height: 20px;\n  margin: 10px 0px;\n}\n\n.contact-info {\n  color: #1D3557;\n}\n\n.contact-detail {\n  font-size: 14px;\n  margin: 1px 0px;\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .centered-heading {\n    font-size: 32px;\n    line-height: 45px;\n  }\n\n  .heading-three {\n    font-size: 23px;\n  }\n\n  .p-color {\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n\n@media only screen and (max-width: 767px) {\n  .privacy-policy {\n    margin: 10px 20px;\n  }\n\n  .heading-three {\n    font-size: 22px;\n  }\n\n  .centered-heading {\n    font-size: 30px;\n    line-height: 40px;\n  }\n\n  .p-color {\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
