import styled from 'styled-components';

interface VerticalSpaceProps {
    height?: number;
}

const VerticalSpace = styled.div<VerticalSpaceProps>`
  width: 100%;
  height: ${(props) => props.height ?? 12}px;
 
`;

export default VerticalSpace;