import React, { useState } from "react";
import Label from "./Common/Label";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import HorizontalSpace from "./HorizontalSpace";

interface lessons {
  id: number;
  paquete: string;
  level: string;
  date: string;
  operation: boolean;
} 

const MembershipLessons: React.FC<lessons> = ({
  id,
  paquete,
  level,
  date,
  operation,
}) => {
  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      // width="696px"
      width="100%"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        // gap="3.6px"
        margin="10px"
      >
        <RowContainer flex="8" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {paquete}
          </Label>
        </RowContainer>
        <RowContainer flex="7" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {level}
          </Label>
        </RowContainer>
        <RowContainer flex="8" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {date}
          </Label>
        </RowContainer>
        <RowContainer flex="5" gap="0px"  alignItem="center" >
          {operation ? (
            <Label
              fontSize="10px"
              color="#FFFFFF"
              fontWeight={400}
              cursor="pointer"
              bgColor="#7542E4"
              justifyContent="center"
              display="flex"
              width="82px"
              borderRadius="40px"
              padding="8px 13px"
            >
              Redeemed
            </Label>
          ) : (
            <Label
              fontSize="10px"
              color="#FFFFFF"
              fontWeight={400}
              cursor="pointer"
              padding="8px 13px"
              bgColor="#00A3FF"
              justifyContent="center"
              display="flex"
              width="82px"
              borderRadius="40px"
            >
              Bought
            </Label>
          )}
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default MembershipLessons;
