// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jodit-container:not(.jodit_inline) {
  border: none !important;
}

.text-editor .css-fupe6q{
  border: 1px solid #ccc;
}

.text-editor .css-mk2oaz{
  height: 50px;
}
.text-editor .css-xabbgz{
  height: 50px;
}

.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root{
  /* display: none !important; */
   color: rgba(26,26,26,0.87) !important;
}
.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{
  color: rgba(26,26,26,0.87) !important;
}
.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root{
  /* display: none !important; */
 color: #e6e6e6 !important;
}
.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{
  color: #e6e6e6;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/TextEditor.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,8BAA8B;GAC7B,qCAAqC;AACxC;AACA;EACE,qCAAqC;AACvC;AACA;EACE,8BAA8B;CAC/B,yBAAyB;AAC1B;AACA;EACE,cAAc;AAChB","sourcesContent":[".jodit-container:not(.jodit_inline) {\n  border: none !important;\n}\n\n.text-editor .css-fupe6q{\n  border: 1px solid #ccc;\n}\n\n.text-editor .css-mk2oaz{\n  height: 50px;\n}\n.text-editor .css-xabbgz{\n  height: 50px;\n}\n\n.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root{\n  /* display: none !important; */\n   color: rgba(26,26,26,0.87) !important;\n}\n.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{\n  color: rgba(26,26,26,0.87) !important;\n}\n.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root{\n  /* display: none !important; */\n color: #e6e6e6 !important;\n}\n.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{\n  color: #e6e6e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
