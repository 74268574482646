import React from "react";
import { Form } from "react-bootstrap";
import "../../assets/css/customCheckbox.css";

interface Props {
  //   label: string;
  checked: boolean;
  onChange: () => void;
}

const CustomCheckbox: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <Form.Check
      type="checkbox"
      //   id={`custom-checkbox-${label}`}
      //   label={label}
      checked={checked}
      onChange={onChange}
      style={{
        width: "30px",
        // height: "30px",
      }}
      className="custom-checkbox"
    />
  );
};

export default CustomCheckbox;
