import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../services/authService";
import { useDispatch } from "react-redux";
import { removeParticles } from "../redux/particlesReducerSlice";
import { removeDropDown } from "../redux/lessonsSlice";

const PrivateComponent = () => {
  const auth = getToken();
  const dispatch = useDispatch();
  
  if (!auth?.token) {
    dispatch(removeDropDown());
    dispatch(removeParticles());
  }

  return auth?.token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateComponent;
