import React, { useState } from "react";
import Label from "../Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "../Common/RowContainer";
import Container from "../Common/Container";
import HorizontalSpace from "../HorizontalSpace";
import editIcon from "../../assets/editIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import styled from "styled-components";
import Switch from "../Common/Switch";
import CustomCheckbox from "../Common/CustomCheckbox";
import ViewIcon from "../../assets/Images/viewIcon.png";

interface Message {
  id: number;
  name: string;
  email: string;
  message: string;
  updatedAt: string;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  onView: (id: number) => void;
}


const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MessageList: React.FC<Message> = ({
  id,
  name,
  email,
  message,
  updatedAt,
  onDelete,
  onCheckboxChange,
  isChecked,
  onView,
}) => {
  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
      className="user-data user-container-hover"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        margin="10px"
      >
        <RowContainer flex="1.4">
          <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          />
        </RowContainer>
        <NewRowContainer flex="6">
          <NewLabel fontSize="16px" fontWeight={400}>
            {name}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="6.7">
          <NewLabel fontSize="16px" fontWeight={400}>
            {email}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="14">
          <NewLabel fontSize="16px" fontWeight={400} width="480px">
            {message}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="5.7">
          <NewLabel fontSize="16px" fontWeight={400} marginLeft="25px">
            {updatedAt}
          </NewLabel>
        </NewRowContainer>
        <RowContainer flex="3">
          <div
              className="user-scale"
            style={{
              cursor: "pointer",
              backgroundColor: "#e6e6e6",
              // opacity: "10%",
              alignItems: "center",
              display: "flex",
              borderRadius: "10px",
              padding: "4px",
            }}
          >
            <img
              src={ViewIcon}
              width="24px"
              onClick={() => onView(id)}
              height="24px"
              title="view message"
              style={{
                WebkitUserSelect: "none",
                userSelect: "none",
              }}
            />
          </div>
          {/* <div style={{ cursor: "pointer" }}>
            <img
              src={deleteIcon}
              onClick={() => onDelete(id)}
              title="delete message"
              style={{ WebkitUserSelect: "none", userSelect: "none" }}
            />
          </div> */}
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default MessageList;
