import { useState, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import ArrowIcon from "../../../../assets/ArrowIcon.svg";
import editIcon from "../../../../assets/editIcon.svg";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import CustomDropdown from "../../../Common/CustomDropdown";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import RowContainer from "../../../Common/RowContainer";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";

interface Bretzel {
  id: number;
  name: string;
  type: string;
  order: number;
}

const Bretzel = () => {
  const [bretzel, setBretzel] = useState<Bretzel[]>([]);
  const [addBretzel, setNewBretzel] = useState(false);
  const [group, setGroup] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [editItem, setEditItem] = useState<Bretzel | null>(null);
  const [bretzelId, setBretzelId] = useState<number | null>(null);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [bretzelToDelete, setBretzelToDelete] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};
  
  const dropdownItems: string[] = [
    "Select Item",
    "Block text",
    "Example",
    "Conjugation",
    "Add image",
    "Grammar single",
  ];

  const handleSelect = (item: string) => {
    setGroup(item);
    setSelectedItem(item);
  };

  const handleNewBretzel = () => {
    setNewBretzel(true);
    setEditItem(null);
  };

  const getBretzel = (selectedItem: string): string => {
    switch (selectedItem) {
      case "Block text":
        return "blockText";
      case "Grammar single":
        return "grammarSingle";
      case "Example":
        return "example";
      case "Conjugation":
        return "Conjugation";
      case "Add image":
        return "flashimage";
      default:
        return "";
    }
  };

  const setBretzelContent = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "Block Text";
      case "GrammarSingle":
        return "Grammar single";
      case "Example":
        return "Example";
      case "Conjugation":
        return "Conjugation";
      case "FlashImage":
        return "Image / svg";
      default:
        return "";
    }
  };

  const setBretzelType = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "blockText";
      case "GrammarSingle":
        return "grammarSingle";
      case "Example":
        return "example";
      case "Conjugation":
        return "conjugation";
      case "FlashImage":
        return "flashimage";
      default:
        return "";
    }
  };

  const getModelName = (type: string): string => {
    switch (type) {
      case "blockText":
        return "blockTexts";
      case "example":
        return "examples";
      case "conjugation":
        return "conjugations";
      case "flashimage":
        return "flashimages";
      case "grammarSingle":
        return "grammarSingles";
      default:
        throw new Error(`Unknown type: ${type}`);
    }
  };

  useEffect(() => {
   const fetchBretzel = async () => {
     try {
       setIsLoading(true);
       const response = await getRequest(
         `${baseURL}/lesson/get-britzel-component-flashcard-content-componentbyid/${flashcardContentId}`
       );
     
       const responseData = response?.data?.data?.sortedData;
     
       const responseBretzelId = response?.data?.data?.BretzelId;

       setBretzelId(responseBretzelId);

       if (responseData && responseData.length > 0) {
        
         setBretzel(
           responseData.map((group: any, index: number) => ({
             id: group.id,
             name: setBretzelContent(group.model),
             type: setBretzelType(group.model),
             order: index + 1,
           }))
         );  
         setBretzelId(responseData[0].britzelId)
       
       } else {
         setBretzel([]);
       }
         setIsLoading(false);
     } catch (error) {
       console.error("Error fetching grammar group:", error);
     }
   };
    if (flashcardContentId) {
      fetchBretzel();
    }
  }, [flashcardContentId]);

  const updateBrezelId = (number: any) => {
    setBretzelId(number);
  };


  const handleSaveBretzel = async () => {
    const bretzelType = getBretzel(selectedItem);

    try {
      let currentBretzelId = bretzelId;

      if (!currentBretzelId) {
        let data = { flashCardContentComponentId: flashcardContentId };
        setIsLoading(true);
        const createResponse = await postRequest(
          `${baseURL}/lesson/create-britzel`,
          data
        );
        setIsLoading(false);
        currentBretzelId = createResponse?.data.data.id;
        setBretzelId(currentBretzelId);
      }

      const reqData = {
        britzelId: currentBretzelId,
        type: bretzelType,
        order: bretzel.length + 1,
      };
      
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/add-britzel-component`,
        reqData
      );
    setIsLoading(false);
      const newBretzel = response?.data.data.map(
        (bretzelComponent: any, index: number) => ({
          id: bretzelComponent.id,
          name: setBretzelContent(bretzelComponent.model),
          type: setBretzelType(bretzelComponent.model),
          order: index + 1,
        })
      );
       
      setBretzel(newBretzel);
      setGroup("");
      setNewBretzel(false);
    } catch (error) {
      console.error("Error creating grammar group:", error);
    }
  };

  const handleEditBretzel = async (id: number) => {
    const selectedBretzel = bretzel.find((bretzel) => bretzel.id === id);
    if (selectedBretzel) {
      const { id, type } = selectedBretzel;
      console.log("type: ", type);

      const payload = { britzelId: id, type };
      console.log("payload: ", payload);
      
      const response = await postRequest(
        `${baseURL}/lesson/goto-britzel-component`,
        payload
      );
      navigate("/lessons/theme/subthemes/flashcard/content/bretzel/content", {
        state: {
          type: selectedBretzel.type,
          britzelId: selectedBretzel.id,
        },
      });
    }
  };

//   const handleDeleteBretzel =async (id: number) => {
//    try {
//     const selectedBretzel = bretzel.find((item)=>item.id === id);

//     if(!selectedBretzel){
//       throw new Error("Bretzel item not found");
//     }

//     const modelName = getModelName(selectedBretzel.type);

//     const reqData = {
     
//       modelName: modelName,
//       id: id,
//     };
//      console.log("reqData: ", reqData);
//    const response =  await postRequest(`${baseURL}/lesson/delete-bretzel-content`,reqData);
//     // await deleteRequest(
//     //   `${baseURL}/lesson/delete-flashcard-content-components/${id}`
//     // );
//     Toast(response?.data.statusMessage, "success");
//   const updatedbretzel = bretzel.filter((bretzel) => bretzel.id !== id);
//   console.log('updatedbretzel: ', updatedbretzel);
//   setBretzel(updatedbretzel);
// } catch (error) {
//   console.error("Error deleting bretzel Content:", error);
// }
//   };
  
  const handleConfirmDeleteBretzel = async (id: number) => {
    try {
      const selectedBretzel = bretzel.find((item) => item.id === id);
      if (!selectedBretzel) {
        throw new Error("Bretzel item not found");
      }

      const modelName = getModelName(selectedBretzel.type);

      const reqData = {
        modelName: modelName,
        id: id,
      };
      console.log("reqData: ", reqData);
      const response = await postRequest(
        `${baseURL}/lesson/delete-bretzel-content`,
        reqData
      );
      Toast(response?.data.statusMessage, "success");
      const updatedbretzel = bretzel.filter((bretzel) => bretzel.id !== id);
      setShowDeleteDialog(false);
      setBretzelToDelete(null);
      setBretzel(updatedbretzel);
    } catch (error) {
      console.error("Error deleting grammar group Content:", error);
      setShowDeleteDialog(false);
    }
  };

  const handleDeleteBretzel = (id: number, name: string) => {
    setBretzelToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
    setOnConfirmDelete(() => () => handleConfirmDeleteBretzel(id));
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setBretzelToDelete(null);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <VerticalSpace height={10} />
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Bretzel
            </h4>
            <VerticalSpace height={20} />
            <div className="row d-flex bretzel-container">
              <div className="col bretzel-content">
                <div
                  className="container d-flex justify-content-center"
                  style={{ maxWidth: "666px" }}
                >
                  <div
                    className="container p-4"
                    style={{
                      backgroundColor: "rgb(220 220 220 / 66%)",
                      borderRadius: "30px",
                    }}
                  >
                    <div className="col-12">
                      {/* <div className="d-align-items-center"> */}
                      <Label fontSize="20px" fontWeight={700}>
                        Content components
                      </Label>
                      {/* </div> */}
                      <div>
                        {bretzel.length === 0 ? (
                          <div className="p-5 d-flex justify-content-center align-items-center ">
                            <label
                              htmlFor="theme"
                              className="form-label"
                              style={{ fontWeight: "bold", color: "#7542E4" }}
                            >
                              There are no Bretzel yet in this FlashCard
                              Content.
                            </label>
                          </div>
                        ) : (
                          bretzel.map((bretzel) => (
                            <RowContainer key={bretzel.id} alignItem="center">
                              {/* <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ gap: "10px" }}
                                key={bretzel.id}
                              > */}
                              <div
                                className="drop_box bretzel-type col-10"
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Label
                                    fontSize="14px"
                                    color="#000"
                                    fontWeight={400}
                                  >
                                    {bretzel.name}
                                  </Label>
                                </div>

                                <div>
                                  <img
                                    src={ArrowIcon}
                                    style={{
                                      WebkitUserSelect: "none",
                                      userSelect: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="p-0"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() => handleEditBretzel(bretzel.id)}
                              >
                                <img
                                  src={editIcon}
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>

                              <div
                                className="p-0"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() =>
                                  handleDeleteBretzel(bretzel.id, bretzel.name)
                                }
                              >
                                <img
                                  src={deleteIcon}
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>
                              {/* </div> */}
                            </RowContainer>
                          ))
                        )}
                      </div>
                      <VerticalSpace height={20} />
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2 text-center"
                          style={{
                            color: "#100F15",
                            background: "none",

                            border: "1px solid #100F15",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                          onClick={handleNewBretzel}
                        >
                          Add new
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <VerticalSpace height={200} />
            </div>
          </div>

          {addBretzel && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>Add a Bretzel</h5>
                    <VerticalSpace height={10} />
                    <CustomDropdown
                      items={dropdownItems}
                      onSelect={handleSelect}
                      width="-webkit-fill-available"
                    />
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setNewBretzel(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                      }}
                      onClick={handleSaveBretzel}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default Bretzel;
