// MainLayout.tsx

import React, { ReactNode, useState, useEffect, useContext } from "react";
import Sidebar from "../layout/Sidebar";
import Header from "./Header";
import "../app.css";
import { Outlet } from "react-router-dom";
import Loader from "../utils/Loading";
import { SidebarContext } from "../hooks/UseSidebar";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
   const { side, toggleSide, setSide } = useContext(SidebarContext);

   const handleLinkClick = () => {
     if (side) {
       toggleSide();
     }
   };

   const [loading, setLoading] = useState(true);

   useEffect(() => {
     const timer = setTimeout(() => {
       setLoading(false);
     }, 1000);

     return () => clearTimeout(timer);
   }, []);

    const handleOutsideClick = () => {
      if (side) {
        setSide(false);
      }
    };

  return (
    <div className="main-layout">
      <Sidebar onLinkClick={handleLinkClick} />
      {side && <div className="overlay" onClick={handleOutsideClick}></div>}
      <div className="content-wrapper content" style={{ width: "100%" }}>
        <Header />
        <div className="content-wrappers">
          {loading ? (
            <Loader/>
          ) : (
            <>
              {children}
              <Outlet />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
