import React, { useEffect, useRef, useState } from "react";
import VerticalSpace from "../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faX } from "@fortawesome/free-solid-svg-icons";
import Label from "../../Common/Label";
import editIcon from "../../../assets/editIcon.svg";
import deleteIcon from "../../../assets/deleteIcon.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import RadioButton from "../../Common/RadioButton";
import ArrowIcon from "../../../assets/ArrowIcon.svg";
import CustomDropdown from "../../Common/CustomDropdown";
import HorizontalSpace from "../../HorizontalSpace";
import ParticlesType from "../../Common/ParticleType";
import RowContainer from "../../Common/RowContainer";
import RightSideArrow from "../../../assets/RightSideArrow.svg";
import Container from "../../Common/Container";
import ColumnContainer from "../../Common/ColumnContainer";
import HeartIcon from "../../../assets/Images/heartIcon.svg";
import { toast } from "react-toastify";
import flashcardIcon from "../../../assets/Images/flashcard.svg";
import dragAndDropIcon from "../../../assets/Images/drag&drop.svg";
import {
  baseURL,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../api/apiClient";
import Toast from "../../Common/Toast";
import "../../../stylesheets/theme.css";
import DeleteConfirmation from "../../Common/DeleteConfirmation";
import Loader from "../../../utils/Loading";
import gsap from 'gsap';
import '../../../stylesheets/common.css'
import DeleteLoader from "../../../utils/deleteLoader";

interface FlashCardContent {
  id: number;
  name: string;
  order: number;
}

const FlashCardContent = () => {
  const { id } = useParams();
  const [addFlashContnet, setNewFlashCard] = useState(false);
  const [flashCardName, setFlashCardName] = useState("");
  const [flashcontent, setFlashContent] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [flashcardcontent, setFlashCardContent] = useState<FlashCardContent[]>(
    []
  );
  const [bretzel, setBretzel] = useState<FlashCardContent[]>([]);
  const [selectedOption, setSelectedOption] = useState("bretzel");
  const [flashcardContentError, setFlashCardContentError] = useState("");
  const [dynamicItems, setDynamicItems] = useState<string[]>([]);
  const [particlesBgColor, setParticlesBgColor] = useState("#7542E4");
  const [showParticlesColorPicker, setShowParticlesColorPicker] =
    useState(false);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [flashCardContentToDelete, setFlashCardContentToDelete] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading]= useState(false);

  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const touchStartY = useRef<number | null>(null);
  const currentTouchIndex = useRef<number | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { flashCardId } = location.state || {};
  const fetchFlashcardContent = async () => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-flashcard-content-components/${id}`
      );

      if (response?.data.data) {
        const filteredContent = response?.data.data.flashcardContentComponent
          .filter((item: FlashCardContent) => item.name !== "Bretzel")
          .sort(
            (a: FlashCardContent, b: FlashCardContent) => a.order - b.order
          );
        setFlashCardName(response?.data.data.name);
        setParticlesBgColor(response?.data.data.color || "#7542E4");
        setFlashCardContent(filteredContent);
        const bretzel = response?.data.data.flashcardContentComponent.filter(
          (item: FlashCardContent) => item.name == "Bretzel"
        );
        setBretzel(bretzel);

        const itemNames = filteredContent.map(
          (item: FlashCardContent) => item.name
        );
        setDynamicItems(itemNames);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching sub-themes:", error);
    }
  };

  useEffect(() => {
    fetchFlashcardContent();
  }, [id]);

  const dropdownItems: string[] = [
    "Select Item",
    "Block text",
    "Example",
    "Conjugation",
    "Conjugation List",
    "Grammar group",
    "Particles List",
    "add image / svg",
  ];

  const handleSelect = (item: string) => {
    setFlashContent(item);
    setSelectedItem(item);
  };

  const handleNewFlaseContent = () => {
    setNewFlashCard(true);
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const getContentType = (selectedItem: string): string => {
    switch (selectedItem) {
      case "Block text":
        return "block_text";
      case "Example":
        return "example";
      case "Conjugation":
        return "conjugation";
      case "Conjugation List":
        return "conjugation_list";
      case "Grammar group":
        return "grammar_group";
      case "Particles List":
        return "particle_list";
      case "add image / svg":
        return "add_image_svg";

      default:
        return "";
    }
  };

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (
    index: number,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    if (draggedItemIndex !== null && draggedItemIndex !== index) {
      const newFlashcardContent = [...flashcardcontent];
      const draggedItem = newFlashcardContent[draggedItemIndex];
      newFlashcardContent.splice(draggedItemIndex, 1);
      newFlashcardContent.splice(index, 0, draggedItem);
      setFlashCardContent(newFlashcardContent);
      const itemNames = newFlashcardContent.map(
        (item: FlashCardContent) => item.name
      );
      setDynamicItems(itemNames);
      setDraggedItemIndex(index);
    }
  };

  const handleDrop = async () => {
    setDraggedItemIndex(null);

    const updateOrder = flashcardcontent.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    const response = await postRequest(
      `${baseURL}/flashcard-content-component/change-order`,
      updateOrder
    );
    if (response?.data.data) {
      const filteredContent = response?.data.data.filter(
        (item: FlashCardContent) => item.name !== "Bretzel"
      );
      setFlashCardContent(filteredContent);
      const itemNames = filteredContent.map(
        (item: FlashCardContent) => item.name
      );
      setDynamicItems(itemNames);
    }
  };

  const handleDragEnd = () => {
    setDraggedItemIndex(null);
  };

  const handleTouchStart = (
    index: number,
    event: React.TouchEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    setDraggedItemIndex(index);
    currentTouchIndex.current = index;
    touchStartY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (draggedItemIndex !== null && touchStartY.current !== null) {
      const currentY = event.touches[0].clientY;
      const movedDistance = currentY - touchStartY.current;
      const sensitivityThreshold = 1;
      if (Math.abs(movedDistance) > sensitivityThreshold) {
        const newIndex =
          movedDistance > 0
            ? currentTouchIndex.current! + 1
            : currentTouchIndex.current! - 1;
        if (newIndex >= 0 && newIndex < flashcardcontent.length) {
          const newFlashcardContent = [...flashcardcontent];
          const draggedItem = newFlashcardContent[draggedItemIndex];
          newFlashcardContent.splice(draggedItemIndex, 1);
          newFlashcardContent.splice(newIndex, 0, draggedItem);
          setFlashCardContent(newFlashcardContent);
          const itemNames = newFlashcardContent.map(
            (item: FlashCardContent) => item.name
          );
          setDynamicItems(itemNames);
          setDraggedItemIndex(newIndex);
          currentTouchIndex.current = newIndex;
          touchStartY.current = currentY;
        }
      }
    }
  };

  const handleTouchEnd = async () => {
    setDraggedItemIndex(null);
    touchStartY.current = null;
    currentTouchIndex.current = null;
    await handleDrop();
  };

  const handleSaveFlashCard = async () => {
    const contentComponentType = getContentType(selectedItem);

    const activeFlashcardId = flashCardId || id;

    try {
      const nextOrder = flashcardcontent.length + 1;

      const reqData = {
        flashCardId: activeFlashcardId,
        contentComponentType: contentComponentType,
        name: flashcontent,
        order: nextOrder,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/create-flashcard-content-component`,
        reqData
      );

      if (response?.data) {
        const newflashcardContent: FlashCardContent = {
          id: response?.data.data.id,
          name: response?.data.data.name,
          order: response?.data.data.order,
        };

        setDynamicItems([...dynamicItems, selectedItem]);
        setFlashCardContent([...flashcardcontent, newflashcardContent]);
      }
      setIsLoading(false);
      setFlashContent("");
      setNewFlashCard(false);
    } catch (error) {
      console.error("Error creating flashcard content:", error);
    }
  };

  const handleConfirmDeleteFlashCardContent = async (id: number) => {
    try {
      setLoading(true);
      const response = await deleteRequest(
        `${baseURL}/lesson/delete-flashcard-content-components/${id}`
      );
      setLoading(false);
      const updatedflashcard = flashcardcontent.filter(
        (flashcardContent) => flashcardContent.id !== id
      );

      const createDynamicName = updatedflashcard.map(({ name }) => {
        return name;
      });

      setFlashCardContent(updatedflashcard);

      // const updatedDynamicItems = dynamicItems.filter(
      //   (item) => item !== flashcardcontent.find((fc) => fc.id === id)?.name
      // );
      setDynamicItems(createDynamicName);

      setShowDeleteDialog(false);
      setFlashCardContentToDelete(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      setLoading(false);
      console.error("Error deleting flashcard:", error);
      setShowDeleteDialog(false);
    }
  };

  const handleDeleteFlaseCardContent = (id: number, name: string) => {
    setFlashCardContentToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
    setOnConfirmDelete(() => () => handleConfirmDeleteFlashCardContent(id));
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setFlashCardContentToDelete(null);
  };

  const handleEditBretzel = async () => {
    let bretzelContent = bretzel.find((content) => content.name === "Bretzel");

    if (!bretzelContent) {
      const reqData = {
        flashCardId: flashCardId || id,
        contentComponentType: "bretzel",
        name: "Bretzel",
      };

      try {
        const response = await postRequest(
          `${baseURL}/lesson/create-flashcard-content-component`,
          reqData
        );

        bretzelContent = {
          id: response?.data.data.id,
          name: response?.data.data.name,
          order: response?.data.data.order,
        };
      } catch (error) {
        console.error("Error creating Bretzel content:", error);
        return;
      }
    }

    navigate("/lessons/theme/subthemes/flashcard/content/bretzel", {
      state: { flashcardContentId: bretzelContent.id },
    });
  };

  const handleEditFlashContent = async (id: number, name: string) => {
    let routePath = "/lessons/theme/subthemes/flashcard/content/";

    switch (name) {
      case "Block text":
        routePath += "blocktext";
        break;
      case "Example":
        routePath += "example";
        break;
      case "Conjugation":
        routePath += "conjugation";
        break;
      case "Conjugation List":
        routePath += "conjugation-list";
        break;
      case "Grammar group":
        routePath += "grammar-group";
        break;
      case "Particles List":
        routePath += "particles-list";
        break;
      case "add image / svg":
        routePath += "add-image-svg";
        break;
      case "Bretzel":
        routePath += "bretzel";
        break;
      default:
        break;
    }

    navigate(routePath, {
      state: { flashcardContentId: id },
    });
  };

  const handleColorPicker = async () => {
    setIsLoading(true);
    const resData = {
      flashcardId: flashCardId || id,
      color: particlesBgColor,
    };
    const response = await postRequest(
      `${baseURL}/flashcard/add-flashcard-color`,
      resData
    );
    setIsLoading(false);
    Toast(response?.data.statusMessage, "success");
  };

  const handleBackClick = async () => {
    const activeFlashcardId = flashCardId || id;
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcards/${activeFlashcardId}`
      );
      const subthemeId = response?.data.data[0].subthemeId;
      navigate(`/lessons/theme/subthemes/${subthemeId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });

    timeLine.from(".left-container", {
      opacity: 0,
      x: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2
    });

    timeLine.from(".right-container", {
      opacity: 0,
      x: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Flashcard Content
            </h4>
            <VerticalSpace height={30} />
            <div className="row p-3 d-flex main-flashcard justify-content-between">
              <div className="col preview-content left-container">
                <div
                  className="container d-flex justify-content-center"
                  style={{ maxWidth: "666px" }}
                >
                  <div
                    className="container p-3"
                    style={{
                      backgroundColor: "rgb(220 220 220 / 66%)",
                      borderRadius: "30px",
                    }}
                  >
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label fontSize="20px" fontWeight={700}>
                          Preview
                        </Label>

                        <div>
                          {/*  */}
                          <RowContainer
                            bgColor="#FFFFFF"
                            padding="13px 10px"
                            borderRadius="10px"
                            border="1px solid #D9D9D9"
                            alignItem="center"
                            onClick={() => setShowParticlesColorPicker(true)}
                            cursor="pointer"
                            gap="15px"
                          >
                            <input
                              type="color"
                              className="form-control form-control-color user"
                              id="myColor"
                              value={particlesBgColor}
                              onChange={(e) =>
                                setParticlesBgColor(e.target.value)
                              }
                              style={{
                                border: "0px",
                                padding: "3.5px 10px",
                                width: "32px",
                                color: particlesBgColor,
                                backgroundColor: particlesBgColor,
                                height: "24px",
                                borderRadius: "20px",
                              }}
                            />
                            <img src={RightSideArrow} alt="Right-Arrow" />
                          </RowContainer>
                        </div>
                      </div>
                      <VerticalSpace height={9} />

                      <Container
                        bgColor="#FFFFFF"
                        border="1px solid #D9D9D9"
                        borderRadius="10px"
                        padding="27px 94px"
                        className="preview-container"
                      >
                        <Container>
                          <RowContainer
                            // bgColor="#7542E4"
                            borderRadius="40px 40px 0px 0px"
                            justifyContent="space-between"
                            padding="16px 18px 8px 16px"
                            alignItem="center"
                            bgColor={particlesBgColor}
                          >
                            <img src={HeartIcon} alt="heartIcon" />
                            <Label
                              fontSize="15px"
                              color="#FFFFFF"
                              fontWeight={700}
                            >
                              {flashCardName}
                            </Label>
                            <img src={flashcardIcon} alt="flashcardIcon" />
                          </RowContainer>
                          <ColumnContainer
                            padding="24px"
                            backgroundColor="#323235"
                            borderRadius="0px 0px 40px 40px"
                          >
                            {dynamicItems.map((item, index) => (
                              <RowContainer
                                key={index}
                                alignItem="center"
                                justifyContent="center"
                                padding="27px 114px"
                                border="1px dotted #FFFFFF"
                                borderRadius="20px"
                                margin="10px 0"
                                className="type-container"
                              >
                                <Label
                                  fontSize="14px"
                                  color="#FFFFFF"
                                  fontWeight={400}
                                  width="max-content"
                                >
                                  {item}
                                </Label>
                              </RowContainer>
                            ))}
                          </ColumnContainer>
                        </Container>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
              <HorizontalSpace width={2} />

              <div className="col type-contnet right-container">
                <div
                  className="container d-flex justify-content-center"
                  style={{ maxWidth: "766px" }}
                >
                  <div
                    className="container p-4"
                    style={{
                      backgroundColor: "rgb(220 220 220 / 66%)",
                      borderRadius: "30px",
                    }}
                  >
                    <div className="col-12">
                      <Label fontSize="20px" fontWeight={700}>
                        Content components
                      </Label>

                      <div>
                        {flashcardcontent.length === 0 ? (
                          <div className="p-5 d-flex justify-content-center align-items-center ">
                            <label
                              htmlFor="theme"
                              className="form-label"
                              style={{ fontWeight: "bold", color: "#7542E4" }}
                            >
                              There are no flashcard content yet in this
                              flashcard.
                            </label>
                          </div>
                        ) : (
                          flashcardcontent
                            .filter(
                              (flashcontent) => flashcontent.name !== "Bretzel"
                            )
                            .map((flashcontent, index) => (
                              <>
                                <div
                                  style={{ gap: "10px" }}
                                  key={flashcontent.id}
                                  className={`d-flex justify-content-between align-items-center flashCard-Content ${
                                    draggedItemIndex === index ? "dragging" : ""
                                  }`}
                                >
                                  <RowContainer alignItem="center">
                                    <div
                                      draggable
                                      onDragStart={() => handleDragStart(index)}
                                      onDragOver={(event) =>
                                        handleDragOver(index, event)
                                      }
                                      onDrop={handleDrop}
                                      onDragEnd={handleDragEnd}
                                      onTouchStart={(event) =>
                                        handleTouchStart(index, event)
                                      }
                                      onTouchMove={handleTouchMove}
                                      onTouchEnd={handleTouchEnd}
                                      style={{ cursor: "grab" }}
                                    >
                                      <img
                                        src={dragAndDropIcon}
                                        style={{
                                          cursor: "grab",
                                          backgroundColor: "#D9D9D9",
                                          borderRadius: "5px",
                                          height: "45px",
                                          pointerEvents: "none",
                                        }}
                                        draggable="false"
                                      />
                                    </div>
                                    <div
                                      className="drop_box"
                                      style={{
                                        width: "100%",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <Label
                                          fontSize="14px"
                                          color="#000"
                                          fontWeight={400}
                                        >
                                          {flashcontent.name}
                                        </Label>
                                      </div>

                                      <div>
                                        <img
                                          src={ArrowIcon}
                                          style={{
                                            WebkitUserSelect: "none",
                                            userSelect: "none",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </RowContainer>
                                  <RowContainer
                                    justifyContent="end"
                                    width="fit-content"
                                    className="edit-deleteIcon"
                                  >
                                    <div
                                      className="p-0"
                                      style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                      }}
                                      onClick={() =>
                                        handleEditFlashContent(
                                          flashcontent.id,
                                          flashcontent.name
                                        )
                                      }
                                    >
                                      <img
                                        className="user-scale"
                                        src={editIcon}
                                        style={{
                                          WebkitUserSelect: "none",
                                          userSelect: "none",
                                        }}
                                      />
                                    </div>

                                    <div
                                      className="p-0"
                                      style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                      }}
                                      onClick={() =>
                                        handleDeleteFlaseCardContent(
                                          flashcontent.id,
                                          flashcontent.name
                                        )
                                      }
                                    >
                                      <img
                                        className="user-scale"
                                        src={deleteIcon}
                                        style={{
                                          WebkitUserSelect: "none",
                                          userSelect: "none",
                                        }}
                                      />
                                    </div>
                                  </RowContainer>
                                </div>
                              </>
                            ))
                        )}
                      </div>
                      <VerticalSpace height={20} />
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          type="button"
                          className="flashCardContent-button user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            padding: "10px 40px",
                            borderRadius: "10px",
                          }}
                          onClick={handleNewFlaseContent}
                        >
                          Add new
                        </button>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <RadioButton
                            checked={selectedOption === "bretzel"}
                            onChange={() => handleOptionChange("bretzel")}
                          />
                          <label htmlFor="bretzel">Bretzel</label>
                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleEditBretzel()}
                          >
                            <img
                              src={editIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <VerticalSpace height={150} />
            </div>
            <div
              className="container row d-flex justify-content-center publish-button"
              style={{
                position: "fixed",
                zIndex: 1,
                height: "12%",
                maxWidth: "100%",
                bottom: "0px",
                background:
                  "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  position: "fixed",
                  zIndex: 1,
                  bottom: "34px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-success d-flex justify-content-center user"
                  style={{
                    backgroundColor: "#FF9E00",
                    color: "#100F15",
                    height: "fit-content",
                    borderRadius: "10px",
                    padding: "10px 20%",
                    width: "400px",
                    fontSize: "14px",
                  }}
                  onClick={() => handleColorPicker()}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>

          {addFlashContnet && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>
                      Add a Flashcard Content Component
                    </h5>
                    <VerticalSpace height={10} />
                    <CustomDropdown
                      items={dropdownItems}
                      onSelect={handleSelect}
                      width="-webkit-fill-available"
                    />
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setNewFlashCard(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                      }}
                      onClick={handleSaveFlashCard}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {loading && (
        <DeleteLoader />
      )}
    </div>
  );
};
export default FlashCardContent;
