import styled from 'styled-components';

interface HorizontalSpaceProps {
  width?: number;
  border?: string;
  margintop?: string;
  height?: number;
}

const HorizontalSpace = styled.div<HorizontalSpaceProps>`
  width: ${(props) => props.width ?? 12}%;
  // height: 100%;
  height: ${(props) => props.height ?? '100'}%;
  border:${(props) => props.border ?? ''};
  margin-top:${(props) => props.margintop ?? ''};
`;

// Example usage:
// <Button width={200} height={44}>Click me</Button>




// Example usage:
// <Button width={200} height={44}>Click me</Button>

export default HorizontalSpace;
