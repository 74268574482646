import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface Item {
  id: number;
  name: string;
  pId: number;
  children?: Item[];
  particleType?: string;
  particleValue?: string;
  particleType1?: string;
  particleValue1?: string;
}

interface NestedMenuProps {
  data: Item[] | null;
  loading: boolean;
  error: string | null;
}

interface ParticlesState{
  data: NestedMenuProps[];
  loading: boolean;
  error: string | null;
}

const initialState: ParticlesState = {
    data: [],
    loading: false,
    error: null,
}

const particlesReducerSlice = createSlice({
    name: "particles",
    initialState,
    reducers: {
     setParticles: (state, action: PayloadAction<NestedMenuProps[]>) => {
      localStorage.setItem('particlesdata',JSON.stringify(action.payload))
      state.data = action.payload;
    },
    getParticles : (state) => {
      const data = localStorage.getItem('particlesdata')
      if(data){
        state.data = JSON.parse(data);
      }
    },
    removeParticles: ()=>{
        localStorage.removeItem('particlesdata');
    }
    }
})

export const selectParticlesData = (state: RootState) => state.particles.data;

export const {setParticles,getParticles,removeParticles} =particlesReducerSlice.actions;

export default particlesReducerSlice.reducer;