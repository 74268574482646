import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getToken } from "../services/authService";
import LoginPage from "../pages/Login/login";
import PrivateComponent from "../utils/AuthComponent";
import MainLayout from "../layout/mainLayout";
import NotFoundPage from "../pages/NotFoundPage";
import Dashboard from "../pages/Dashboard/dashboard";
import MemberShipUsers from "../pages/Memberships/members";
import MembersUsers from "../pages/Members/users";
import CreateUser from "../Components/User/createUser";
import Lessons from "../pages/Lessons/lessons";
import CreateLessons from "../Components/Lessons/createlessons";
import SubThemes from "../Components/Lessons/Themes/SubThemes";
import FlashCard from "../Components/Lessons/Themes/Flashcard";
import FlashCardContent from "../Components/Lessons/Themes/FlashCardContent";
import Question from "../pages/Question/question";
import CreateQuestion from "../Components/Question/createQuestion";
import Particles from "../pages/Particles/particles";
import NewParticles from "../Components/Particles/CreateParticle";
import ParticlesLevel from "../Components/Particles/ParticleLevel";
import ParticlesLevelTwo from "../Components/Particles/ParticleLevelTwo";
import ParticlesLevelThree from "../Components/Particles/ParticleLevelThree";
import ParticlesLevelFour from "../Components/Particles/ParticleLevelFour";
import BlockText from "../Components/Lessons/Themes/Flashcard/BlockText";
import ConjugationComponent from "../Components/Lessons/Themes/Flashcard/Conjugation";
import ExampleComponents from "../Components/Lessons/Themes/Flashcard/Example";
import ImageComponents from "../Components/Lessons/Themes/Flashcard/ImageComponents";
import GrammarGroup from "../Components/Lessons/Themes/Flashcard/GrammarGroup";
import Bretzel from "../Components/Lessons/Themes/Flashcard/Bretzel";
import ConjugationList from "../Components/Lessons/Themes/Flashcard/ConjugationList";
import GrammarGroupContent from "../Components/Lessons/Themes/Flashcard/GrammarGroupContent";
import BretzelContentComponent from "../Components/Lessons/Themes/Flashcard/BretzelContentComponent";
import ParticlesList from "../Components/Lessons/Themes/Flashcard/ParticlesList";
import NotificationsList from "../pages/Notifications/notifications";
import Notifications from "../Components/Notifications/createNotification";
import TextFieldsList from "../pages/TextFields/textFieldsList";
import TextFields from "../Components/TextFields/createTextFields";
import DragDropWordsList from "../pages/Drag&DropWords/Drag&DropWordsList";
import DragDropWords from "../Components/DragDropWords/createDragDropWords";
import DragDropColorsList from "../pages/DragDropColorsList/DragDropColorsList";
import DragDropColors from "../Components/DragDropColors/createDragDropColors";
import MultipleOptionsList from "../pages/MultipleOption/multipleOptionList";
import CreateMultipleOptions from "../Components/MultipleOptions/createMultipleOption";
import Messages from "../pages/Messages/messages";
import Tickets from "../pages/Tickets/tickets";
import HelmetWrapper from "../Components/Common/HelmetWrapper";
import EditMemberShipUser from "../Components/MemberShipUsers/editMemberShipUser";
import InActiveUsers from "../pages/Members/inActiveUsers";
import ViewTicktes from "../Components/Ticket/ViewTickets";
import Configs from "../pages/Config/Config";
import CreateConfig from "../Components/Config/CreateConfig";
import ViewMessage from "../Components/Message/MessageView";
import {
  UserData, PrivacyPolicy,
  Faqs,
} from "../pages/TermsAndPolicy/PrivacyPolicy";

const AppRoutes: React.FC = () => {
  const auth = getToken();
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            auth?.token ? (
              <Navigate to="/" />
            ) : (
              <>
                <HelmetWrapper title="Lexik- Login" />
                <LoginPage />
              </>
            )
          }
        />
        <Route element={<PrivateComponent />}>
          <Route element={<MainLayout />}>
            <Route
              path="/"
              element={
                <>
                  <HelmetWrapper title="Lexik- Dashboard" />
                  <Dashboard />
                </>
              }
            />

            {/* membership members */}
            <Route
              path="/members"
              element={
                <>
                  <HelmetWrapper title="Lexik- Members" />
                  <MemberShipUsers />
                </>
              }
            />
            <Route
              path="/members/edit-member/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Members" />
                  <EditMemberShipUser />
                </>
              }
            />

            {/* members */}
            <Route
              path="/users"
              element={
                <>
                  <HelmetWrapper title="Lexik- Users" />
                  <MembersUsers />
                </>
              }
            />
            <Route
              path="/users/create-user"
              element={
                <>
                  <HelmetWrapper title="Lexik- Users" />
                  <CreateUser />
                </>
              }
            />
            <Route
              path="/users/edit-user/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Users" />
                  <CreateUser />
                </>
              }
            />

            {/* InActive Users InActiveUsers*/}

            <Route
              path="/users/inactive-users"
              element={
                <>
                  <HelmetWrapper title="Lexik- InActive Users" />
                  <InActiveUsers />
                </>
              }
            />

            {/* lessons */}
            <Route
              path="/lessons"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <Lessons />
                </>
              }
            />
            <Route
              path="/lessons/create-lessons"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <CreateLessons />
                </>
              }
            />
            <Route
              path="/lessons/edit-lessons/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <CreateLessons />
                </>
              }
            />
            <Route
              path="/lessons/theme/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <SubThemes />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <FlashCard />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <FlashCardContent />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/blocktext"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <BlockText />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/conjugation"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <ConjugationComponent />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/example"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <ExampleComponents />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/grammar-group"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <GrammarGroup />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/grammar-group/content"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <GrammarGroupContent />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/conjugation-list"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <ConjugationList />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/particles-list"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <ParticlesList />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/add-image-svg"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <ImageComponents />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/bretzel"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <Bretzel />
                </>
              }
            />
            <Route
              path="/lessons/theme/subthemes/flashcard/content/bretzel/content"
              element={
                <>
                  <HelmetWrapper title="Lexik- Lessons" />
                  <BretzelContentComponent />
                </>
              }
            />

            {/* questions */}
            <Route
              path="/questions"
              element={
                <>
                  <HelmetWrapper title="Lexik- Questions" />
                  <Question />
                </>
              }
            />
            <Route
              path="/questions/create"
              element={
                <>
                  <HelmetWrapper title="Lexik- Questions" />
                  <CreateQuestion />
                </>
              }
            />
            <Route
              path="/questions/edit/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Questions" />
                  <CreateQuestion />
                </>
              }
            />

            {/* particles */}
            <Route
              path="/particles"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <Particles />
                </>
              }
            />
            <Route
              path="/particles/create"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <NewParticles />
                </>
              }
            />
            <Route
              path="/particles/edit-particle/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <NewParticles />
                </>
              }
            />
            <Route
              path="/particles/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <ParticlesLevel />
                </>
              }
            />
            <Route
              path="/particles/level/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <ParticlesLevelTwo />
                </>
              }
            />
            <Route
              path="/particles/leveltwo/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <ParticlesLevelThree />
                </>
              }
            />
            <Route
              path="/particles/levelthree/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Particles" />
                  <ParticlesLevelFour />
                </>
              }
            />

            {/* Notifications */}
            <Route
              path="/notifications"
              element={
                <>
                  <HelmetWrapper title="Lexik- Notifications" />
                  <NotificationsList />
                </>
              }
            />
            <Route
              path="/notifications/create-notification"
              element={
                <>
                  <HelmetWrapper title="Lexik- Notifications" />
                  <Notifications />
                </>
              }
            />
            <Route
              path="/notifications/edit-notification/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Notifications" />
                  <Notifications />
                </>
              }
            />

            {/* TextFields */}
            <Route
              path="/textfields"
              element={
                <>
                  <HelmetWrapper title="Lexik- Textfields" />
                  <TextFieldsList />
                </>
              }
            />
            <Route
              path="/textfields/create-textfields"
              element={
                <>
                  <HelmetWrapper title="Lexik- Textfields" />
                  <TextFields />
                </>
              }
            />
            <Route
              path="/textfields/edit-textfields/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Textfields" />
                  <TextFields />
                </>
              }
            />

            {/* DragDropWords */}
            <Route
              path="/drag-drop-words"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Words" />
                  <DragDropWordsList />
                </>
              }
            />
            <Route
              path="/drag-drop-words/create-drag-drop-words"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Words" />
                  <DragDropWords />
                </>
              }
            />
            <Route
              path="/drag-drop-words/edit-drag-drop-words/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Words" />
                  <DragDropWords />
                </>
              }
            />

            {/* DragDropColors */}
            <Route
              path="/drag-drop-colors"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Colors" />
                  <DragDropColorsList />
                </>
              }
            />
            <Route
              path="/drag-drop-colors/create-drag-drop-colors"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Colors" />
                  <DragDropColors />
                </>
              }
            />
            <Route
              path="/drag-drop-colors/edit-drag-drop-colors/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Drag & Drop Colors" />
                  <DragDropColors />
                </>
              }
            />

            {/* Multiple Options */}
            <Route
              path="/multiple-options"
              element={
                <>
                  <HelmetWrapper title="Lexik- Multiple Options" />
                  <MultipleOptionsList />
                </>
              }
            />
            <Route
              path="/multiple-options/create-multiple-options"
              element={
                <>
                  <HelmetWrapper title="Lexik- Multiple Options" />
                  <CreateMultipleOptions />
                </>
              }
            />
            <Route
              path="/multiple-options/edit-multiple-options/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Multiple Options" />
                  <CreateMultipleOptions />
                </>
              }
            />

            {/* messages */}
            <Route
              path="/messages"
              element={
                <>
                  <HelmetWrapper title="Lexik- Messages" />
                  <Messages />
                </>
              }
            />
            <Route
              path="/message/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Messages" /> <ViewMessage />
                </>
              }
            />

            {/* tickets */}
            <Route
              path="/tickets"
              element={
                <>
                  <HelmetWrapper title="Lexik- Tickets" />
                  <Tickets />
                </>
              }
            />
            <Route
              path="/ticket/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Tickets" />
                  <ViewTicktes />
                </>
              }
            />

            {/* configs */}
            <Route
              path="/configs"
              element={
                <>
                  <HelmetWrapper title="Lexik- Configs" />
                  <Configs />
                </>
              }
            />
            <Route
              path="/configs/edit-config/:id"
              element={
                <>
                  <HelmetWrapper title="Lexik- Configs" />
                  <CreateConfig />
                </>
              }
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            auth?.token ? (
              <>
                <HelmetWrapper title="Lexik- 404" />
                <NotFoundPage />
              </>
            ) : (
              <NotFoundPage />
              // <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/user-data"
          element={
            <>
              <HelmetWrapper title="User Data" />
              <UserData />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <HelmetWrapper title="Privacy Policy" />
              <PrivacyPolicy />
            </>
          }
        />
        <Route
          path="/faqs"
          element={
            <>
              <HelmetWrapper title="FAQS" />
              <Faqs />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
