import React, { createContext, useState, ReactNode } from 'react';

interface SidebarContextProps {
  side: boolean;
  toggleSide: () => void;
  setSide: (value: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  side: false,
  toggleSide: () => {},
  setSide: () => {},
});

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [side, setSide] = useState(false);

    const toggleSide = () => {
        setSide((prevSide) => !prevSide);
    };

    return (
      <SidebarContext.Provider value={{ side, toggleSide, setSide }}>
        {children}
      </SidebarContext.Provider>
    );
};
