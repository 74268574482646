import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipUser from "../../Components/membershipUser";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import DragDropColor from "../../Components/DragDropColors/DragDropColor";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { setDropDown } from "../../redux/lessonsSlice";
import { setParticles } from "../../redux/particlesReducerSlice";
import { useDispatch } from "react-redux";
import Loader from "../../utils/Loading";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface DragDropColorsList {
  id: number;
  themeName: string;
  points: string;
  updatedAt: string;
  active: boolean;
}

const DragDropColorsList = () => {
  const [dragDropColors, setDragDropColors] = useState<
    DragDropColorsList[]
  >([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedColors, setSelectedColors] = useState<number[]>([]);
  const [selectedColorsId, setSelectedColorsId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
  }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const dropdownItems: string[] = ["All","Level","Latest update"];
   
   useEffect(() => {
     fetchColors(page + 1, limit, filters);
   }, [page, limit, filters]);

   //fetch Colors list
   const fetchColors = async (
     page: number,
     perPage: number,
     filters: { level?: string; updatedAt?: number }
   ) => {
     setLoading(true);
     try {
       let url = `${baseURL}/drag-and-drop-colors-challenge/get-all-drag-and-drop-colors-challenge?page=${page}&limit=${perPage}`;
       if (filters.level) {
         url += `&level=${filters.level}`;
       }
       if (filters.updatedAt) {
         url += `&updatedAt=${filters.updatedAt}`;
       }
       const response = await getRequest(url);
       setLoading(false);
       if (response?.data) {
         setDragDropColors(response.data.data.result);
         setCount(response.data.data.totalChallengesCount);
         setPage(response.data.data.page - 1);
         setHasNextPage(response.data.data.hasNextPage);
         setHasPreviousPage(response.data.data.hasPreviousPage);
       }
       
       const fetchLessons = await getRequest(
         `${baseURL}/notification-challenge/get-lesson-dropdown`
       );

       const fetchParticles = await getRequest(
         `${baseURL}/particle/get-dropdown`
       );
       dispatch(setDropDown(fetchLessons?.data?.data || []));
       dispatch(setParticles(fetchParticles?.data?.data || []));
     } catch (error) {
       console.error("Error fetching lessons:", error);
     }
   };

   const handleChangePage = (
     event: React.MouseEvent<HTMLButtonElement> | null,
     newPage: number
   ) => {
     setPage(newPage);
   };

   const handleChangeRowsPerPage = (
     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
     setLimit(parseInt(event.target.value, 10));
     setPage(0);
   };

   const handleApplyFilters = () => {
     switch (selectedItem) {
       case "Level":
         setFilters({level: "A1,A2,B1" });
         break;
       case "Latest update":
         setFilters({updatedAt: -1 });
         break;
       default:
         setFilters({});
         break;
     }
   };


  const handleEditColors = (id: number) => {
    navigate(`/drag-drop-colors/edit-drag-drop-colors/${id}`);
  };

  const handleCreatedragDropColors = () => {
    navigate("/drag-drop-colors/create-drag-drop-colors");
  };

 const handleStatusChange = async (id: number, active: boolean) => {
   const reqData = {
     modelName: "dragAndDropColorsChallenge",
     id: id,
     isActive: !active,
   };
   await postRequest(`${baseURL}/change-status`, reqData);
   await fetchColors(page + 1, limit, filters);
 };

  const onCheckboxChange = (id: number) => {
    if (selectedColors.includes(id)) {
      setSelectedColors(selectedColors.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedColors([...selectedColors, id]);
    }
  };

  const deleteSelectedColors = () => {
    if (selectedColors.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one challenge to delete.", "error");
    }
  };

  const deleteColors = async (colorsIds: number | number[]) => {
    try {
      const payload = {
        id: Array.isArray(colorsIds) ? colorsIds : colorsIds,
      };
      const response = await postRequest(
        `${baseURL}/drag-and-drop-colors-challenge/delete-drag-and-drop-colors-challenge-by-id`,
        payload
      );

      if (response?.status === 200) {
        Toast("Colors deleted successfully", "success");
        setDragDropColors((prevData) =>
          prevData.filter((colors) =>
            Array.isArray(colorsIds)
              ? !colorsIds.includes(colors.id)
              : colors.id !== colorsIds
          )
        );
      } else {
        Toast("Failed to delete challenge", "error");
      }
    } catch (error) {
      console.error("Error deleting challenge:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedColorsId !== null) {
      await deleteColors(selectedColorsId);
    } else {
      await deleteColors(selectedColors);
    }
    await fetchColors(page + 1, limit, filters);
    setSelectedColors([]);
    setSelectedColorsId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>
                    Challenges drag & drop colors
                  </h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedColors}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleCreatedragDropColors}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="13">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Theme
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Point
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>
                      <RowContainer flex="2.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {dragDropColors && dragDropColors.length > 0 ? (
                      <ColumnContainer>
                        {dragDropColors.map((dragDropColor) => (
                          <DragDropColor
                            key={dragDropColor.id}
                            id={dragDropColor.id}
                            theme={dragDropColor.themeName}
                            point={`${dragDropColor.points} pts`}
                            created={formatDate(dragDropColor.updatedAt)}
                            onEdit={handleEditColors}
                            onDelete={() => {
                              setSelectedColorsId(dragDropColor.id);
                              toggleDeleteDialog();
                            }}
                            isChecked={selectedColors.includes(
                              dragDropColor.id
                            )}
                            onCheckboxChange={onCheckboxChange}
                            status={dragDropColor.active}
                            onStatusChange={() =>
                              handleStatusChange(
                                dragDropColor.id,
                                dragDropColor.active
                              )
                            }
                          />
                        ))}
                      </ColumnContainer>
                    ) : (
                      <>
                        <VerticalSpace height={30} />
                        <Label
                          alignItems="center"
                          display="flex"
                          color="rgba(164, 163, 167, 1)"
                          justifyContent="center"
                        >
                          No results found.
                        </Label>
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedColors.length === 0
                    ? "this drag & drop colors?"
                    : selectedColors.length === 1
                    ? "this drag & drop colors?"
                    : "these drag & drop colors?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};

export default DragDropColorsList;
