import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import VerticalSpace from "../../../VerticalSpace";
import Label from "../../../Common/Label";
import RadioButton from "../../../Common/RadioButton";
import HorizontalSpace from "../../../HorizontalSpace";
import TextEditor from "../../../Common/TextEditor";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import RowContainer from "../../../Common/RowContainer";
import ParticlesType from "../../../Common/ParticleType";
import {
  baseURL,
  getRequest,
  multiPostRequest,
  postRequest,
} from "../../../../api/apiClient";
import { toast } from "react-toastify";
import Toast from "../../../Common/Toast";
import NestedMenu from "../../../ParticlesDropdown/NestedMenu";
import CustomCheckbox from "../../../Common/CustomCheckbox";
import "../../../../stylesheets/theme.css";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";

interface BretzelContent {
  id: number;
  type: string;
  lightTheme: string | null;
  description?: string;
  flashimage?: string | null;
  particles?: any;
  order: number;
  dropdownValue?: DropdownValue | null;
  inputValue?: string;
  britzelId?: string;

  header?: string;
  footer?: string;
  size?: string;
  sentence?: { [key: string]: string };
  conjugation?: { type: string; value: string; translation: string }[];
  particleValue?: string;

  pairs?: Pair[];
  conjugations?: Conjugations;
}
interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

// interface Pair {
//   dropdownValue: DropdownValue | null;
//   inputValue: string;
// }

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  showCheckbox?: boolean;
  isInputChecked?: boolean;
  particleData?: any;
  order?: number;
}

interface Conjugations {
  ich: string[];
  du: string[];
  ersiees: string[];
  wir: string[];
  ihr: string[];
  siesie: string[];
}

type HeaderFooterState = { [key: number]: string };

const BretzelContentComponent = () => {
  const [bretzelContent, setBretzelContent] = useState<BretzelContent[]>([]);

  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState<{
    [id: number]: string;
  }>({});
  console.log("selectedOption: ", selectedOption);
  const [size, setSelectedSize] = useState<{ [id: number]: string }>({});
  const [flashImage, setFlashImage] = useState<{
    [id: number]: File | string | null;
  }>({});
  const [particles, setParticles] = useState<{ [id: number]: string }>({});
  const [pairs, setPairs] = useState<{ [id: number]: Pair[] }>({});
  const fileInputRefs = useRef<{ [id: number]: HTMLInputElement | null }>({});
  const [header, setHeader] = useState<HeaderFooterState>({});
  const [footer, setFooter] = useState<HeaderFooterState>({});
  const [infinitiveVerb, setInfinitiveVerb] = useState<HeaderFooterState>({});
  const [conjugations, setConjugations] = useState<{
    [id: number]: Conjugations;
  }>({});
  const [globalTheme, setGlobalTheme] = useState("Light");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [deletetSentenceIdDelete, setDeleteSentenceIdDelete] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoader, setImageLoader] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [errors, setErrors] = useState<Record<number, string[]>>({});
  const [validationErrors, setValidationErrors] = useState<
    Record<number, { dropdownValue?: string; inputValue?: string }[]>
  >({});
  const navigate = useNavigate();
  const location = useLocation();

  const { type, britzelId } = location.state || {};

  // const handleOptionChange = (id: number, option: string) => {
  //   setBretzelContent((prev) =>
  //     prev.map((item) =>
  //       item.id === id ? { ...item, lightTheme: option } : item
  //     )
  //   );
  // };

  const handleOptionChange = (id: number, option: string) => {
    console.log("Id:", id, "status:", option);

    setSelectedOption((prevOptions) => ({
      ...selectedOption,
      [id]: option,
    }));
  };

  const handleDescriptionChange = (id: number, value: string) => {
    setBretzelContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id ? { ...item, description: value } : item
      )
    );

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        newErrors[id] = newErrors[id].filter(
          (error) => error !== "Description is required."
        );
        if (newErrors[id].length === 0) {
          delete newErrors[id];
        }
      }
      return newErrors;
    });
  };

  const handleFlashImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setImageLoader((prev) => ({ ...prev, [id]: true }));
      setFlashImage((prev) => ({
        ...prev,
        [id]: files[0],
      }));
      setImageLoader((prev) => ({ ...prev, [id]: false }));
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id]; // Remove any existing error for this id
      return newErrors;
    });
  };

  const handleHeaderChange = (id: number, value: string) => {
    setHeader((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleFooterChange = (id: number, value: string) => {
    setFooter((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInfinitiveVerbChange = (id: number, value: string) => {
    setInfinitiveVerb((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id]; // Remove any existing error for this id
      return newErrors;
    });
  };

  const handleConjugationChange = (
    itemId: number,
    person: keyof Conjugations,
    value: string,
    translation: string
  ) => {
    setConjugations((prevConjugations) => {
      const prevItemConjugations = prevConjugations[itemId] || {
        ich: ["", ""],
        du: ["", ""],
        ersiees: ["", ""],
        wir: ["", ""],
        ihr: ["", ""],
        siesie: ["", ""],
      };

      return {
        ...prevConjugations,
        [itemId]: {
          ...prevItemConjugations,
          [person]: [value, translation],
        },
      };
    });
  };

  const handleConjugationInputChange = (
    itemId: number,
    person: keyof Conjugations,
    index: number,
    value: string
  ) => {
    setConjugations((prevConjugations) => {
      const newConjugations = { ...prevConjugations };

      // If item ID doesn't exist, create a new object for it
      if (!newConjugations[itemId]) {
        newConjugations[itemId] = {
          ich: ["", ""],
          du: ["", ""],
          ersiees: ["", ""],
          wir: ["", ""],
          ihr: ["", ""],
          siesie: ["", ""],
        };
      }

      // Update the conjugation value at the specified person and index
      newConjugations[itemId][person][index] = value;

      return newConjugations;
    });
  };

  const handleChooseFileClick = (id: number) => {
    const fileInputRef = fileInputRefs.current[id];
    if (fileInputRef) {
      fileInputRef.click();
    }
  };

  const handleSizeChange = (id: number, option: string) => {
    setSelectedSize((prevOptions) => ({
      ...prevOptions,
      [id]: option,
    }));
  };

  const handleThemeChange = (option: string) => {
    setGlobalTheme(option);
  };

  const addNewPair = (id: number) => {
    setPairs((prevPairs) => {
      const newOrder = (prevPairs[id]?.length || 0) + 1;
      return {
        ...prevPairs,
        [id]: [
          ...(prevPairs[id] || []),
          {
            dropdownValue: null,
            inputValue: "",
            showCheckbox: false,
            isInputChecked: false,
            order: newOrder,
          },
        ],
      };
    });
  };

  const handleInputChange = (id: number, index: number, value: string) => {
    setPairs((prevPairs) => {
      const updatedPairs = [...(prevPairs[id] || [])];
      updatedPairs[index].inputValue = value;
      return { ...prevPairs, [id]: updatedPairs };
    });

    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[id] && updatedErrors[id][index]) {
        delete updatedErrors[id][index].inputValue;
      }
      return updatedErrors;
    });
  };

  const removePair = async (id: number, index: number, sentenceId?: number) => {
    if (sentenceId !== undefined) {
      try {
        const reqData = {
          modelName: "examplesentences",
          id: sentenceId,
        };
        console.log("reqData: ", reqData);
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          setPairs((prevPairs) => {
            const updatedPairs = [...(prevPairs[id] || [])];
            updatedPairs.splice(index, 1);
            return { ...prevPairs, [id]: updatedPairs };
          });
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      setPairs((prevPairs) => {
        const updatedPairs = [...(prevPairs[id] || [])];
        updatedPairs.splice(index, 1);
        return { ...prevPairs, [id]: updatedPairs };
      });
    }
  };

  const handleDropdownChange = (
    id: number,
    index: number,
    value: DropdownValue
  ) => {
    setPairs((prevPairs) => {
      const updatedPairs = [...(prevPairs[id] || [])];
      if (updatedPairs[index]) {
        updatedPairs[index] = {
          ...updatedPairs[index],
          dropdownValue: value,
          showCheckbox: value.name === "Text",
        };
      }
      return { ...prevPairs, [id]: updatedPairs };
    });

    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[id] && updatedErrors[id][index]) {
        delete updatedErrors[id][index].dropdownValue;
      }
      return updatedErrors;
    });
  };

  const handleCheckboxChange = (id: number, index: number) => {
    setPairs((prevPairs) => {
      const updatedPairs = [...(prevPairs[id] || [])];
      if (updatedPairs[index]) {
        updatedPairs[index] = {
          ...updatedPairs[index],
          isInputChecked: !updatedPairs[index].isInputChecked,
        };
      }
      return { ...prevPairs, [id]: updatedPairs };
    });
  };

  const handleParticlesDropdownChange = (id: number, value: DropdownValue) => {
    setBretzelContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id
          ? {
              ...item,
              particles: {
                ...item.particles,
                dropdownValue: value,
              },
            }
          : item
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        newErrors[id] = newErrors[id].filter(
          (error) => error !== "Particle dropdown value is required."
        );
        if (newErrors[id].length === 0) {
          delete newErrors[id];
        }
      }
      return newErrors;
    });
  };

  const handleParticlesInputChange = (id: number, value: string) => {
    setBretzelContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id
          ? {
              ...item,
              particles: {
                ...item.particles,
                inputValue: value,
              },
            }
          : item
      )
    );
  };

  const setBretzelType = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "blockText";
      case "GrammarSingle":
        return "grammarSingle";
      case "Example":
        return "example";
      case "Conjugation":
        return "conjugation";
      case "FlashImage":
        return "flashimage";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchBretzelContent();
  }, [type]);

  const fetchBretzelContent = async () => {
    try {
      const reqData = {
        britzelId: britzelId,
        type: type,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/goto-britzel-component`,
        reqData
      );

      if (!response?.data?.data) {
        console.error("Invalid response structure", response);
        return;
      }

      const data = response?.data.data;

      if (!Array.isArray(data)) {
        console.error("Invalid data format", data);
        return;
      }

      const formattedData = data.map((item: any) => ({
        ...item,
        type: setBretzelType(item.model),
      }));

      formattedData.sort(
        (a: BretzelContent, b: BretzelContent) => a.order - b.order
      );
      setBretzelContent(formattedData);

      // Iterate over each item to set the state accordingly
      for (const item of formattedData) {
        if (item.description)
          setDescription((prev) => `${prev}\n${item.description}`);
        console.log("selectedOption", item.lightTheme);

        if (item.lightTheme) {
          setSelectedOption((prev) => ({
            ...prev,
            [item.id]: item.lightTheme || "Light",
          }));
        }

        console.log("selectedOption", selectedOption);

        // setGlobalTheme(item.lightTheme);

        if (item.flashimage) {
          const file = new File([], item.flashimage.split("/").pop());
          setImageLoader((prev) => ({ ...prev, [item.id]: true }));
          setFlashImage((prev) => ({
            ...prev,
            [item.id]: item.flashimage,
          }));
          setImageLoader((prev) => ({ ...prev, [item.id]: false }));
        }

        if (item.header)
          setHeader((prev) => ({ ...prev, [item.id]: item.header }));
        if (item.footer)
          setFooter((prev) => ({ ...prev, [item.id]: item.footer }));
        if (item.size)
          setSelectedSize((prev) => ({ ...prev, [item.id]: item.size }));

        if (item.sentences && Array.isArray(item.sentences)) {
          const sentencePairs = await Promise.all(
            item.sentences.map(async (sentence: any) => {
              try {
                const particle = await fetchParticleById(Number(sentence.type));
                return {
                  id: sentence.id,
                  dropdownValue: particle,
                  inputValue: sentence.value,
                  showCheckbox: particle?.name === "Text",
                  isInputChecked: sentence.isStrike || false,
                  order: sentence.order || 0,
                };
              } catch (error) {
                console.error("Error fetching particle:", error);
                // Handle the error appropriately, e.g., return a default value
                return {
                  dropdownValue: null,
                  inputValue: "",
                  showCheckbox: false,
                  isInputChecked: false,
                  order: 0,
                };
              }
            })
          );

          setPairs((prev) => ({ ...prev, [item.id]: sentencePairs }));
        }

        if (item.conjugation) {
          const conjugationObj: Conjugations = {
            ich: ["", ""],
            du: ["", ""],
            ersiees: ["", ""],
            wir: ["", ""],
            ihr: ["", ""],
            siesie: ["", ""],
          };
          item.conjugation.forEach((conj: any) => {
            if (conjugationObj[conj.type as keyof Conjugations]) {
              conjugationObj[conj.type as keyof Conjugations] = [
                conj.value,
                conj.translation,
              ];
            }
          });
          setConjugations((prev) => ({
            ...prev,
            [item.id]: conjugationObj,
          }));
          if (item.particleValue)
            setInfinitiveVerb((prev) => ({
              ...prev,
              [item.id]: item.particleValue,
            }));
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching bretzel content:", error);
    }
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleSave = async () => {
    const newErrors: Record<number, string[]> = {};
    const pairsErrors: Record<
      number,
      { dropdownValue?: string; inputValue?: string }[]
    > = {};
    let hasError = false;
    // if (pairs[id] && pairs[id].length === 0) {
    //   Toast("Please add at least one pair.", "error");
    //   return;
    // }

    bretzelContent.forEach((item) => {
      if (item.type === "blockText" && !item.description) {
        newErrors[item.id] = [
          ...(newErrors[item.id] || []),
          "Description is required.",
        ];
      }
      if (item.type === "grammarSingle") {
        if (!item.particles?.dropdownValue) {
          newErrors[item.id] = [
            ...(newErrors[item.id] || []),
            "Particle dropdown value is required.",
          ];
        }
      }
      if (item.type === "flashimage" && !flashImage[item.id]) {
        newErrors[item.id] = [
          ...(newErrors[item.id] || []),
          "Flash image is required.",
        ];
      }
      if (item.type === "conjugation" && !infinitiveVerb[item.id]) {
        newErrors[item.id] = [
          ...(newErrors[item.id] || []),
          "infinitiveVerb is required.",
        ];
      }
      if (item.type === "example") {
        const examplePairs = pairs[item.id] || [];
        if (examplePairs.length === 0) {
          Toast(`Please add at least one pair.`, "error");
          hasError = true;
        }
      }
    });

    Object.keys(pairs).forEach((idStr) => {
      const id = Number(idStr);
      pairs[id].forEach((item, index) => {
        if (!item.dropdownValue) {
          pairsErrors[id] = [...(pairsErrors[id] || [])];
          pairsErrors[id][index] = {
            ...pairsErrors[id][index],
            dropdownValue: "Dropdown value is required.",
          };
          hasError = true;
        }
        if (!item.inputValue) {
          pairsErrors[id] = [...(pairsErrors[id] || [])];
          pairsErrors[id][index] = {
            ...pairsErrors[id][index],
            inputValue: "Input value is required.",
          };
          hasError = true;
        }
      });
    });

    // Set errors state with new validation errors
    setValidationErrors(pairsErrors);

    // If there are validation errors, do not proceed with saving

    //  let hasPairs = false;
    //  Object.keys(pairs).forEach((idStr) => {
    //    if (pairs[Number(idStr)].length > 0) {
    //      hasPairs = true;
    //    }
    //  });
    //  if (!hasPairs) {
    //    Toast("Please add at least one pair.", "error");
    //    return;
    //  }

    if (Object.keys(pairsErrors).length > 0) {
      return;
    }

    // Set errors state with new validation errors
    setErrors(newErrors);

    // If there are validation errors, do not proceed with saving
    if (Object.keys(newErrors).length > 0) {
      Toast("Please check validation!", "error");
      return;
    }

    if (hasError) {
      return;
    }

    // type SentenceType = { [key: string]: string };

    // let sentence: { [id: string]: SentenceType } = {};

    // Object.entries(pairs).forEach(([id, pairList]) => {
    //   sentence[id] = pairList.reduce((acc: SentenceType, pair: Pair) => {
    //     if (pair.dropdownValue) {
    //       acc[pair.dropdownValue.id] = pair.inputValue;
    //     }
    //     return acc;
    //   }, {});
    // });

    type SentenceType = {
      id: number;
      value: string;
      type: string;
      order: number;
      isStrike: boolean;
    }[];

    let sentence: { [id: number]: SentenceType } = {};

    Object.entries(pairs).forEach(([id, pairList]) => {
      sentence[Number(id)] = pairList.map((pair) => ({
        id: pair.id || 0,
        value: pair.inputValue,
        type: pair.dropdownValue?.id.toString() || "",
        order: pair.order || 0,
        isStrike: pair.isInputChecked || false,
      }));
    });

    // Use `sentence` as needed
    console.log(sentence);

    // sentence = pairs.reduce((acc: SentenceType, pair: Pair) => {
    //   acc[`"${pair.dropdownValue}"`] = `${pair.inputValue}`;
    //   return acc;
    // }, {});

    const conjugationData = Object.entries(conjugations)
      .map(([id, conjugationObj]) =>
        Object.entries(conjugationObj).map(([person, values]) => ({
          id: Number(id),
          type: person,
          value: values[0],
          translation: values[1],
        }))
      )
      .flat();

    // const conjugationData = Object.entries(conjugations).map(([key, value]) => ({
    //   type: key,
    //   value: value[0],
    //   translation: value[1],
    // }));

    try {
      const formData = new FormData();

      const updatedItems = await Promise.all(
        bretzelContent.map(async (item) => {
          if (item.type === "blockText") {
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              description: item.description,
              lightTheme: selectedOption[item.id] || "Light",
            };
          } else if (item.type === "grammarSingle") {
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              lightTheme: selectedOption[item.id] || "Light",
              particles: item.particles,
              description: item.description,
            };
          } else if (item.type === "flashimage") {
            const flashImages = flashImage[item.id];
            if (flashImages && flashImages instanceof File) {
              const uniqueKey = `flashimage_${item.id}`;
              formData.append(uniqueKey, flashImages);
              return {
                id: item.id,
                order: item.order,
                type: item.type,
                flashimage: uniqueKey,
              };
            } else {
              console.error("flashImage is not a valid File");
              return item;
            }
          } else if (item.type === "example") {
            const isDenkanstoB = selectedOption[item.id] === "Denkanstoẞ";
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              lightTheme: isDenkanstoB
                ? undefined
                : selectedOption[item.id] || "Light",
              header: header[item.id],
              footer: footer[item.id],
              size: size[item.id],
              sentence: sentence[item.id],
              isDenkanstob: isDenkanstoB,
            };
          } else if (item.type === "conjugation") {
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              lightTheme: selectedOption[item.id] || "Light",
              conjugation: conjugationData.filter(
                (conj) => conj.id === item.id
              ),
              particleValue: infinitiveVerb[item.id],
              particleType: "infinitiveVerb",
            };
          }
          return item;
        })
      );
      console.log("updatedItems: ", updatedItems);
      formData.append("data", JSON.stringify(updatedItems));

      setIsLoading(true);
      const response = await multiPostRequest(
        `${baseURL}/lesson/update-britzel-component`,
        formData
      );
      const data = response?.data.data;

      const formattedData = data.map((item: any) => ({
        ...item,
        type: setBretzelType(item.model),
      }));

      formattedData.sort(
        (a: BretzelContent, b: BretzelContent) => a.order - b.order
      );

      setBretzelContent(formattedData);

      for (const item of formattedData) {
        if (item.description)
          setDescription((prev) => `${prev}\n${item.description}`);

        if (item.lightTheme) {
          setSelectedOption((prev) => ({
            ...prev,
            [item.id]: item.lightTheme || "Light",
          }));
        }

        // setGlobalTheme(item.lightTheme);

        if (item.flashimage) {
          const file = new File([], item.flashimage.split("/").pop());
          setImageLoader((prev) => ({ ...prev, [item.id]: true }));
          setFlashImage((prev) => ({
            ...prev,
            [item.id]: item.flashimage,
          }));
          setImageLoader((prev) => ({ ...prev, [item.id]: false }));
        }

        if (item.header)
          setHeader((prev) => ({ ...prev, [item.id]: item.header }));
        if (item.footer)
          setFooter((prev) => ({ ...prev, [item.id]: item.footer }));
        if (item.size)
          setSelectedSize((prev) => ({ ...prev, [item.id]: item.size }));

        if (item.sentences && Array.isArray(item.sentences)) {
          const sentencePairs = await Promise.all(
            item.sentences.map(async (sentence: any) => {
              try {
                const particle = await fetchParticleById(Number(sentence.type));
                return {
                  id: sentence.id,
                  dropdownValue: particle,
                  inputValue: sentence.value,
                  showCheckbox: particle?.name === "Text",
                  isInputChecked: sentence.isStrike || false,
                  order: sentence.order || 0,
                };
              } catch (error) {
                console.error("Error fetching particle:", error);
                // Handle the error appropriately, e.g., return a default value
                return {
                  dropdownValue: null,
                  inputValue: "",
                  showCheckbox: false,
                  isInputChecked: false,
                  order: 0,
                };
              }
            })
          );

          setPairs((prev) => ({ ...prev, [item.id]: sentencePairs }));
        }

        if (item.conjugation) {
          const conjugationObj: Conjugations = {
            ich: ["", ""],
            du: ["", ""],
            ersiees: ["", ""],
            wir: ["", ""],
            ihr: ["", ""],
            siesie: ["", ""],
          };
          item.conjugation.forEach((conj: any) => {
            if (conjugationObj[conj.type as keyof Conjugations]) {
              conjugationObj[conj.type as keyof Conjugations] = [
                conj.value,
                conj.translation,
              ];
            }
          });
          setConjugations((prev) => ({
            ...prev,
            [item.id]: conjugationObj,
          }));
          if (item.particleValue)
            setInfinitiveVerb((prev) => ({
              ...prev,
              [item.id]: item.particleValue,
            }));
        }
      }

      Toast(response?.data.statusMessage, "success");
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleConfirmDeleteSentenceId = async (
    id: number,
    index: number,
    sentenceId?: number
  ) => {
    if (sentenceId !== undefined) {
      try {
        const reqData = {
          modelName: "examplesentences",
          id: sentenceId,
        };
        console.log("reqData: ", reqData);
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          setPairs((prevPairs) => {
            const updatedPairs = [...(prevPairs[id] || [])];
            updatedPairs.splice(index, 1);
            return { ...prevPairs, [id]: updatedPairs };
          });
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      setPairs((prevPairs) => {
        const updatedPairs = [...(prevPairs[id] || [])];
        updatedPairs.splice(index, 1);
        return { ...prevPairs, [id]: updatedPairs };
      });
    }
  };

  const removeSentenceId = (id: number, index: number) => {
    setPairs((prevPairs) => {
      const updatedPairs = [...(prevPairs[id] || [])];
      updatedPairs.splice(index, 1);
      return { ...prevPairs, [id]: updatedPairs };
    });
  };

  const handleDeleteSentenceId = (
    id: number,
    index: number,
    sentenceId?: number
  ) => {
    if (sentenceId !== undefined) {
      setDeleteSentenceIdDelete(id);
      setDeleteDialogMessage(`Are you sure you want to delete this Sentence?`);
      setOnConfirmDelete(
        () => () => handleConfirmDeleteSentenceId(id, index, sentenceId)
      );
      setShowDeleteDialog(true);
    } else {
      removeSentenceId(id, index);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteSentenceIdDelete(null);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-britzel-content/${bretzelContent[0].britzelId}`
      );

      const flashCardContentComponentId =
        response?.data.data.flashCardContentComponentId;

      navigate(`/lessons/theme/subthemes/flashcard/content/bretzel`, {
        state: {
          flashcardContentId: flashCardContentComponentId,
        },
      });
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex align-items-center"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Bretzel
          </h4>
          <VerticalSpace height={30} />
          {bretzelContent.map((item) => (
            <div key={item.id}>
              {item.type === "blockText" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <Label
                            fontSize="20px"
                            fontWeight={700}
                            padding="0px 0px 0px 10px"
                          >
                            Description
                          </Label>
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={selectedOption[item.id] === "Light"}
                              onChange={() =>
                                handleOptionChange(item.id, "Light")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <HorizontalSpace width={20} />
                            <RadioButton
                              checked={selectedOption[item.id] === "Dark"}
                              onChange={() =>
                                handleOptionChange(item.id, "Dark")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <div>
                          <TextEditor
                            onChange={(value) =>
                              handleDescriptionChange(item.id, value)
                            }
                            value={item.description}
                            lightTheme={
                              selectedOption[item.id] as "Light" | "Dark"
                            }
                          />
                          {errors[item.id]?.includes(
                            "Description is required."
                          ) && (
                            <p
                              className="text-danger"
                              style={{ marginLeft: "10px" }}
                            >
                              Description is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
              {item.type === "example" && (
                <>
                  <div
                    className="container example-container"
                    style={{ maxWidth: "1117px" }}
                  >
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex example-top justify-content-between">
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={size[item.id] === "Full"}
                              onChange={() => handleSizeChange(item.id, "Full")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              full
                            </Label>
                            <HorizontalSpace
                              width={20}
                              className="horizontal-space"
                            />
                            <RadioButton
                              checked={size[item.id] === "Half"}
                              onChange={() => handleSizeChange(item.id, "Half")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              half
                            </Label>
                          </div>
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={selectedOption[item.id] === "Light"}
                              onChange={() =>
                                handleOptionChange(item.id, "Light")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <RadioButton
                              checked={selectedOption[item.id] === "Dark"}
                              onChange={() =>
                                handleOptionChange(item.id, "Dark")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                            {/* <RadioButton
                              checked={selectedOption[item.id] === "Denkanstoẞ"}
                              onChange={() =>
                                handleOptionChange(item.id, "Denkanstoẞ")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              denkanstoẞ
                            </Label> */}
                          </div>
                        </div>
                        <VerticalSpace height={16} />

                        <Label
                          fontSize="20px"
                          padding="0px 0px 0px 8px"
                          fontWeight={700}
                        >
                          Header (sentence)
                        </Label>

                        <VerticalSpace height={16} />
                        <div
                          className="d-flex header-input align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="inputthemes"
                            placeholder=""
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            // value={header[item.id] || ''}
                            // onChange={(e) => handleFooterChange(item.id, e.target.value)}
                            value={header[item.id] || ""}
                            onChange={(e) =>
                              handleHeaderChange(item.id, e.target.value)
                            }
                          />
                          <div
                            className="p-0"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                            }}
                            // onClick={() => handleDeleteSubTheme(subtheme.id)}
                          >
                            {/* <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/> */}
                          </div>
                        </div>
                        <VerticalSpace height={26} />
                        <div className="d-flex justify-content-between">
                          <Label
                            fontSize="20px"
                            padding="0px 0px 0px 8px"
                            fontWeight={700}
                          >
                            Sentence (example)
                          </Label>
                        </div>
                        <VerticalSpace height={16} />

                        <div
                          className="col d-flex flex-column sentence-example align-itmes-center"
                          style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                        >
                          {pairs[item.id] &&
                            pairs[item.id].map((pair, index) => (
                              <div
                                key={index}
                                className="row sentence-row"
                                style={{
                                  flexWrap: "unset",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="col sentence-dropdown"
                                  style={{ flex: "1" }}
                                >
                                  <NestedMenu
                                    data={
                                      pair.dropdownValue
                                        ? [pair.dropdownValue!]
                                        : []
                                    }
                                    onSelectItem={(value) =>
                                      handleDropdownChange(
                                        item.id,
                                        index,
                                        value as DropdownValue
                                      )
                                    }
                                    borderColor={
                                      validationErrors[item.id]?.[index]
                                        ?.dropdownValue
                                        ? "#dc3545"
                                        : "#D9D9D9"
                                    }
                                  />
                                  <div
                                    className="p-0 delete-icon"
                                    style={{
                                      cursor: "pointer",
                                      width: "fit-content",
                                    }}
                                    onClick={() =>
                                      handleDeleteSentenceId(
                                        item.id,
                                        index,
                                        pair.id
                                      )
                                    }
                                  >
                                    <img
                                      src={deleteIcon}
                                      style={{
                                        WebkitUserSelect: "none",
                                        userSelect: "none",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col sentence-input">
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type="text"
                                      value={pair.inputValue || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          index,
                                          e.target.value
                                        )
                                      }
                                      className={`form-control ${
                                        validationErrors[item.id]?.[index]
                                          ?.inputValue
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder=""
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        fontSize: "14px",
                                        textDecoration: pair.isInputChecked
                                          ? "line-through"
                                          : "none",
                                      }}
                                    />

                                    {pair.showCheckbox && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          position: "absolute",
                                          right: "10px",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                        }}
                                      >
                                        <CustomCheckbox
                                          checked={!!pair.isInputChecked}
                                          onChange={() =>
                                            handleCheckboxChange(item.id, index)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="p-0 delete-button"
                                  style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  onClick={() =>
                                    handleDeleteSentenceId(
                                      item.id,
                                      index,
                                      pair.id
                                    )
                                  }
                                >
                                  <img
                                    src={deleteIcon}
                                    style={{
                                      WebkitUserSelect: "none",
                                      userSelect: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <VerticalSpace height={16} />
                        <button
                          type="button"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 20px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() => addNewPair(item.id)}
                        >
                          Add new pair
                        </button>
                        <VerticalSpace height={34} />
                        <div>
                          <Label
                            fontSize="20px"
                            padding="0px 0px 0px 8px"
                            fontWeight={700}
                          >
                            Footer (sentence)
                          </Label>

                          <VerticalSpace height={16} />
                          <div
                            className="d-flex header-input align-items-center"
                            style={{ gap: "10px" }}
                            // style={{ padding: "0px 42px 0px 0px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="inputthemes"
                              placeholder=""
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              value={footer[item.id] || ""}
                              onChange={(e) =>
                                handleFooterChange(item.id, e.target.value)
                              }
                            />
                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              // onClick={() => handleDeleteSubTheme(subtheme.id)}
                            >
                              {/* <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
              {item.type === "conjugation" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={selectedOption[item.id] === "Light"}
                              onChange={() =>
                                handleOptionChange(item.id, "Light")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <HorizontalSpace width={20} />
                            <RadioButton
                              checked={selectedOption[item.id] === "Dark"}
                              onChange={() =>
                                handleOptionChange(item.id, "Dark")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                          </div>
                        </div>
                        <VerticalSpace height={24} />
                        <div className="d-flex justify-content-between">
                          <Label fontSize="20px" fontWeight={700}>
                            Verb
                          </Label>
                        </div>
                        <VerticalSpace height={16} />
                        <div
                          className="d-flex align-items-center verb-title"
                          style={{ padding: "0px 28px 0px 0px" }}
                        >
                          <RowContainer
                            width="20%"
                            alignItem="center"
                            className="static-type"
                          >
                            <ParticlesType
                              width="32px"
                              height="24px"
                              padding="3.5px 10px"
                              bgColor="#37176C"
                              borderRadius="4px 20px 20px 4px"
                            />
                            <Label
                              fontSize="14px"
                              color="#000"
                              fontWeight={400}
                            >
                              Infinitive verb
                            </Label>
                          </RowContainer>
                          <input
                            type="text"
                            // className="form-control"
                            className={`form-control ${
                              errors[item.id] ? "is-invalid" : ""
                            }`}
                            id="inputthemes"
                            placeholder=""
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            value={infinitiveVerb[item.id] || ""}
                            onChange={(e) =>
                              handleInfinitiveVerbChange(
                                item.id,
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <VerticalSpace height={40} />
                        <div className="d-flex justify-content-between">
                          <Label fontSize="20px" fontWeight={700}>
                            Conjugation
                          </Label>
                        </div>
                        <VerticalSpace height={16} />
                        <div
                          className="col d-flex verb-items"
                          style={{ padding: "0px 58px 0px 0px", gap: "30px" }}
                        >
                          <div className="col-md-6 items">
                            {["ich", "du", "ersiees"].map((person) => (
                              <div key={person}>
                                <RowContainer>
                                  <Label
                                    fontSize="14px"
                                    width="18%"
                                    padding="0px 0px 0px 8px"
                                    fontWeight={700}
                                    marginTop="9px"
                                  >
                                    {person === "ersiees"
                                      ? "er, sie, es"
                                      : person}
                                  </Label>
                                  <div
                                    className="col-md-10 verb-input"
                                    style={{ gap: "8px", display: "grid" }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`input${person}1`}
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        fontSize: "14px",
                                      }}
                                      value={
                                        conjugations[item.id]?.[
                                          person as keyof Conjugations
                                        ][0] || ""
                                      }
                                      onChange={(e) =>
                                        handleConjugationInputChange(
                                          item.id,
                                          person as keyof Conjugations,
                                          0,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`input${person}2`}
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        fontSize: "14px",
                                      }}
                                      value={
                                        conjugations[item.id]?.[
                                          person as keyof Conjugations
                                        ][1] || ""
                                      }
                                      onChange={(e) =>
                                        handleConjugationInputChange(
                                          item.id,
                                          person as keyof Conjugations,
                                          1,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </RowContainer>
                                <VerticalSpace height={16} />
                              </div>
                            ))}
                          </div>

                          <div className="col-md-6 items">
                            {["wir", "ihr", "siesie"].map((person) => (
                              <div key={person}>
                                <RowContainer>
                                  <Label
                                    fontSize="14px"
                                    width="18%"
                                    padding="0px 0px 0px 8px"
                                    fontWeight={700}
                                    marginTop="9px"
                                  >
                                    {person === "siesie" ? "sie, Sie" : person}
                                  </Label>
                                  <div
                                    className="col-md-10 verb-input"
                                    style={{ gap: "8px", display: "grid" }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`input${person}1`}
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        fontSize: "14px",
                                      }}
                                      // value={
                                      //   conjugations[
                                      //     person as keyof Conjugations
                                      //   ][0]
                                      // }
                                      value={
                                        conjugations[item.id]?.[
                                          person as keyof Conjugations
                                        ][0] || ""
                                      }
                                      onChange={(e) =>
                                        handleConjugationInputChange(
                                          item.id,
                                          person as keyof Conjugations,
                                          0,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`input${person}2`}
                                      style={{
                                        borderRadius: "10px",
                                        height: "40px",
                                        fontSize: "14px",
                                      }}
                                      // value={
                                      //   conjugations[
                                      //     person as keyof Conjugations
                                      //   ][1]
                                      // }
                                      value={
                                        conjugations[item.id]?.[
                                          person as keyof Conjugations
                                        ][1] || ""
                                      }
                                      onChange={(e) =>
                                        handleConjugationInputChange(
                                          item.id,
                                          person as keyof Conjugations,
                                          1,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </RowContainer>
                                <VerticalSpace height={16} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <VerticalSpace height={40} />
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
              {item.type === "flashimage" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                        padding: "18px 20px",
                      }}
                    >
                      {!flashImage[item.id] && (
                        <div>
                          <VerticalSpace height={4} />
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              padding: "29px 29px 16px 29px",
                            }}
                          >
                            <header>
                              <h4>Upload a image jpg, png or svg</h4>
                            </header>

                            <input
                              type="file"
                              // ref={fileInputRef}
                              ref={(el) =>
                                (fileInputRefs.current[item.id] = el)
                              }
                              hidden
                              accept="image/*"
                              // onChange={handleFlashImageChange}
                              onChange={(e) =>
                                handleFlashImageChange(e, item.id)
                              }
                            />
                            <VerticalSpace height={20} />
                            <button
                              type="button"
                              className="btn btn-outline-secondary me-2 text-center"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",
                                borderRadius: "10px",
                                padding: "14px 30px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              onClick={() => handleChooseFileClick(item.id)}
                              // onClick={handleChooseFileClick}
                            >
                              Choose file
                            </button>
                            {errors[item.id]?.map((error, index) => (
                              <p
                                key={index}
                                className="text-danger"
                                style={{ marginTop: "10px" }}
                              >
                                {error}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}

                      {flashImage[item.id] && (
                        <div
                          className="drop_box"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {imageLoader[item.id] ? (
                            <Loader />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {typeof flashImage[item.id] === "string" ? (
                                <>
                                  <img
                                    src={flashImage[item.id] as string}
                                    alt="starting-lessons-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById(`fileInput-${item.id}`)
                                        ?.click()
                                    }
                                  />
                                  <input
                                    type="file"
                                    id={`fileInput-${item.id}`}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleFlashImageChange(e, item.id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    // src={URL.createObjectURL(flashImage[item.id])}
                                    src={
                                      flashImage[item.id]
                                        ? URL.createObjectURL(
                                            flashImage[item.id] as File
                                          )
                                        : ""
                                    }
                                    alt="flash"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById(`fileInput-${item.id}`)
                                        ?.click()
                                    }
                                  />
                                  <input
                                    type="file"
                                    id={`fileInput-${item.id}`}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleFlashImageChange(e, item.id)
                                    }
                                  />
                                </>
                              )}
                              {typeof flashImage[item.id] !== "string" &&
                              flashImage[item.id] ? (
                                <p
                                  style={{
                                    color: "#000000",
                                    marginTop: "0%",
                                    marginBottom: "0%",
                                  }}
                                >
                                  {(flashImage[item.id] as File).name}
                                </p>
                              ) : null}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
              {item.type === "grammarSingle" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <Label
                            fontSize="20px"
                            fontWeight={700}
                            padding="0px 0px 0px 10px"
                          >
                            Particle
                          </Label>
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={selectedOption[item.id] === "Light"}
                              // checked={item.lightTheme === "Light"}
                              onChange={() =>
                                handleOptionChange(item.id, "Light")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <HorizontalSpace width={20} />
                            <RadioButton
                              checked={selectedOption[item.id] === "Dark"}
                              // checked={item.lightTheme === "Dark"}
                              onChange={() =>
                                handleOptionChange(item.id, "Dark")
                              }
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <div
                          className="row sentence-row"
                          style={{ flexWrap: "unset", alignItems: "center" }}
                        >
                          <div
                            className="col sentence-dropdown"
                            style={{ flex: "1" }}
                          >
                            <NestedMenu
                              data={
                                item.particles?.dropdownValue
                                  ? [item.particles.dropdownValue]
                                  : []
                              }
                              onSelectItem={(value) =>
                                handleParticlesDropdownChange(
                                  item.id,
                                  value as DropdownValue
                                )
                              }
                              borderColor={
                                errors[item.id] ? "#dc3545" : "#D9D9D9"
                              }
                            />
                          </div>
                          <div className="col sentence-input">
                            <input
                              type="text"
                              value={item.particles?.inputValue || ""}
                              onChange={(e) =>
                                handleParticlesInputChange(
                                  item.id,
                                  e.target.value
                                )
                              }
                              className="form-control"
                              placeholder=""
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <Label
                          fontSize="20px"
                          fontWeight={700}
                          padding="0px 0px 0px 10px"
                        >
                          Description
                        </Label>
                        <VerticalSpace height={16} />
                        <div>
                          <TextEditor
                            onChange={(value) =>
                              handleDescriptionChange(item.id, value)
                            }
                            value={item.description}
                            lightTheme={
                              selectedOption[item.id] as "Light" | "Dark"
                            }
                            // lightTheme={item.lightTheme || "Light"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
            </div>
          ))}
          <VerticalSpace height={200} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 3,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default BretzelContentComponent;
