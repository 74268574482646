// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .question {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start !important;
    }
        .main-page {
            padding: 0% !important;
        }
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/question.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;QACtB,SAAS;QACT,kCAAkC;IACtC;QACI;YACI,sBAAsB;QAC1B;AACR","sourcesContent":["@media (max-width: 768px) {\n    .question {\n        flex-direction: column;\n        gap: 10px;\n        align-items: flex-start !important;\n    }\n        .main-page {\n            padding: 0% !important;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
