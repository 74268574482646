import React, { useState } from "react";
import Label from "../Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "../Common/RowContainer";
import Container from "../Common/Container";
import HorizontalSpace from "../HorizontalSpace";
import editIcon from "../../assets/editIcon.svg";
import styled from "styled-components";
import ViewIcon from "../../assets/Images/viewIcon.png";

interface Config {
  id: number;
  name: string;
  updatedAt: string;
  onEdit: (id: number) => void;
}

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ConfigList: React.FC<Config> = ({ id, name, updatedAt, onEdit }) => {
  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        margin="10px"
      >
        <RowContainer flex="0.5">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
          {/* <NewLabel fontSize="16px" fontWeight={400}>
            {id}
          </NewLabel> */}
        </RowContainer>
        <RowContainer flex="2.8">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
          <NewLabel fontSize="16px" fontWeight={400}>
            {id}
          </NewLabel>
        </RowContainer>
        <NewRowContainer flex="10">
          <NewLabel fontSize="16px" fontWeight={400}>
            {name}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="5">
          <NewLabel fontSize="16px" fontWeight={400} marginLeft="25px">
            {updatedAt}
          </NewLabel>
        </NewRowContainer>
        <RowContainer flex="1">
          <div style={{ cursor: "pointer" }}>
            <img
              src={editIcon}
              onClick={() => onEdit(id)}
              title="delete message"
              style={{ WebkitUserSelect: "none", userSelect: "none" }}
            />
          </div>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default ConfigList;
