// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .green-text {
    color: green;
}

.red-text {
    color: red;
} */
.container-fluid{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: {500};
    font-style: normal;
    font-variation-settings: "slnt" 0; */
    
}
/* style.css */

/* @font-face {
    font-family: 'Inter Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */`, "",{"version":3,"sources":["webpack://./src/assets/css/members.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;IACI,gCAAgC;IAChC,yBAAyB;IACzB;;wCAEoC;;AAExC;AACA,cAAc;;AAGd;;;;;;;GAOG","sourcesContent":["/* .green-text {\n    color: green;\n}\n\n.red-text {\n    color: red;\n} */\n.container-fluid{\n    font-family: \"Inter\", sans-serif;\n    font-optical-sizing: auto;\n    /* font-weight: {500};\n    font-style: normal;\n    font-variation-settings: \"slnt\" 0; */\n    \n}\n/* style.css */\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');\n\n/* @font-face {\n    font-family: 'Inter Variable';\n    font-style: normal;\n    font-display: swap;\n    font-weight: 100 900;\n    src: url(https://cdn.jsdelivr.net/fontsource/fonts/inter:vf@latest/latin-wght-normal.woff2) format('woff2-variations');\n    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
