import React, { memo, useCallback, useRef } from "react";
import JoditEditor from "jodit-react";
import "../../stylesheets/TextEditor.css";
import debounce from "lodash.debounce";

interface TextEditorProps {
  onChange: (value: string) => void;
  value?: string;
  lightTheme?: "Light" | "Dark";
  height?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  onChange,
  value = "",
  lightTheme = "Light",
  height = "100px",
}) => {
  const editorRef = useRef(null);

  // Editor configuration
  const config = {
    theme: lightTheme.toLowerCase(), // Jodit themes are 'light' or 'dark'
    height: height,
    readonly: false,
    disablePlugins: "add-new-line",
    toolbar: true,
    minHeight: "120px",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    allowResizeY: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    toolbarAdaptive: false,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "align",
      "|",
      "fontsize",
      "brush",
      "|",
      "undo",
      "redo",
    ],
  };

  const editorStyle: React.CSSProperties = {
    borderRadius: "12px",
    overflow: "hidden",
  };

  const debouncedOnChange = useCallback(
    debounce((newContent: string) => {
      if (typeof newContent === "string") {
        onChange(newContent);
      }
    }, 2200),
    [onChange]
  );
  return (
    <div style={editorStyle}>
      <JoditEditor
        ref={editorRef}
        value={value}
        config={config}
        onChange={debouncedOnChange}
      />
    </div>
  );
};

export default memo(TextEditor);
