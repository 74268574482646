import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import CustomDropdown from "../Common/CustomDropdown";

interface Messages {
  id: number;
  userId?: number;
  userFullName: string;
  userEmail: string;
  description: string;
  ratings: string;
  updatedAt: string;
}

const ViewMessage = () => {
  const { id } = useParams();
  const [message, setMessage] = useState<Messages>();
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMessagesById();
  }, []);
  
  useEffect(() => {
    if (textareaRef.current && message?.description) {
      // Auto resize textarea based on content
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message?.description]);

  const fetchMessagesById = async () => {
    setLoading(true);
    const response = await getRequest(
      `${baseURL}/ratings/get-app-ratings-by-id/${id}`
    );
    if (response?.data) {
      setMessage(response.data.data);
    }
    setLoading(false);
  };

  const handleBackClick = () => {
    navigate(`/messages`);
  };
  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Message view
            </h4>
            <VerticalSpace height={20} />
            <div className="row p-3 d-flex align-items-center justify-content-center">
              <div
                className="container col-10"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                  padding: "20px",
                }}
              >
                <VerticalSpace height={24} />
                <div className="col">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        UserId
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={message?.userId || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        User name
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={message?.userFullName || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        User email
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={message?.userEmail || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Ratings
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={message?.ratings || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Message
                      </Label>
                      <VerticalSpace height={4} />
                      <textarea
                        ref={textareaRef}
                        className="form-control"
                        data-kt-autosize="true"
                        value={message?.description || "N/A"}
                        style={{
                          resize: "none",
                          overflow: "hidden",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ViewMessage;
