import React, { useState, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButton from "../../../Common/RadioButton";
import HorizontalSpace from "../../../HorizontalSpace";
import ParticlesType from "../../../Common/ParticleType";
import RowContainer from "../../../Common/RowContainer";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import Loader from "../../../../utils/Loading";
import TextEditor from "../../../Common/TextEditor";

interface Conjugations {
  ich: string[];
  du: string[];
  ersiees: string[];
  wir: string[];
  ihr: string[];
  siesie: string[];
}

const ConjugationComponent = () => {
  const [selectedOption, setSelectedOption] = useState("Light");
  const [infinitiveVerb, setInfinitiveVerb] = useState("");
  const [conjugations, setConjugations] = useState<Conjugations>({
    ich: ["", ""],
    du: ["", ""],
    ersiees: ["", ""],
    wir: ["", ""],
    ihr: ["", ""],
    siesie: ["", ""],
  });
  const [conjugationId, setConjugationId] = useState("");
  const [infinitiveVerbError, setInfinitiveVerbError] = useState("");
  const [conjugationErrors, setConjugationErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  useEffect(() => {
    if (flashcardContentId) {
      fetchConjugationDetails(flashcardContentId);
    }
  }, [flashcardContentId]);

  const fetchConjugationDetails = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-conjugation/${flashcardContentId}`
      );

      const conjugationData = response?.data?.data;
      if (conjugationData) {
        setConjugationId(conjugationData.id || "");
        setInfinitiveVerb(conjugationData.particleValue || "");
        setSelectedOption(conjugationData.lightTheme || "Light");

        const updatedConjugations: Conjugations = {
          ich: ["", ""],
          du: ["", ""],
          ersiees: ["", ""],
          wir: ["", ""],
          ihr: ["", ""],
          siesie: ["", ""],
        };

        conjugationData.conjugation.forEach((conj: any) => {
          const transformedKey = transformKey(conj.type);
          if (transformedKey in updatedConjugations) {
            updatedConjugations[transformedKey as keyof Conjugations] = [
              conj.value,
              conj.translation,
            ];
          }
        });

        setConjugations(updatedConjugations);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching conjugation details:", error);
    }
  };

  const transformKey = (key: string) => {
    switch (key) {
      case "er/sie/es":
        return "ersiees";
      case "sie/sie":
        return "siesie";
      default:
        return key;
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    person: keyof Conjugations,
    index: number
  ) => {
    const value = typeof e === "string" ? e : e.target.value;
    if (value){
      setConjugations((prevConjugations) => ({
        ...prevConjugations,
        [person]: prevConjugations[person].map((conjugation, i) =>
          i === index ? value : conjugation
        ),
      }));
    }
  };

  const validateInfinitiveVerb = () => {
    if (infinitiveVerb === "") {
      setInfinitiveVerbError("Infinitive verb cannot be empty");
      return false;
    } else {
      setInfinitiveVerbError("");
      return true;
    }
  };

  // const validateConjugations = () => {
  //   let isValid = true;
  //   Object.entries(conjugations).forEach(([key, values]) => {
  //     if (values.some((value: any) => value === "")) {
  //       setConjugationErrors(`Conjugation for ${key} cannot be empty`);
  //       isValid = false;
  //     }
  //   });
  //   return isValid;
  // };

  const handleSave = async () => {
    if (!validateInfinitiveVerb()) {
      return;
    }
    const conjugationData = Object.entries(conjugations).map(([key, value]) => {
      let transformedKey = key;
      if (key === "ersiees") {
        transformedKey = "er/sie/es";
      } else if (key === "siesie") {
        transformedKey = "sie/sie";
      }
      return {
        type: transformedKey,
        value: value[0],
        translation: value[1],
      };
    });

    const data = {
      flashCardContentComponentId: flashcardContentId,
      lightTheme: selectedOption,
      particleType: "infinitiveVerb",
      particleValue: infinitiveVerb,
      conjugation: conjugationData,
    };

    let response;
    if (conjugationId) {
      setIsLoading(true);
      response = await postRequest(
        `${baseURL}/lesson/update-conjugation/${conjugationId}`,
        data
      );
      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
    } else {
      setIsLoading(true);
      response = await postRequest(
        `${baseURL}/lesson/create-conjugation`,
        data
      );
      setIsLoading(false);
    }
  };

  const handleTextEditorLoad = () => {
    setEditorLoaded(true);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <div className="p-3 main-page">
        <div
          className="d-flex  align-items-center"
          onClick={handleBackClick}
          style={{ width: "fit-content" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="me-2"
            style={{
              backgroundColor: "#D9D9D9",
              padding: "7px",
              borderRadius: "8px",
              color: "#000",
              cursor: "pointer",
            }}
          />
          <label
            style={{
              color: "#FF9E00",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Back
          </label>
        </div>
        <h4
          style={{
            textAlign: "center",
            margin: "auto",
            color: "#7542E4",
            fontWeight: "bold",
          }}
        >
          Conjugation
        </h4>
        <VerticalSpace height={30} />
        <div
          className="container block-text-container"
          style={{ maxWidth: "1117px" }}
        >
          <div
            className="container p-4"
            style={{
              backgroundColor: "rgb(220 220 220 / 66%)",
              borderRadius: "30px",
            }}
          >
            <div className="col-12">
              <div className="d-flex justify-content-end">
                <div className="d-flex" style={{ gap: "8px" }}>
                  <RadioButton
                    checked={selectedOption === "Light"}
                    onChange={() => handleOptionChange("Light")}
                  />
                  <Label fontSize="14px" fontWeight={400}>
                    light
                  </Label>
                  <HorizontalSpace width={20} />
                  <RadioButton
                    checked={selectedOption === "Dark"}
                    onChange={() => handleOptionChange("Dark")}
                  />
                  <Label fontSize="14px" fontWeight={400}>
                    dark
                  </Label>
                </div>
              </div>
              <VerticalSpace height={24} />
              <div className="d-flex justify-content-between">
                <Label fontSize="20px" fontWeight={700}>
                  Verb
                </Label>
              </div>
              <VerticalSpace height={16} />
              <div
                className="d-flex align-items-center verb-title"
                style={{ padding: "0px 28px 0px 0px" }}
              >
                <RowContainer
                  width="20%"
                  alignItem="center"
                  className="static-type"
                >
                  <ParticlesType
                    width="32px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#37176C"
                    borderRadius="4px 20px 20px 4px"
                  />
                  <Label fontSize="14px" color="#000" fontWeight={400}>
                    Infinitive verb
                  </Label>
                </RowContainer>
                <RowContainer flexDirection="column">
                  <input
                    type="text"
                    className={`form-control ${
                      infinitiveVerbError && "is-invalid"
                    }`}
                    id="inputthemes"
                    placeholder=""
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                    value={infinitiveVerb}
                    onChange={(e) => {
                      setInfinitiveVerb(e.target.value);
                      setInfinitiveVerbError("");
                    }}
                  />
                  {infinitiveVerbError && (
                    <div className="text-danger" style={{ marginLeft: "10px" }}>
                      {infinitiveVerbError}
                    </div>
                  )}
                </RowContainer>
              </div>

              <VerticalSpace height={40} />
              <div className="d-flex justify-content-between">
                <Label fontSize="20px" fontWeight={700}>
                  Conjugation
                </Label>
              </div>
              <VerticalSpace height={16} />
              <div
                className="col d-flex verb-items"
                style={{ padding: "0px 58px 0px 0px", gap: "30px" }}
              >
                <div className="col-md-6 items">
                  {["ich", "du", "ersiees"].map((person) => (
                    <div key={person}>
                      <RowContainer>
                        <Label
                          fontSize="14px"
                          width="18%"
                          padding="0px 0px 0px 8px"
                          fontWeight={700}
                          marginTop="9px"
                        >
                          {person === "ersiees" ? "er, sie, es" : person}
                        </Label>
                        <div
                          className="col-md-10 verb-input"
                          style={{ gap: "8px", display: "grid" }}
                        >
                          <TextEditor
                            value={
                              conjugations[person as keyof Conjugations][0]
                            }
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                person as keyof Conjugations,
                                0
                              );
                            }}
                            height="120px"
                            lightTheme={selectedOption as "Light" | "Dark"}
                          />
                          <TextEditor
                            value={
                              conjugations[person as keyof Conjugations][1]
                            }
                            onChange={(e) => {
                              handleInputChange(
                                e,
                                person as keyof Conjugations,
                                1
                              );
                            }}
                            height="120px"
                            lightTheme={selectedOption as "Light" | "Dark"}
                          />
                        </div>
                      </RowContainer>
                      <VerticalSpace height={16} />
                    </div>
                  ))}
                </div>

                <div className="col-md-6 items">
                  {["wir", "ihr", "siesie"].map((person) => (
                    <div key={person}>
                      <RowContainer>
                        <Label
                          fontSize="14px"
                          width="18%"
                          padding="0px 0px 0px 8px"
                          fontWeight={700}
                          marginTop="9px"
                        >
                          {person === "siesie" ? "sie, Sie" : person}
                        </Label>
                        <div
                          className="col-md-10 verb-input"
                          style={{ gap: "8px", display: "grid" }}
                        >
                          <TextEditor
                            value={
                              conjugations[person as keyof Conjugations][0]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                person as keyof Conjugations,
                                0
                              )
                            }
                            height="120px"
                            lightTheme={selectedOption as "Light" | "Dark"}
                          />
                          <TextEditor
                            value={
                              conjugations[person as keyof Conjugations][1]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                person as keyof Conjugations,
                                1
                              )
                            }
                            height="120px"
                            lightTheme={selectedOption as "Light" | "Dark"}
                          />
                        </div>
                      </RowContainer>
                      <VerticalSpace height={16} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <VerticalSpace height={40} />
          </div>
        </div>
        <VerticalSpace height={240} />
        <div
          className="container row d-flex justify-content-center publish-button"
          style={{
            position: "fixed",
            zIndex: 999,
            height: "12%",
            maxWidth: "100%",
            bottom: "0px",
            background:
              "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
          }}
        >
          <div
            className="d-flex justify-content-center"
            style={{
              position: "fixed",
              zIndex: 4,
              bottom: "34px",
            }}
          >
            <button
              type="button"
              className="btn btn-success d-flex justify-content-center"
              style={{
                backgroundColor: "#FF9E00",
                color: "#100F15",
                height: "fit-content",
                borderRadius: "10px",
                padding: "10px 20%",
                width: "400px",
                fontSize: "14px",
              }}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConjugationComponent;
