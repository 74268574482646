import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HorizontalSpace from "../HorizontalSpace";
import "../../assets/css/createlessons.css";
import VerticalSpace from "../VerticalSpace";
import deleteIcon from "../../assets/deleteIcon.svg";
import OptionCheckbox from "../Common/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import CustomDropdown from "../Common/CustomDropdown";
import LessonsDropdown from "../LessonDropdwon/lessonsDropdwon";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import "../../stylesheets/theme.css";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../Common/Toast";
import RightSideArrow from "../../assets/RightSideArrow.svg";
import RadioButton from "../Common/RadioButton";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
import '../../stylesheets/common.css'

interface Exercise {
  id?: number;
  inputValue: string;
  selected: boolean;
  order: number;
}

interface Option {
  id?: number;
  inputValue: string;
  selected: boolean;
  order: number;
}

interface Theme {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  themes: Theme[];
}

interface Level {
  level?: string;
  lessons: Lesson[];
}

const MultipleOptions = () => {
  const { id } = useParams();
  const [lessonsData, setLessonsData] = useState<Level[]>([]);
  const [multipleOptionId, setMultipleOptionId] = useState("");
  const [points, setPoints] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [exercise, setExercise] = useState<Exercise[]>([]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState<number | null>(
    null
  );
  const [level, setLevel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    lesson: ""
  });
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => { });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);

  const [validationError, setValidationError] = useState<{
    options: {
      [key: number]: {
        inputValue?: string;
      };
    };
    exercise: {
      [key: number]: {
        inputValue?: string;
      };
    };
  }>({
    options: {},
    exercise: {},
  });


  const dropdownItems: string[] = ["0 pts", "1 pts", "2 pts", "3 pts"];

  const navigate = useNavigate();

  useEffect(() => {
    fetchMultipleOptionData();
  }, []);

  const fetchMultipleOptionData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (id) {
        response = await getRequest(
          `${baseURL}/multiple-option-challenge/get-multiple-option-challenge-by-id/${id}`
        );
      } else {
        setIsLoading(false);
        return;
      }

      if (response?.data.success) {
        const updatedMultipleOptionData =
          response.data.data.multipleOptionsChallenge;
        const exercises = response.data.data.exerciseChallenge.filter(
          (item: any) => item.type === "exercise"
        );
        const answers = response.data.data.exerciseChallenge.filter(
          (item: any) => item.type === "answers"
        );

        const themeName = await fetchThemeById(
          updatedMultipleOptionData.themeId
        );
        setLessonsData([
          {
            lessons: [
              {
                id: updatedMultipleOptionData.themeId,
                name: themeName.name,
                themes: [],
              }
            ]
          },
        ]);
        setLevel(themeName.lessonsLevel);
        setPoints(`${updatedMultipleOptionData.points} pts`);
        const fetchedExercises = exercises.map((item: any, index: number) => ({
          id: item.id,
          selected: item.isMark,
          inputValue: item.value,
          order: item.order || index + 1,
        }));
        const fetchedOptions = answers.map((item: any, index: number) => ({
          id: item.id,
          selected: item.isMark,
          inputValue: item.value,
          order: item.order || index + 1,
        }));
        setExercise(fetchedExercises);
        setOptions(fetchedOptions);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSelectItem = (item: Lesson) => {
    setLessonsData([{ lessons: [item] }]);
    setErrors({
      ...errors,
      lesson: item ? "" : "Please select a lesson.",
    });
  };

  const addNewExercise = () => {
    const newOrder = exercise.length + 1;

    setExercise([
      ...exercise,
      { inputValue: "", selected: false, order: newOrder },
    ]);
  };

  const handleInputExercise = (index: number, value: string) => {
    const updatedExercise = [...exercise];
    updatedExercise[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.exercise[index]) {
      updatedErrors.exercise[index].inputValue = "";
    } else {
      updatedErrors.exercise[index] = { inputValue: "" };
    }
    setExercise(updatedExercise);
    setValidationError(updatedErrors);
  };

  const handleCheckboxExercise = (index: number) => {
    setExercise(
      exercise.map((exercise, i) => {
        if (i === index) {
          return { ...exercise, selected: !exercise.selected };
        }
        return exercise;
      })
    );
    setCorrectOptionIndex(index);
  };

  const removeExercise = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "multipleOptionExerciseChallenge",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedExercises = [...exercise];
          updatedExercises.splice(index, 1);
          setExercise(updatedExercises);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting exercise:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting exercise:", error);
      }
    } else {
      const updatedExercises = [...exercise];
      updatedExercises.splice(index, 1);
      setExercise(updatedExercises);

      const updatedErrors = { ...validationError };
      if (updatedErrors.exercise) {
        delete updatedErrors.exercise[index];
      }
      setValidationError(updatedErrors);
    }
  };

  const handleDeleteExercise = (index: number, id?: number) => {
    if (id !== undefined) {
      setDeleteDialogMessage("Are you sure you want to delete this exercise?");
      setOnConfirmDelete(() => () => removeExercise(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removeExercise(index);
    }
  };

  const addNewField = () => {
    const newOrder = options.length + 1;
    setOptions([
      ...options,
      { inputValue: "", selected: false, order: newOrder },
    ]);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.options[index]) {
      updatedErrors.options[index].inputValue = "";
    } else {
      updatedErrors.options[index] = { inputValue: "" };
    }
    setOptions(updatedOptions);
    setValidationError(updatedErrors);
  };

  const handleCheckboxChange = (index: number) => {
    setOptions(
      options.map((options, i) => {
        if (i === index) {
          return { ...options, selected: !options.selected };
        }
        return options;
      })
    );
    setCorrectOptionIndex(index);
  };

  const removeOptions = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "multipleOptionExerciseChallenge",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedOptions = [...options];
          updatedOptions.splice(index, 1);
          setOptions(updatedOptions);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting options:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting options:", error);
      }
    } else {
      const updatedOptions = [...options];
      updatedOptions.splice(index, 1);
      setOptions(updatedOptions);

      const updatedErrors = { ...validationError };
      if (updatedErrors.options) {
        delete updatedErrors.options[index];
      }
      setValidationError(updatedErrors);
    }
  };

  const handleDeletOptions = (index: number, id?: number) => {
    if (id !== undefined) {
      setDeleteDialogMessage("Are you sure you want to delete this options?");
      setOnConfirmDelete(() => () => removeOptions(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removeOptions(index);
    }
  };


  const handleSave = async () => {
    let hasError = false;

    const validationErrors = {
      lesson: !lessonsData.length ? "Please select a lesson." : "",
    };

    setErrors(validationErrors);

    if (exercise.length === 0) {
      Toast("Please add at least one exercise.", "error");
      return;
    }

    if (options.length === 0) {
      Toast("Please add at least one option.", "error");
      return;
    }

    const newErrorsExercise: {
      [key: number]: { inputValue?: string };
    } = {};
    const newErrorsOptions: {
      [key: number]: { inputValue?: string };
    } = {};
    exercise.forEach((item, index) => {
      newErrorsExercise[index] = {};
      if (!item.inputValue) {
        newErrorsExercise[index].inputValue = "required";
        hasError = true;
      }
    }
    );
    options.forEach((item, index) => {
      newErrorsOptions[index] = {};
      if (!item.inputValue) {
        newErrorsOptions[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      exercise: newErrorsExercise,
      options: newErrorsOptions,
    }));

    if (hasError) {
      return;
    }
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    const exerciseChallenges = exercise.map((exercise) => ({
      id: exercise.id,
      type: "exercise",
      value: exercise.inputValue,
      isMark: exercise.selected,
      order: exercise.order,
    }));

    const exerciseOptions = options.map((options) => ({
      id: options.id,
      type: "answers",
      value: options.inputValue,
      isMark: options.selected,
      order: options.order,
    }));

    const firstLesson = lessonsData[0];

    const themeId = firstLesson.lessons[0].id;

    const requestData = {
      themeId: themeId,
      points: 1,
      exerciseChallenges: [...exerciseChallenges, ...exerciseOptions],
    };

    try {
      setIsLoading(true);
      let response;
      const updateId = multipleOptionId || id;
      if (updateId) {
        response = await postRequest(
          `${baseURL}/multiple-option-challenge/update-multiple-option-challenge/${updateId}`,
          requestData
        );
      } else {
        response = await postRequest(
          `${baseURL}/multiple-option-challenge/create-multiple-option-challenge`,
          requestData
        );
      }

      if (response?.data.success) {
        const updatedMultipleOptionData =
          response.data.data.multipleOptionChallenge;
        const exercises = response.data.data.exercise.filter(
          (item: any) => item.type === "exercise"
        );
        const answers = response.data.data.exercise.filter(
          (item: any) => item.type === "answers"
        );
        setMultipleOptionId(updatedMultipleOptionData.id);
        setPoints(`${updatedMultipleOptionData.points} pts`);
        const fetchedExercises = exercises.map((item: any, index: number) => ({
          id: item.id,
          selected: item.isMark,
          inputValue: item.value,
          order: item.order || index + 1,
        }));
        const fetchedOptions = answers.map((item: any, index: number) => ({
          id: item.id,
          selected: item.isMark,
          inputValue: item.value,
          order: item.order || index + 1,
        }));
        setExercise(fetchedExercises);
        setOptions(fetchedOptions);
      }
      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error handling question:", error);
    }
  };

  const fetchThemeById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/notification-challenge/get-themename-by-id/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setPairToDelete(null);
  };

  const handleBackClick = async () => {
    navigate(`/multiple-options`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });

  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <VerticalSpace height={10} />
          <h4
          className="row-container"
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Add a challenge
          </h4>
          <VerticalSpace height={30} />
          <div
            className="container"
            style={{
              borderRadius: "30px",
              maxWidth: "1117px",
              backgroundColor: "#d9d9d980",
              padding: "0px",
            }}
          >
            <div
              className="col-12"
              style={{
                borderRadius: "30px 30px 0px 0px",
                backgroundColor: "#d9d9d9",
                padding: '30px'
              }}
            >
              {level ? (
                <RowContainer alignItem="center" justifyContent="end" margin="0px 0px 20px 0px">
                  <RadioButton checked={true} />
                  <Label fontSize="14px" fontWeight={400}>
                    {level}
                  </Label>
                </RowContainer>
              ) : null}
              <RowContainer alignItem="center" className="textFields-container">
                <div className="col-12" style={{ flex: "unset" }}>
                  <LessonsDropdown
                    data={lessonsData as []}
                    onSelectItem={(item) => handleSelectItem(item as Lesson)}
                    borderColor={errors.lesson ? "#dc3545" : "#D9D9D9"}
                  />
                </div>
                <RowContainer alignItem="center" width="60px">
                  <Label fontSize="18px" fontWeight={400}>
                    1 pts
                  </Label>
                  <img src={RightSideArrow} alt="arrow" />
                </RowContainer>
              </RowContainer>
            </div>
            <RowContainer className="exercise-answers-container" padding="30px">
              <RowContainer
                className="exercise-answers"
                justifyContent="space-between"
              >
                <div className="exercise">
                  <Label
                    fontSize="20px"
                    padding="0px 0px 0px 8px"
                    fontWeight={700}
                  >
                    Exercise
                  </Label>
                  <VerticalSpace height={10} />
                  <div className="align-items-center">
                    <div style={{ flexDirection: "column" }}>
                      {exercise.map((exercise, index) => (
                        <RowContainer
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter exerice"
                            className={`form-control ${validationError.exercise[index]?.inputValue
                                ? "is-invalid"
                                : ""
                              }`}
                            value={exercise.inputValue}
                            onChange={(e) =>
                              handleInputExercise(index, e.target.value)
                            }
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                          />
                          <OptionCheckbox
                            checked={exercise.selected}
                            onChange={() => handleCheckboxExercise(index)}
                          />
                          <div style={{ cursor: "pointer" }}>
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              onClick={() =>
                                handleDeleteExercise(index, exercise.id)
                              }
                            />
                          </div>
                        </RowContainer>
                      ))}
                      <VerticalSpace height={20} />

                      <button
                        type="button"
                        className="user"
                        style={{
                          color: "#100F15",
                          background: "none",
                          border: "1px solid #100F15",
                          borderRadius: "10px",
                          padding: "14px 20px",
                          fontSize: "14px",
                          width: "auto",
                        }}
                        onClick={addNewExercise}
                      >
                        Add new field
                      </button>
                    </div>
                  </div>
                </div>
                <div className="answers">
                  <Label
                    fontSize="20px"
                    padding="0px 0px 0px 8px"
                    fontWeight={700}
                  >
                    Answers
                  </Label>
                  <VerticalSpace height={10} />
                  <div className="align-items-center">
                    <div style={{ flexDirection: "column" }}>
                      {options.map((options, index) => (
                        <RowContainer
                          key={options.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter option"
                            className={`form-control ${validationError.options[index]?.inputValue
                                ? "is-invalid"
                                : ""
                              }`}
                            value={options.inputValue}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                          />
                          <OptionCheckbox
                            checked={options.selected}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          <div style={{ cursor: "pointer" }}>
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              onClick={() =>
                                handleDeletOptions(index, options.id)
                              }
                            />
                          </div>
                        </RowContainer>
                      ))}
                      <VerticalSpace height={20} />

                      <button
                        type="button"
                        className="user"
                        style={{
                          color: "#100F15",
                          background: "none",
                          border: "1px solid #100F15",
                          borderRadius: "10px",
                          padding: "14px 20px",
                          fontSize: "14px",
                          width: "auto",
                        }}
                        onClick={addNewField}
                      >
                        Add new field
                      </button>
                    </div>
                  </div>
                </div>
              </RowContainer>
            </RowContainer>
          </div>
          <VerticalSpace height={250} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default MultipleOptions;
