// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: #F8F8F8;
}

.content-wrapper{
    display: flex;
    flex-direction: column;
}
/* .content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 1;
} */

.content-wrappers{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #F8F8F8;
}



.content-wrapper .content {
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

 .sidebar.visible {
     display: block;
     transform: translateX(0);
     position: absolute;
 }
/* .content {
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
} */

.content>.navbar {
    flex-shrink: 0;
}

.content>.main-content {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #F8F8F8;
    padding: 20px;
    /* Add padding for content */
}

.container-fluid{
    height: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9991;
}

.sidebar.visible {
    z-index: 9999;
}


@media (max-width: 768px) {
   .content-wrappers {
        margin-top: 25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;;;;;;;GAOG;;AAEH;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;AAC7B;;;;AAIA;IACI,cAAc;IACd,eAAe;IACf,WAAW;IACX,aAAa;AACjB;;CAEC;KACI,cAAc;KACd,wBAAwB;KACxB,kBAAkB;CACtB;AACD;;;;GAIG;;AAEH;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;;AAGA;GACG;QACK,gBAAgB;IACpB;AACJ","sourcesContent":[".main-layout {\n    display: flex;\n    height: 100vh;\n    overflow: hidden;\n    background-color: #F8F8F8;\n}\n\n.content-wrapper{\n    display: flex;\n    flex-direction: column;\n}\n/* .content-wrapper {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    position: relative;\n    z-index: 1;\n} */\n\n.content-wrappers{\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n    background-color: #F8F8F8;\n}\n\n\n\n.content-wrapper .content {\n    flex-shrink: 0;\n    position: fixed;\n    width: 100%;\n    z-index: 1000;\n}\n\n .sidebar.visible {\n     display: block;\n     transform: translateX(0);\n     position: absolute;\n }\n/* .content {\n    flex-grow: 1;\n    display: flex;\n    overflow-y: auto;\n} */\n\n.content>.navbar {\n    flex-shrink: 0;\n}\n\n.content>.main-content {\n    flex-grow: 1;\n    overflow-y: auto;\n    background-color: #F8F8F8;\n    padding: 20px;\n    /* Add padding for content */\n}\n\n.container-fluid{\n    height: 100%;\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 9991;\n}\n\n.sidebar.visible {\n    z-index: 9999;\n}\n\n\n@media (max-width: 768px) {\n   .content-wrappers {\n        margin-top: 25px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
