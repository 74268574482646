import React from "react";
import PrivacyPolicyContent from "../../Components/PrivacyAndPolicy/privacy-policy";
import FaqsContent from "../../Components/PrivacyAndPolicy/faqs-content";
import UserDataContent from "../../Components/PrivacyAndPolicy/user-data";

const UserData = () =>{
    return (
        <UserDataContent />
    )
}

const PrivacyPolicy = () => {
  return (
      <PrivacyPolicyContent />
  );
};

const Faqs = () => {
  return <FaqsContent />;
};


export { UserData,PrivacyPolicy, Faqs };
