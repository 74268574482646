import React, { useState, useEffect } from "react";
import user from "../assets/Images/logo/user.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass,faBars } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/header.css";
import { getToken } from "../services/authService";
import RowContainer from "../Components/Common/RowContainer";
import HorizontalSpace from "../Components/HorizontalSpace";
import { SidebarContext } from "../hooks/UseSidebar";


interface User {
  fullName?: string;
  uId?: string;
  status?: string;
}

const Header: React.FC<User> = () => {
  const [userName, setUserName] = useState<string | null>(null);
  const { toggleSide } = React.useContext(SidebarContext);

  useEffect(() => {
    const userData = getToken();
    if (userData && userData.fullName) {
      setUserName(userData.fullName);
    }
  }, []);

   const showSideBar = () => {
     toggleSide();
   };

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <RowContainer justifyContent="space-between" alignItem="center">
          <RowContainer alignItem="center">
            <FontAwesomeIcon
              icon={faBars}
              className="slide-button"
              style={{
                // backgroundColor: "#D9D9D9",
                // padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={showSideBar}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              className="div-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              style={{
                backgroundColor: "transparent",
                border: "0px",
                fontSize: "14px",
                // width: "170px",
                // fontWeight: 400,
                color: "black",
                opacity: "40%",
              }}
            />
          </RowContainer>
          <RowContainer gap="0px" alignItem="center" justifyContent="flex-end">
            <span
              style={{
                backgroundColor: "#009BFF",
                borderRadius: "4px",
                padding: "5px",
                height: "28px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              Hello
            </span>
            <span
              style={{
                backgroundColor: "#592ACB",
                borderRadius: "4px 20px 20px 4px",
                padding: "5px 10px",
                height: "28px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              {userName || "Antonia"}
            </span>
            <HorizontalSpace width={2} />
            <img src={user} alt="User" style={{ borderRadius: "7px" }} />
          </RowContainer>
        </RowContainer>
      </div>
    </nav>
  );
};

export default Header;
