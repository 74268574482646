import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface Theme {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  themes?: Theme[];
}

interface Level {
  level: string;
  lessons: Lesson[];
}

interface LessonsState {
  data: Level[];
  loading: boolean;
  error: string | null;
}

const initialState: LessonsState = {
  data: [],
  loading: false,
  error: null,
};

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    setDropDown: (state, action: PayloadAction<Level[]>) => {
      localStorage.setItem('lessonsdata',JSON.stringify(action.payload))
      state.data = action.payload;
    },
    getDropDown : (state) => {
      const data = localStorage.getItem('lessonsdata')
      if(data){
        state.data = JSON.parse(data);
      }
    },
    removeDropDown: ()=>{
      localStorage.removeItem('lessonsdata');
    }
  }
});

export const selectLessonsData = (state: RootState) => state.lessons.data;

export const { setDropDown,getDropDown,removeDropDown } = lessonsSlice.actions;

export default lessonsSlice.reducer;
