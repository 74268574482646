import React from "react";

// Define a type for the date format function
type DateFormatFunction = (dateString: string) => string;

// Date format function
const formatDate: DateFormatFunction = (dateString) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};

// // Date Format component
// const DateFormat: React.FC<{ dateString: string }> = ({ dateString }) => {
//   const formattedDate = formatDate(dateString);
//   return <>{formattedDate}</>;
// };

// // Usage example
// const ExampleComponent: React.FC = () => {
//   return <DateFormat dateString="2024-04-19T12:18:01.417Z" />;
// };

export default formatDate;
