import React, { useState } from "react";
import Label from "./Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import HorizontalSpace from "./HorizontalSpace";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import CustomCheckbox from "./Common/CustomCheckbox";
import Switch from "./Common/Switch";

interface Particle {
  id: number;
  name: string;
  latestdate: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  status: boolean;
  onStatusChange: (id: number) => void;
}

const ParticleList: React.FC<Particle> = ({
  id,
  name,
  latestdate,
  // active,
  onEdit,
  onDelete,
  onCheckboxChange,
  isChecked,
  status,
  onStatusChange,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);

  const toggleStatus = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
  };

  const handleDelete = () => {
    onDelete(id); // Call the delete function with the ID of the challenge
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        // gap="3.6px"
        margin="10px"
      >
        <RowContainer flex="1.5">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
        </RowContainer>
        <RowContainer flex="25" gap="0px">
          <Label fontSize="16px" fontWeight={400}>
            {name}
          </Label>
        </RowContainer>
        <RowContainer flex="13">
          <Label fontSize="16px" fontWeight={400}>
            {latestdate}
          </Label>
        </RowContainer>
        <RowContainer flex="3.5">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "10px" }}
          >
            <Switch isOn={isSwitchOn} onToggle={toggleStatus} />
            {/* <Switch isOn={apiCallStatus} onToggle={toggleSwitch} /> */}
            <div style={{ cursor: "pointer" }}>
              <img
                src={editIcon}
                onClick={() => onEdit(id)}
                style={{ WebkitUserSelect: "none", userSelect: "none" }}
              />
            </div>
            {/* <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} alt="Delete" style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div> */}
          </div>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default ParticleList;
