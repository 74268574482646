import React, { useState, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButton from "../../../Common/RadioButton";
import HorizontalSpace from "../../../HorizontalSpace";
import TextEditor from "../../../Common/TextEditor";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import Loader from "../../../../utils/Loading";
import gsap from 'gsap';
import '../../../../stylesheets/common.css'

interface BlockText {
  id: number;
  name: string;
}

const BlockText = () => {
  const [description, setDescription] = useState("");
  const [lightTheme, setSelectedOption] = useState("Light");
  const [blockTextId, setblockTextId] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId: flashcardContentId } = location.state || {};
    useEffect(() => {
      if (flashcardContentId) {
        fetchBlockTextDetails(flashcardContentId);
      }
    }, [flashcardContentId]);

  const fetchBlockTextDetails = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-blocktext/${flashcardContentId}`
      );
      const blockTextData = response?.data?.data;
      if (blockTextData) {
        setblockTextId(blockTextData.id || "");
        setDescription(blockTextData.description || "");
        setSelectedOption(blockTextData.lightTheme || "Light");
      }
       setIsLoading(false);
    } catch (error) {
      console.error("Error fetching block text details:", error);
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    if (value !== "") {
      setError("");
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (description === "") {
      Toast("BlockText cannot be empty","error");
      isValid = false;
    } else {
      setError("");
    }
    return isValid;
  };

  const handleSave = async () => {
     if (!validateForm()) {
       return;
     }

    const data = {
      flashCardContentComponentId: flashcardContentId,
      description,
      lightTheme,
    };

    try {
      let response;
      if(blockTextId){
         setIsLoading(true);
        response = await postRequest(
          `${baseURL}/lesson/update-blocktext/${blockTextId}`,
          data
        );
         setIsLoading(false);
       Toast(response?.data.statusMessage, "success");
      }else{
         setIsLoading(true);
        response = await postRequest(`${baseURL}/lesson/create-blocktext`,data);
         setIsLoading(false);
      }
      if(response?.data){
         const updatedData = response.data.data;
         if (updatedData) {
           setblockTextId(updatedData.id || "");
           setDescription(updatedData.description || "");
           setSelectedOption(updatedData.lightTheme || "Light");
         }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };


   const handleBackClick = async () => {
     try {
       const response = await getRequest(
         `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
       );
       const flashCardId = response?.data.data[0].flashCardId;
       navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
     } catch (error) {
       console.error("Error fetching themeId:", error);
     }
   };

   useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
          className="row-container"
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Block text
          </h4>
          <VerticalSpace height={30} />
          <div
            className="container block-text-container"
            style={{ maxWidth: "1117px" }}
          >
            <div
              className="container p-4 user-data"
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
              }}
            >
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <div className="d-flex" style={{ gap: "8px" }}>
                    <RadioButton
                      checked={lightTheme === "Light"}
                      onChange={() => handleOptionChange("Light")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      light
                    </Label>
                    <HorizontalSpace width={20} />
                    <RadioButton
                      checked={lightTheme === "Dark"}
                      onChange={() => handleOptionChange("Dark")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      dark
                    </Label>
                  </div>
                </div>
                <VerticalSpace height={24} />
                <div className="d-flex justify-content-between">
                  <Label fontSize="20px" fontWeight={700}>
                    Description
                  </Label>
                </div>
                <VerticalSpace height={16} />
                <div>
                  <TextEditor
                    value={description}
                    onChange={handleDescriptionChange}
                    lightTheme={lightTheme as "Light" | "Dark"}
                  />
                </div>
              </div>
            </div>
          </div>
          <VerticalSpace height={230} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BlockText;
