import React, { useState } from "react";
import Label from "./Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import HorizontalSpace from "./HorizontalSpace";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import CustomCheckbox from "./Common/CustomCheckbox";
import Switch from "./Common/Switch";

interface Member {
  id: number;
  srNo: number;
  name: string;
  lastname: string;
  email: string;
  created: string;
  onEdit: (id: number) => void;
  status: boolean;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  onDelete: (id: number) => void;
  onStatusChange: (id: number) => void;
}

const MembersUser: React.FC<Member> = ({
  id,
  srNo,
  name,
  lastname,
  email,
  created,
  onDelete,
  onEdit,
  status,
  onCheckboxChange,
  isChecked,
  onStatusChange,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);
  const toggleSwitch = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
  };

  const handleDelete = () => {
    onDelete(id); 
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        margin="10px"
      >
        <RowContainer flex="1.5">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
        </RowContainer>
        <RowContainer flex="2" gap="0px">
          <Label fontWeight={400} fontSize="16px" color="#201F29">
            {srNo}
          </Label>
        </RowContainer>
        <RowContainer flex="5" gap="0px">
          <Label fontWeight={400} fontSize="16px">
            {name}
          </Label>
        </RowContainer>
        <RowContainer flex="5" gap="0px">
          <Label fontWeight={400} fontSize="16px">
            {lastname}
          </Label>
        </RowContainer>
        <RowContainer flex="8" gap="0px">
          <Label fontWeight={400} fontSize="16px">
            {email}
          </Label>
        </RowContainer>

        <RowContainer flex="6">
          <Label fontWeight={400} fontSize="16px">
            {created}
          </Label>
        </RowContainer>
        <RowContainer flex="2.7">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "10px" }}
          >
            <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
            <div style={{ cursor: "pointer" }}>
              <img
                src={editIcon}
                onClick={() => onEdit(id)}
                style={{ WebkitUserSelect: "none", userSelect: "none" }}
              />
            </div>
            {/* <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div> */}
          </div>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default MembersUser;
