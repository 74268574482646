import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import CustomDropdown from "../Common/CustomDropdown";

interface Ticket {
  id: number;
  status: string;
  ticketId: string;
  problem: string;
  description: string;
  email: string;
  userId: string;
  updatedAt: string;
}

const ViewTicktes = () => {
  const { id } = useParams();
  const [ticket, setTicket] = useState<Ticket>();
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTicketsById();
  }, []);

   useEffect(() => {
     if (textareaRef.current && ticket?.description) {
       // Auto resize textarea based on content
       textareaRef.current.style.height = "auto";
       textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
     }
   }, [ticket?.description]);

  const fetchTicketsById = async () => {
    setLoading(true);
    const response = await getRequest(
      `${baseURL}/ticket/get-ticket-by-id/${id}`
    );
    if (response?.data) {
      setTicket(response.data.data);
    }
    setLoading(false);
  };

   const dropdownItems: string[] = [
     "Open",
     "Urgent",
     "Cancel",
     "Medium",
     "Solved",
   ];

   const handleStatusChange = async(status: string) => {
    setLoading(true);
    const reqData = {
      status: status,
    };
    const response = await postRequest(`${baseURL}/ticket/update-ticket/${id}`, reqData);
    if (response?.data.statusCode === 200) {
        setTicket(response?.data.data)
    }
    setLoading(false);
   };

  const getPriorityLabel = (priority: any) => {
    switch (priority) {
      case "Open":
        return (
          <Label
            fontSize="14px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#7542E4"
            justifyContent="center"
            display="flex"
            width="90px"
            borderRadius="40px"
            padding="10px 15px"
          >
            Open
          </Label>
        );
      case "Urgent":
        return (
          <Label
            fontSize="14px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#EB002C"
            justifyContent="center"
            display="flex"
            width="90px"
            borderRadius="40px"
            padding="10px 15px"
          >
            Urgent
          </Label>
        );
      case "Cancel":
        return (
          <Label
            fontSize="14px"
            color="#100F15"
            fontWeight={400}
            cursor="pointer"
            bgColor="#D9D9D9"
            justifyContent="center"
            display="flex"
            width="90px"
            borderRadius="40px"
            padding="10px 15px"
          >
            Cancelled
          </Label>
        );
      case "Solved":
        return (
          <Label
            fontSize="14px"
            color="#FFFFFF"
            fontWeight={400}
            cursor="pointer"
            bgColor="#28A138"
            justifyContent="center"
            display="flex"
            width="90px"
            borderRadius="40px"
            padding="10px 15px"
          >
            Solved
          </Label>
        );
      case "Medium":
      default:
        return (
          <Label
            fontSize="14px"
            color="#100F15"
            fontWeight={400}
            cursor="pointer"
            bgColor="#FF9E00"
            justifyContent="center"
            display="flex"
            width="90px"
            borderRadius="40px"
            padding="10px 15px"
          >
            Medium
          </Label>
        );
    }
  };

  const handleBackClick = () => {
    navigate(`/tickets`);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Tickets view
            </h4>
            <VerticalSpace height={20} />
            <div className="row p-3 d-flex align-items-center justify-content-center">
              <div
                className="container col-10"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                  padding: "20px",
                }}
              >
                <VerticalSpace height={24} />
                <div className="col">
                  <div className="row g-3">
                    <div className="col-md-6">
                      {getPriorityLabel(ticket?.status)}
                    </div>
                    <div className="col-md">
                      <CustomDropdown
                        items={dropdownItems}
                        onSelect={handleStatusChange}
                        selectedItem={ticket?.status}
                        width="150px"
                        padding="8px 10px"
                        height="40px"
                        border="1px solid #D9D9D9"
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        TicketId
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={ticket?.ticketId || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        UserId
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={ticket?.userId || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        User email
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={ticket?.email || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Problem
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        value={ticket?.problem || "N/A"}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Message
                      </Label>
                      <VerticalSpace height={4} />
                      <textarea
                        ref={textareaRef}
                        className="form-control"
                        data-kt-autosize="true"
                        value={ticket?.description || "N/A"}
                        style={{
                          resize: "none",
                          overflow: "hidden",
                        }}
                        disabled
                      />
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ViewTicktes;
