import React, { useState, useEffect } from "react";
import VerticalSpace from "../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../assets/deleteIcon.svg";
import editIcon from "../../assets/editIcon.svg";
import plusIcon from "../../assets/plus_Icon.png";
import Label from "../Common/Label";
import HorizontalSpace from "../HorizontalSpace";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/inputBox.css";
import { baseURL, postRequest } from "../../api/apiClient";
import Toast from "../Common/Toast";
import "../../stylesheets/theme.css";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
import '../../stylesheets/common.css'

interface Particle {
  id: number;
  name: string;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
}

const ParticlesLevel = () => {
  const { id } = useParams(); 
  const [addParticles, setParticlesLevel] = useState(false);
  const [name, setName] = useState("");
  const [particleType, setParticleType] = useState("");
  const [particleValue, setParticleValue] = useState("");
  const [particleType1, setParticleType1] = useState("");
  const [particleValue1, setParticleValue1] = useState("");
  const [particleName, setParticleName] = useState<Particle[]>([]);
  const [editingParticle, setEditingParticle] = useState<Particle | null>(null);
  const [particleError, setParticleError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { particleId: pId, name: names } = location.state || {}; 
  const [mainNameState, setMainName] = useState("");

  const handleParticlesLevel = () => {
    setName("");
    setParticleType("");
    setParticleValue("");
    setParticleType1("");
    setParticleValue1("");
    setParticleError("");
    setParticlesLevel(true);
    setEditingParticle(null);
  };

  const handleEditParticle = (particle: Particle) => {

     setName(particle.name);
     setParticleType(particle.particleType);
     setParticleValue(particle.particleValue);
     setParticleType1(particle.particleType1);
     setParticleValue1(particle.particleValue1);

    // Set the currently edited particle
    setEditingParticle(particle);
    setParticlesLevel(true);
  };

   useEffect(()=>{
    const fetchParticle = async ()=>{
      try {
        const reqData = {
          id: id,
        };
        setIsLoading(true);
        const response = await postRequest(
          `${baseURL}/particle/get-particle`,
          reqData
        );
        
        const particlesData = response?.data.data.particles;
        const mainName = response?.data.data.name;
        setParticleName(particlesData);
        setMainName(mainName);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching particles:", error);
      }
    }
    fetchParticle();
   },[id])

  const handleSaveParticle = async ()=>{
    if (!name) {
      setParticleError("Please enter particle name");
      return;
    }
      if (editingParticle) {
       await handleUpdateParticle();
      } else {
       await handleCreateParticle();
      }
  }

  const handleCreateParticle = async () => {

    if (!name) {
      setParticleError("Please enter particle name");
      return;
    }
    
    try {
      const data = {
        pId,
        name,
        particleType,
        particleValue,
        particleType1,
        particleValue1,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/particle/create-particle`,
        data
      );

      const createdParticle = response?.data.data;
      setParticleName([...particleName, createdParticle]); 
      setIsLoading(false);
      setName("");
      setParticleType("");
      setParticleValue("");
      setParticleType1("");
      setParticleValue1("");

      // setNewParticles(false);
      setParticlesLevel(false);
    } catch (error) {
      setIsLoading(false);
      Toast("Error creating Particle","error");
    }
  }

  const handleUpdateParticle =async () => {
    if (!name) {
      setParticleError("Please enter particle name");
      return;
    }
    try {
      const data = {
        id: editingParticle?.id,
        pId,
        name,
        particleType,
        particleValue,
        particleType1,
        particleValue1,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/particle/update-particle`,
        data
      );
      const updatedParticle = response?.data.data;

      const updatedParticles = particleName.map((particle) =>
        particle.id === updatedParticle.id ? updatedParticle : particle
      );
      setParticleName(updatedParticles);
      setIsLoading(false);
      setName("");
      setParticleType("");
      setParticleValue("");
      setParticleType1("");
      setParticleValue1("");
      setEditingParticle(null);
      setParticlesLevel(false);
    } catch (error) {
      setIsLoading(false);
      Toast("Error updating Particle","error");
    }
  };

  const handleAddSubParticle = (id: number | null, name: string) => {
    navigate(`/particles/level/${id}`, {
      state: { particleId: id, name: name },
    });
  };
  

  const handleDeleteParticle = async (id: number | number[]) => {
    try {
      const reqData = {
        modelName: "particles",
        id: id,
      };
      const response = await postRequest(
        `${baseURL}/lesson/delete-bretzel-content`,
        reqData
      );
      if (response?.status === 200) {
        Toast(response?.data.statusMessage, "success");
        const updatedParticles = particleName.filter(
          (particle) => particle.id !== id
        );
        setParticleName(updatedParticles);
      }
    } catch (error) {
      console.error("Error deleting particles:", error);
    }
  };

  const handleBackClick = async () => {
    navigate(`/particles`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });

  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a Particle
            </h4>
            <VerticalSpace height={20} />
            <div
              className="container d-flex justify-content-center theme-container"
              style={{ maxWidth: "666px" }}
            >
              <div
                className="container p-4"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                }}
              >
                <div className="col-12 theme-content">
                  <div className="d-flex justify-content-between">
                    <Label fontSize="20px" fontWeight={700}>
                      {mainNameState}
                    </Label>
                  </div>
                  <div>
                    {particleName.length === 0 ? (
                      <div className="p-5 d-flex justify-content-center align-items-center">
                        <label
                          htmlFor="particle"
                          className="form-label"
                          style={{ fontWeight: "bold", color: "#7542E4" }}
                        >
                          There are no particles yet in this particle.
                        </label>
                      </div>
                    ) : (
                      particleName.map((particle) => (
                        <div
                          className="row d-flex justify-content-center align-items-center "
                          style={{ gap: "10px" }}
                          key={particle.id}
                        >
                          <div
                            className="drop_box theme-box col-9"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Label
                                fontSize="14px"
                                color="#000"
                                fontWeight={400}
                              >
                                {particle.name}
                              </Label>
                            </div>
                          </div>
                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleEditParticle(particle)}
                          >
                            <img
                              src={editIcon}
                              className="user-scale user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                          <div
                            className="p-0"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              backgroundColor: "rgb(203 203 203 / 99%)",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              handleAddSubParticle(particle.id, particle.name)
                            }
                          >
                            <img
                              src={plusIcon}
                              className="user-scale"
                              width="31px"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>

                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleDeleteParticle(particle.id)}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-2 text-center user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      borderRadius: "10px",
                      padding: "14px 40px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleParticlesLevel}
                  >
                    Add new
                  </button>
                </div>
              </div>
            </div>
            <VerticalSpace height={130} />
          </div>
          {addParticles && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                // borderRadius: "24px",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body">
                    <h5 style={{ fontWeight: "bold" }}>Add a Particles</h5>
                    <VerticalSpace height={10} />
                    <Label
                      fontSize="14px"
                      padding="0px 0px 0px 8px"
                      fontWeight={400}
                    >
                      Particle Name<span style={{ color: "red" }}>*</span>
                    </Label>
                    <VerticalSpace height={4} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputparticles"
                      placeholder="Enter Particle Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        borderRadius: "10px",
                        height: "40px",
                        fontSize: "14px",
                      }}
                    />
                    {particleError && (
                      <div className="error-message">{particleError}</div>
                    )}
                    <VerticalSpace height={5} />
                    <div className="col-md-12 d-flex justify-content-between">
                      <div className="col">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Particle Type
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="partilcetype1"
                          value={particleType}
                          onChange={(e) => setParticleType(e.target.value)}
                          placeholder="Type 1"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <HorizontalSpace width={3} />
                      <div className="col">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Particle Value
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="particlevalue1"
                          placeholder="Particle Value"
                          value={particleValue}
                          onChange={(e) => setParticleValue(e.target.value)}
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    </div>
                    <VerticalSpace height={5} />
                    <div className="col-md-12 d-flex justify-content-between">
                      <div className="col">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Particle Type
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="partilcetype2"
                          value={particleType1}
                          onChange={(e) => setParticleType1(e.target.value)}
                          placeholder="Type 2"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <HorizontalSpace width={3} />
                      <div className="col">
                        <Label
                          fontSize="14px"
                          padding="0px 0px 0px 8px"
                          fontWeight={400}
                        >
                          Particle Value
                        </Label>
                        <VerticalSpace height={4} />
                        <input
                          type="text"
                          className="form-control"
                          id="particlevalue2"
                          value={particleValue1}
                          onChange={(e) => setParticleValue1(e.target.value)}
                          placeholder="Particle Value"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setParticlesLevel(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        borderRadius: "10px",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                        borderRadius: "10px",
                      }}
                      onClick={handleSaveParticle}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ParticlesLevel;
