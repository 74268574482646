import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../../assets/css/dropdown.css";
import Arrow from "../../assets/Images/Arow.svg";

interface Props {
  items: string[];
  onSelect: (item: string) => void;
  selectedItem?: string;
  width?: string;
  border?: string;
  height?: string;
  padding?: string;
}

const CustomDropdown: React.FC<Props> = ({
  items,
  onSelect,
  selectedItem,
  width,
  border,
  height,
  padding,
}) => {
   const [currentSelectedItem, setCurrentSelectedItem] = useState<string>(
     selectedItem || (items.length > 0 ? items[0] : "")
   );
  
   useEffect(() => {
     if (selectedItem) {
       setCurrentSelectedItem(selectedItem); // Trigger onSelect when selectedItem is set
     }
   }, [selectedItem]);

     const handleSelect = (item: string) => {
       setCurrentSelectedItem(item);
       onSelect(item);
     };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        className="custom-toggle d-flex align-items-center justify-content-between"
        style={{
          width: width,
          height: height,
          border: border,
          padding: padding,
          borderRadius: "10px",
        }}
      >
        {currentSelectedItem}
        <img src={Arrow} style={{ marginLeft: "auto" }} alt="Arrow" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-menu">
        {items.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleSelect(item)}
            // className="custom-item"
            className={
              currentSelectedItem === item ? "selected-item" : "custom-item"
            }
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
