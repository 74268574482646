import { configureStore } from "@reduxjs/toolkit";
import lessonsReducer from "./lessonsSlice";
import particlesReducer from "./particlesReducerSlice";

const store = configureStore({
  reducer: {
    lessons: lessonsReducer,
    particles: particlesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
