// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.custom-checkbox .form-check-input {
    width: 28px;
    height: 28px;
    border-radius: 10px;
    margin-top: 0px;
    margin-left: 5px;

}

.custom-checkbox .form-check-input:checked {    
    background-color: #7542e4;
    border: none;
    font-size: "10px";
    font-weight: normal;
    
}

.custom-checkbox .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.custom-checkbox .form-check-input:not(:checked) {
    background-color: #d9d9d9;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/customCheckbox.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;;AAEvB;;AAEA;IACI,iEAAuP;AAC3P;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/* styles.css */\n.custom-checkbox .form-check-input {\n    width: 28px;\n    height: 28px;\n    border-radius: 10px;\n    margin-top: 0px;\n    margin-left: 5px;\n\n}\n\n.custom-checkbox .form-check-input:checked {    \n    background-color: #7542e4;\n    border: none;\n    font-size: \"10px\";\n    font-weight: normal;\n    \n}\n\n.custom-checkbox .form-check-input:checked[type=checkbox] {\n    --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);\n}\n\n.custom-checkbox .form-check-input:not(:checked) {\n    background-color: #d9d9d9;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
