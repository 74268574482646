import React, { useState, useRef, useEffect } from "react";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import ParticlesList from "../../Components/ParticleList";
import formatDate from "../../Components/DateFormat";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import VerticalSpace from "../../Components/VerticalSpace";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import Loader from "../../utils/Loading";


const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px; /* Ensures minimum width to trigger horizontal scrolling */
`;

interface Particles {
  id: number;
  name: string;
  updatedAt: string;
  active: boolean;
}

const Particles = () => {
  const [particles, setParticles] = useState<Particles[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedParticles, setSelectedParticles] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedParticlesId, setSelectedParticlesId] = useState<number | null>(
    null
  );
  const [filters, setFilters] = useState<{
    updatedAt?: number;
  }>({});


  
  const navigate = useNavigate();
  
  const dropdownItems: string[] = [
    "All",
    "Latest update",
  ];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const fetchParticles = async (
    page: number,
    perPage: number,
    filters: { updatedAt?: number }
  ) => {
    setLoading(true);
    try {
      let url = `${baseURL}/particle/get-particle?page=${page}&limit=${perPage}`;

      if(filters.updatedAt){
        url += `&updatedAt=${filters.updatedAt}`;
      }

      const response = await postRequest(url);
      // const response = await postRequest(
      //   `${baseURL}/particle/get-particle?page=${page}&limit=${perPage}`
      // );
      setLoading(false);
      if (response?.data) {
        setParticles(response.data.data.particles);
        setCount(response.data.data.totalParticleCount);
        setPage(response.data.data.page - 1);
        // setTotalPages(response.data.data.totalPages);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };
 
  useEffect(() => {
    fetchParticles(page + 1, limit, filters);
  }, [page, limit, filters]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleApplyFilters = () =>{
    switch(selectedItem){
      case "Latest update":
        setFilters({updatedAt: -1});
       break;
      default:
        setFilters({});
        break;
    }
  }

  const handleEditParticles = (id: number, name: string) => {
    navigate(`/particles/${id}`, {
      state: { particleId: id, name: name },
    });
  };

  const handleCreateParticles = () => {
    navigate("/particles/create");
  };

  const handleStatusChange = async (id: number) => {
    try {
      await getRequest(`${baseURL}/particle/changed-status/${id}`);
    } catch (error) {
      //  toast.error(`${error.message}`);
    }
  };

  const onCheckboxChange = (id: number) => {
    if (selectedParticles.includes(id)) {
      setSelectedParticles(
        selectedParticles.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedParticles([...selectedParticles, id]);
    }
  };

  // const deleteSelectedParticles = () => {
  //   selectedParticles.forEach((id) => deleteQuetions(id));
  //   setSelectedParticles([]);
  // };

  const deleteSelectedParticles = () => {
    if (selectedParticles.length > 0) {
      setShowDeleteDialog(true);
    } else {
     Toast("Please select at least one particle to delete.","error");
    }
  };
   const deleteParticles =async (particlesIds: number | number[]) => {
     try {
      const reqData = {
        id: Array.isArray(particlesIds) ? particlesIds : particlesIds,
      };
      const response = await postRequest(`${baseURL}/particle/delete-particle`,reqData);
      if (response?.status === 200){
        Toast(response?.data.statusMessage, "success");
        setParticles((prevData) =>
          prevData.filter((particle) =>
            Array.isArray(particlesIds)
              ? !particlesIds.includes(particle.id)
              : particle.id !== particlesIds
          )
        );
      }
     } catch (error) {
       console.error("Error deleting particles:", error);
     }
   }

  const handleDelete =async () => {
    if (selectedParticlesId !== null){
       await deleteParticles(selectedParticlesId)
    }else{
       await deleteParticles(selectedParticles);
    }
    setSelectedParticles([]);
    setSelectedParticlesId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Particles</h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      padding: "14px 50px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                padding: "14px 40px",
                fontSize: "14px",
                width: "auto",
                borderRadius: "10px",
              }}
              onClick={deleteSelectedParticles}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      padding: "14px 40px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleCreateParticles}
                  >
                    Add Now
                  </button>
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                    >
                      <RowContainer flex="1.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="25">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>
                      <RowContainer flex="13">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>

                      <RowContainer flex="3.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {particles && particles.length > 0 ? (
                      <ColumnContainer>
                        {particles.map((particles) => (
                          <ParticlesList
                            key={particles.id}
                            id={particles.id}
                            name={particles.name}
                            latestdate={formatDate(particles.updatedAt)}
                            onEdit={() =>
                              handleEditParticles(particles.id, particles.name)
                            }
                            onDelete={() => {
                              setSelectedParticlesId(particles.id);
                              toggleDeleteDialog();
                            }}
                            isChecked={selectedParticles.includes(particles.id)}
                            onCheckboxChange={onCheckboxChange}
                            status={particles.active}
                            onStatusChange={handleStatusChange}
                          />
                        ))}
                      </ColumnContainer>
                    ) : (
                      <>
                        <VerticalSpace height={30} />
                        <Label
                          alignItems="center"
                          display="flex"
                          color="rgba(164, 163, 167, 1)"
                          justifyContent="center"
                        >
                          No results found.
                        </Label>
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>

              <VerticalSpace height={100} />
            </div>
            {/* <ToastContainer /> */}
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedParticles.length === 0
                    ? "this particles?"
                    : selectedParticles.length === 1
                    ? "this particle?"
                    : "these particles?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Particles;



              {
                /* with text particles */
              }

              {
                /* <ColumnContainer>
                <ParticlesType
                  padding="3px 10px"
                  bgColor="#0063F9"
                  borderRadius="4px"
                  color="#FFFFFF"
                  fontSize="14px"
                  // lineHeight="18px"
                  fontWeight={400}
                >
                  Masculino
                </ParticlesType>
                <ParticlesType
                  padding="3px 10px"
                  bgColor="#FD4B92"
                  borderRadius="4px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Femenino
                </ParticlesType>
                <ParticlesType
                  padding="3px 10px"
                  bgColor="#6D787E"
                  borderRadius="4px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Neutral
                </ParticlesType>
                <ParticlesType
                  padding="3px 10px"
                  bgColor="#E0E0E6"
                  borderRadius="4px"
                  // lineHeight="18px"
                  color="#121217"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Plural
                </ParticlesType>
                <ParticlesType
                  padding="2.5px 10px"
                  bgColor="#009BFF"
                  borderRadius="20px 4px 4px 20px"
                  // lineHeight="20px"
                  color="#FFFFFF"
                  fontSize="15px"
                  fontWeight={400}
                >
                  Sujeto
                </ParticlesType>
                <ParticlesType
                  padding="2.5px 10px"
                  bgColor="#009BFF"
                  borderRadius="4px 20px 20px 4px"
                  // lineHeight="20px"
                  color="#FFFFFF"
                  fontSize="15px"
                  fontWeight={400}
                >
                  Complemento nom.
                </ParticlesType>
                <ParticlesType
                  padding="3.5px 10px"
                  bgColor="#7542E4"
                  borderRadius="20px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Verbo conj.
                </ParticlesType>
                <ParticlesType
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Infinitivo
                </ParticlesType>
                <ParticlesType
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  Participio
                </ParticlesType>
                <RowContainer gap="0px">
                  <ParticlesType
                    padding="3.5px 10px"
                    bgColor="#7542E4"
                    borderRadius="10px 0px 0px 10px"
                    // lineHeight="18px"
                    color="#FFFFFF"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Verbo sep.
                  </ParticlesType>
                  <ColumnContainer gap="14px">
                    <ParticlesType
                      padding="3.5px"
                      bgColor="#7542E4"
                      // borderRadius="20px"
                      // lineHeight="18px"
                      color="#FFFFFF"
                      fontSize="14px"
                      fontWeight={400}
                    ></ParticlesType>
                    <ParticlesType
                      padding="3.5px"
                      bgColor="#7542E4"
                      // borderRadius="20px"
                      // lineHeight="18px"
                      color="#FFFFFF"
                      fontSize="14px"
                      fontWeight={400}
                    ></ParticlesType>
                  </ColumnContainer>
                </RowContainer>
                <RowContainer gap="0px">
                  <ColumnContainer gap="14px" backgroundColor="#37176C">
                    <ParticlesType
                      padding="3.5px"
                      bgColor="rgb(248, 248, 248)"
                      // borderRadius="20px"
                      // lineHeight="18px"
                      // color="#FFFFFF"
                      fontSize="14px"
                      fontWeight={400}
                    ></ParticlesType>
                    <ParticlesType
                      padding="3.5px"
                      bgColor="rgb(248, 248, 248)"
                      // borderRadius="20px"
                      // lineHeight="18px"
                      // color="#FFFFFF"
                      fontSize="14px"
                      fontWeight={400}
                    ></ParticlesType>
                  </ColumnContainer>
                  <ParticlesType
                    padding="3.5px 10px"
                    bgColor="#37176C"
                    borderRadius="0px 10px 10px 0px"
                    // lineHeight="18px"
                    color="#FFFFFF"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Prefijo
                  </ParticlesType>
                </RowContainer>
                <ParticlesType
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px 4px 4px 20px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  zu
                </ParticlesType>
                <ParticlesType
                  padding="0px 10px"
                  bgColor="#00B69C"
                  borderRadius="4px"
                  // lineHeight="18px"
                  color="#FFFFFF"
                  fontSize="14px"
                  fontWeight={400}
                >
                  TE
                </ParticlesType>
              </ColumnContainer> */
              }

              {
                /* withOut text particles */
              }

              {
                /* <ColumnContainer>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#0063F9"
                  borderRadius="4px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#FD4B92"
                  borderRadius="4px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#6D787E"
                  borderRadius="4px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#E0E0E6"
                  borderRadius="4px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  // bgColor="#E0E0E6"
                  borderRadius="4px"
                  border="1px solid #E0E0E6"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="2px"
                  // bgColor="#E0E0E6"
                  borderRadius="4px"
                  border="1px solid #E0E0E6"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={TextIcon}
                    alt="text-icon"
                    style={{ opacity: "24%" }}
                  />
                </ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#009BFF"
                  borderRadius="20px 4px 4px 20px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#009BFF"
                  borderRadius="4px 20px 20px 4px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#7542E4"
                  borderRadius="20px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px"
                ></ParticlesType>
                <RowContainer gap="0px">
                  <ParticlesType
                    width="26px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#7542E4"
                    borderRadius="10px 0px 0px 10px"
                  ></ParticlesType>
                  <ColumnContainer>
                    <ParticlesType
                      width="6px"
                      height="7px"
                      bgColor="#7542E4"
                    ></ParticlesType>
                    <ParticlesType
                      width="6px"
                      height="7px"
                      bgColor="#7542E4"
                    ></ParticlesType>
                  </ColumnContainer>
                </RowContainer>
                <RowContainer gap="0px">
                  <ColumnContainer backgroundColor="#37176C">
                    <ParticlesType
                      width="6px"
                      height="7px"
                      bgColor="rgb(248, 248, 248)"
                    ></ParticlesType>
                    <ParticlesType
                      width="6px"
                      height="7px"
                      bgColor="rgb(248, 248, 248)"
                    ></ParticlesType>
                  </ColumnContainer>
                  <ParticlesType
                    width="26px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#37176C"
                    borderRadius="0px 10px 10px 0px"
                  ></ParticlesType>
                </RowContainer>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#37176C"
                  borderRadius="20px 4px 4px 20px"
                ></ParticlesType>
                <CombinedParticles
                  // padding="10px"
                  width="32px"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  innerColors={["#FFFFFF"]}
                  bgColor="#00B69C"
                  borderRadius="4px"
                ></CombinedParticles>
                <CombinedParticles
                  width="32px"
                  height="24px"
                  alignItems="center"
                  gap="2px"
                  justifyContent="center"
                  innerColors={["#12525E", "#FFFFFF"]}
                  bgColor="#00B69C"
                  borderRadius="4px"
                ></CombinedParticles>
                <CombinedParticles
                  width="32px"
                  height="24px"
                  gap="2px"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  innerColors={["#12525E", "#12525E", "#FFFFFF"]}
                  bgColor="#00B69C"
                  borderRadius="4px"
                ></CombinedParticles>
                <CombinedParticles
                  width="32px"
                  height="24px"
                  gap="1px"
                  alignItems="center"
                  justifyContent="center"
                  innerColors={["#12525E", "#12525E", "#12525E", "#FFFFFF"]}
                  bgColor="#00B69C"
                  borderRadius="4px"
                ></CombinedParticles>
                {/* <CombinedParticles innerColors={innerColors1} />
                <CombinedParticles innerColors={innerColors2} />
                <CombinedParticles innerColors={innerColors3} /> */
              }
              {
                /* <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#21041C"
                  borderRadius="20px"
                ></ParticlesType>
                <ParticlesType
                  width="32px"
                  height="24px"
                  padding="3.5px 10px"
                  bgColor="#EB002C"
                  borderRadius="4px"
                ></ParticlesType>

                <RowContainer gap="0px">
                  <ColumnContainer gap="4px" backgroundColor="#FF9E00">
                    <ParticlesType
                      width="4px"
                      height="10px"
                      bgColor="rgb(248, 248, 248)"
                    ></ParticlesType>
                    <ParticlesType
                      width="4px"
                      height="10px"
                      bgColor="rgb(248, 248, 248)"
                    ></ParticlesType>
                  </ColumnContainer>
                  <ParticlesType
                    width="28px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#FF9E00"
                    borderRadius="4px"
                  ></ParticlesType>
                </RowContainer>

                <RowContainer gap="0px" alignItem="center">
                  <ColumnContainer gap="6px">
                    <ParticlesType
                      width="4px"
                      height="4px"
                      bgColor="#FF9E00"
                    ></ParticlesType>
                    <ParticlesType
                      width="4px"
                      height="4px"
                      bgColor="#FF9E00"
                    ></ParticlesType>
                  </ColumnContainer>
                  <ParticlesType
                    width="28px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#FF9E00"
                    borderRadius="4px"
                  ></ParticlesType>
                </RowContainer>
                
                <RowContainer gap="0px" alignItem="center">
                  <ColumnContainer gap="2px">
                    <ParticlesType
                      width="4px"
                      height="4px"
                      bgColor="#FF9E00"
                    ></ParticlesType>
                    <ParticlesType
                      width="4px"
                      height="4px"
                      bgColor="#FF9E00"
                    ></ParticlesType>
                    <ParticlesType
                      width="4px"
                      height="4px"
                      bgColor="#FF9E00"
                    ></ParticlesType>  
                  </ColumnContainer>
                  <ParticlesType
                    width="28px"
                    height="24px"
                    padding="3.5px 10px"
                    bgColor="#FF9E00"
                    borderRadius="4px"
                  ></ParticlesType>
                </RowContainer>
              </ColumnContainer>  */
              }