import { useEffect, useState } from "react";
import Loader from "../../utils/Loading";
import styled from "styled-components";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import { baseURL, getRequest } from "../../api/apiClient";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import ConfigList from "../../Components/Config/ConfigList";
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import '../../stylesheets/common.css'


const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface Configs {
  id: number;
  name: string;
  updatedAt: string;
}

const Configs = () =>{
    const [configs, setConfigs] = useState<Configs[]>([]);
    const [loading, setLoading]= useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
      fetchConfig();
    },[])

  const fetchConfig = async ()=>{
    setLoading(true);
    const response = await getRequest(`${baseURL}/config/get-all-config`);
    if (response?.data) {
      setConfigs(response?.data.data);
    }
    setLoading(false);
  }

 const handleEditConfig =(id: number)=>{
     navigate(`/configs/edit-config/${id}`);
 }

 useEffect(() => {
  let timeLine = gsap.timeline();
  timeLine.from(".row-container", {
    opacity: 0,
    y: -40,
    duration: 0.3,
    ease: "power2.out",
  });

  timeLine.from(".user-data", {
    opacity: 0,
    y: 40,
    duration: 0.5,
    ease: "power2.out",
    stagger: 0.2
  });


}, [loading]);
    
return (
  <>
    {loading ? (
      <Loader />
    ) : (
      <div className="container-fluid">
        <div className="p-3">
          <div className="row mb-3 ">
            <div className="col">
              <h2 style={{ fontWeight: "bold" }}>Configs</h2>
            </div>
          </div>
          <ScrollableRowContainer>
            <InnerRowContainer>
              <Container width="100%">
                <RowContainer
                  alignItem="center"
                  padding="8px 12px 8px 12px"
                  gap="8px"
                  className="row-container"
                >
                  <RowContainer flex="0.5">
                    <Label
                      fontWeight={400}
                      fontSize="14px"
                      color="rgba(164, 163, 167, 1)"
                    ></Label>
                  </RowContainer>
                  <RowContainer flex="3">
                    <Label
                      fontWeight={400}
                      fontSize="14px"
                      color="rgba(164, 163, 167, 1)"
                    >
                      Id
                    </Label>
                  </RowContainer>
                  <RowContainer flex="11">
                    <Label
                      fontWeight={400}
                      fontSize="14px"
                      color="rgba(164, 163, 167, 1)"
                    >
                      Name
                    </Label>
                  </RowContainer>
                  <RowContainer flex="5">
                    <Label
                      fontWeight={400}
                      fontSize="14px"
                      color="rgba(164, 163, 167, 1)"
                    >
                      Date
                    </Label>
                  </RowContainer>
                  <RowContainer flex="1">
                    <Label
                      fontWeight={400}
                      fontSize="14px"
                      color="rgba(164, 163, 167, 1)"
                    >
                      Edit
                    </Label>
                  </RowContainer>
                </RowContainer>
                {configs && configs.length > 0 ? (
                  <ColumnContainer>
                    {configs.map((config) => (
                      <ConfigList
                        key={config.id}
                        id={config.id}
                        name={config.name || "Screen Image"}
                        updatedAt={formatDate(config.updatedAt)}
                        onEdit={handleEditConfig}
                      />
                    ))}
                  </ColumnContainer>
                ) : (
                  <>
                    <VerticalSpace height={30} />
                    <Label
                      alignItems="center"
                      display="flex"
                      color="rgba(164, 163, 167, 1)"
                      justifyContent="center"
                    >
                      No results found.
                    </Label>
                  </>
                )}
              </Container>
            </InnerRowContainer>
          </ScrollableRowContainer>
        </div>
      </div>
    )}
  </>
);
}

export default Configs;