// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toggle.dropdown-toggle::after {
    display: none;
    /* Hide the caret icon */
}
#dropdown-basic{
    background-color: #ffffff;
    color: #100F15;
    border: 1px solid #100F15;
    padding: 10px 15px;
    display: flex;
    width: 160px;
}
.custom-menu{
    padding: 10px;
    width: -webkit-fill-available;
}
.custom-dropdown .dropdown-menu {
    min-width: calc(100% - 1rem);
    /* Set the minimum width of the dropdown menu */
}
.selected-item {
    background-color: #7542e4;
    /* Custom background color for the selected item */
    color: #ffffff;
    border-radius: 10px;
    /* Custom text color for the selected item */
}
.selected-item:hover {
    background-color: #7542e4;
    /* Custom background color for the selected item */
    color: #ffffff;
    border-radius: 10px;
    /* Custom text color for the selected item */
}
.custom-item {
    margin-bottom: 4px;
    margin-top: 4px;
    /* Add bottom space to the items */
}
.custom-item:hover {
    background-color: #7542e4 !important;
    color: #ffffff !important;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/dropdown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;AAC5B;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;IACb,6BAA6B;AACjC;AACA;IACI,4BAA4B;IAC5B,+CAA+C;AACnD;AACA;IACI,yBAAyB;IACzB,kDAAkD;IAClD,cAAc;IACd,mBAAmB;IACnB,4CAA4C;AAChD;AACA;IACI,yBAAyB;IACzB,kDAAkD;IAClD,cAAc;IACd,mBAAmB;IACnB,4CAA4C;AAChD;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,kCAAkC;AACtC;AACA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".custom-toggle.dropdown-toggle::after {\n    display: none;\n    /* Hide the caret icon */\n}\n#dropdown-basic{\n    background-color: #ffffff;\n    color: #100F15;\n    border: 1px solid #100F15;\n    padding: 10px 15px;\n    display: flex;\n    width: 160px;\n}\n.custom-menu{\n    padding: 10px;\n    width: -webkit-fill-available;\n}\n.custom-dropdown .dropdown-menu {\n    min-width: calc(100% - 1rem);\n    /* Set the minimum width of the dropdown menu */\n}\n.selected-item {\n    background-color: #7542e4;\n    /* Custom background color for the selected item */\n    color: #ffffff;\n    border-radius: 10px;\n    /* Custom text color for the selected item */\n}\n.selected-item:hover {\n    background-color: #7542e4;\n    /* Custom background color for the selected item */\n    color: #ffffff;\n    border-radius: 10px;\n    /* Custom text color for the selected item */\n}\n.custom-item {\n    margin-bottom: 4px;\n    margin-top: 4px;\n    /* Add bottom space to the items */\n}\n.custom-item:hover {\n    background-color: #7542e4 !important;\n    color: #ffffff !important;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
