import toast from "react-hot-toast"
const Toast = (message: string, type: "success" | "error" | "info") => {
  switch (type) {
    case "success":
      toast.success(message, {
        duration: 2000,
        position: "top-right",
        style: { borderRadius: "8px", minWidth: "250px" },
        className: "",
      });
      break;
    case "error":
      toast.error(message, {
        duration: 2000,
        position: "top-right",
        style: { borderRadius: "8px", minWidth: "250px" },
        className: "",
      });
      break;
    case "info":
      toast(message, {
        duration: 2000,
        position: "top-right",
        style: {
          borderRadius: "8px",
          minWidth: "250px",
          backgroundColor: "#2196F3",
          color: "#fff",
        },
        className: "",
      });
      break;
    default:
      toast(message, {
        duration: 2000,
        position: "top-right",
        style: { borderRadius: "8px", minWidth: "250px" },
        className: "",
      });
      break;
  }
};

export default Toast;