// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-box {
    transition: box-shadow 0.3s ease;
    /* Smooth transition */
}

.user-box:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Shadow effect on hover */
}

.user {
    transition: box-shadow 0.3s ease;
    /* Smooth transition */
}

.user:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.user-scale {
    transition: all 0.3s ease;
}

.user-scale:hover {
    transform: scale(1.2);
}


/* In your CSS file */
.user-container-hover {
    transition: background-color 0.3s ease;
}

.user-container-hover:hover {
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
    
}

.go-back {
    transition: all 0.3s ease;
}

.go-back:hover {
    /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3); */
    transform: scale(1.1);
    
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/common.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;;AAGA,qBAAqB;AACrB;IACI,sCAAsC;AAC1C;;AAEA;IACI,wCAAwC;IACxC,8CAA8C;;AAElD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,8CAA8C;IAC9C,qBAAqB;;AAEzB","sourcesContent":[".user-box {\n    transition: box-shadow 0.3s ease;\n    /* Smooth transition */\n}\n\n.user-box:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    /* Shadow effect on hover */\n}\n\n.user {\n    transition: box-shadow 0.3s ease;\n    /* Smooth transition */\n}\n\n.user:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);\n}\n\n.user-scale {\n    transition: all 0.3s ease;\n}\n\n.user-scale:hover {\n    transform: scale(1.2);\n}\n\n\n/* In your CSS file */\n.user-container-hover {\n    transition: background-color 0.3s ease;\n}\n\n.user-container-hover:hover {\n    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);\n    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */\n    \n}\n\n.go-back {\n    transition: all 0.3s ease;\n}\n\n.go-back:hover {\n    /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3); */\n    transform: scale(1.1);\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
