import React from "react";
import "../../assets/css/ImageWithHoverEffect.css";

interface SidebarProps {
  image: string;
  toScreen: string;
  className: string;
  tooltipText: string;
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  image,
  toScreen,
  className,
  tooltipText,
  onLinkClick,
}) => {
  return (
    <div className="sidebar">
      <ul>
        <li>
          <a href={toScreen} onClick={onLinkClick}>
            <img
              src={image}
              alt="Image"
              className={className}
              title={tooltipText}
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
