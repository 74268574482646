import React, { useState } from "react";
import CheckIcon from "../../assets/Images/Check.svg";
import CloseIcon from "../../assets/Images/Close.svg";
import DeleteIcon from "../../assets/Images/tooltipDeleteIcon.svg";
import RowContainer from "./RowContainer";
import Container from "./Container";

interface TooltipProps {
  position: { top: number; left: number };
  onSaveMessage: (message: string, action: "add" | "update") => void;
  onAction: (action: "close" | "delete") => void;
  tooltipAvailable: boolean;
  tooltipText: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  position,
  onSaveMessage,
  onAction,
  tooltipAvailable,
  tooltipText,
}) => {
  const [message, setMessage] = useState<string>(tooltipText);

  const handleSaveClick = (action: "add" | "update") => {
    onSaveMessage(message, action);
  };

  const handleActionClick = (action: "close" | "delete") => {
    onAction(action);
  };

  const adjustedTop = Math.max(position.top, 0); // Ensure the tooltip stays inside the top boundary
  const adjustedLeft = Math.min(
    Math.max(position.left, 0),
    window.innerWidth - 200 // Adjust based on expected width of the tooltip
  );

  return (
    <div
      style={{
        position: "absolute",
        top: adjustedTop,
        left: adjustedLeft,
        transform: "translate(-50%, -100%)",
        whiteSpace: "nowrap",
        zIndex: 1000,
        display: "flex",
      }}
    >
      <input
        type="text"
        placeholder="add a tooltip"
        style={{
          borderRadius: "6px 0px 0px 6px",
          height: "40px",
          border: "none",
          fontSize: "14px",
          padding: "10px",
          color: "#fff",
          backgroundColor: "#100F15",
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <RowContainer
        alignItem="center"
        justifyContent="end"
        bgColor="#100F15"
        borderRadius="0px 6px 6px 0px"
        gap="2px"
      >
        {tooltipAvailable ? (
          <>
            <Container
              height="100%"
              width="35px"
              padding="5px 6px"
              alignItems="center"
              display="flex"
              cursor="pointer"
            >
              <img
                src={CheckIcon}
                onClick={() => handleSaveClick("update")}
                style={{ cursor: "pointer" }}
              />
            </Container>
            <Container
              bgColor="#EB002C"
              height="100%"
              width="35px"
              padding="5px 6px"
              alignItems="center"
              display="flex"
              borderRadius="0px 6px 6px 0px"
              cursor="pointer"
            >
              <img
                src={DeleteIcon}
                onClick={() => handleActionClick("delete")}
                width="20px"
                height="20px"
              />
            </Container>
          </>
        ) : (
          <>
            <Container
              height="100%"
              width="35px"
              padding="5px 6px"
              alignItems="center"
              display="flex"
              cursor="pointer"
            >
              <img
                src={CheckIcon}
                onClick={() => handleSaveClick("add")}
                style={{ cursor: "pointer" }}
              />
            </Container>
            <Container
              height="100%"
              width="35px"
              padding="5px 6px"
              alignItems="center"
              display="flex"
              cursor="pointer"
            >
              <img
                src={CloseIcon}
                onClick={() => handleActionClick("close")}
                style={{ cursor: "pointer" }}
              />
            </Container>
          </>
        )}
      </RowContainer>

      <div
        style={{
          position: "absolute",
          top: "100%", // Position the triangle at the bottom of the tooltip
          left: "50%", // Center the triangle horizontally
          transform: "translateX(-50%)",
          width: "0",
          height: "0",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "13px solid #100F15", // Same color as the tooltip background
        }}
      />
    </div>
  );
};

export default Tooltip;
