import React from "react";
import styled from "styled-components";

interface ParticleProps {
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: number;
  fontStyle?: string;
  styleName?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textAlign?: string;
  margin?: string;
  padding?: string;
  alignItems?: string;
  bgColor?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  color?: string;
  borderRadius?: string;
  marginLeft?: string;
  position?: string;
  gap?: string;
  top?: string;
  marginTop?: string;
  left?: string;
  opacity?: string;
  boxShadow?: string;
  display?: string;
  zIndex?: string;
  justifyContent?: string;
  transform?: string;
}


const ParticlesType = styled.div<ParticleProps>`
  width: ${(props)=>props.width ?? ""};
  height: ${(props)=>props.height ?? ""};
  font-size: ${(props) => props.fontSize ?? ""};
  font-size: ${(props) => props.fontSize ?? ""};
  font-weight: ${(props) => props.fontWeight ?? ""};
  line-height: ${(props) => props.lineHeight ?? ""};
  font-style: ${(props) => props.fontStyle ?? ""};
  letter-spacing: ${(props) => props.letterSpacing ?? ""};
  text-align: ${(props) => props.textAlign ?? ""};
  margin: ${(props) => props.margin ?? ""};
  padding: ${(props) => props.padding ?? ""};
  align-items: ${(props) => props.alignItems ?? ""};
  background-color: ${(props) => props.bgColor ?? ""};
  color: ${(props) => props.color ?? ""};
  border-radius: ${(props) => props.borderRadius ?? ""};
  margin-left: ${(props) => props.marginLeft ?? ""};
  position: ${(props) => props.position ?? ""};
  border: ${(props) => props.border ?? ""};
  border-left: ${(props)=>props.borderLeft ?? ""};
  border-right: ${(props)=>props.borderRight ?? ""};
  border-top: ${(props)=>props.borderTop ?? ""};
  border-bottom: ${(props)=>props.borderBottom ?? ""};
  gap: ${(props)=>props.gap ?? "10px"};
  top: ${(props) => props.top ?? ""};
  margin-top: ${(props) => props.marginTop ?? ""};
  left: ${(props) => props.left ?? ""};
  opacity: ${(props) => props.opacity ?? ""};
  boxshadow: ${(props) => props.boxShadow ?? ""};
  display: ${(props) => props.display ?? "flex"};
  z-index: ${(props) => props.zIndex ?? ""};
  justify-content: ${(props) => props.justifyContent ?? ""};
  transform: ${(props) => props.transform ?? ""};
`;

export default ParticlesType;